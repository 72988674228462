import React, { useRef } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

const SearchBox = ({
  onPlaceSelected,
  InputEventJob,
  venue,
  names,
  ph,
  className,
}) => {
  const autoCompleteRef = useRef(null);

  const onLoad = (autoComplete) => {
    autoCompleteRef.current = autoComplete;
  };

  const onPlaceChanged = (event) => {
    if (autoCompleteRef.current !== null) {
      const place = autoCompleteRef.current.getPlace();
      const { lat, lng } = place.geometry.location;
      const latLng = { lat: lat(), lng: lng() };
      onPlaceSelected(place, latLng);
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
      libraries={["places"]}
    >
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder={ph}
          style={{ width: "100%" }}
          className={className}
          name={names}
          onChange={InputEventJob}
          value={venue}
        />
      </Autocomplete>
    </LoadScript>
  );
};

export default SearchBox;
