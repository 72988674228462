import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Createjob.css";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useEffect, useState } from "react";
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchBox from "./Searchbox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import AppConfig from "../../../Api/AppConfig";

const Createjob = () => {
  const navigate = useNavigate();
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [mdata, setmdata] = useState();
  const [create, setcreate] = useState(false);
  const [Error, seterror] = useState("");
  const [value, setValue] = useState(null);

  const [msg, setmsg] = useState();
  const [isloading, setisloading] = useState(false);
  const [job, setJob] = useState({
    vendor: "",
    budget: "",
    detail: "",
    venue: "",
    skill: [],
  });
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}master_data`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          setmdata(redata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  }, []);

  const InputEventJob = (event) => {
    const { name, value } = event.target;
    setJob((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const AddJob = (event) => {
    event.preventDefault();
    if (job.venue === "" || job.budget === "" || job.detail === "") {
      seterror("Please select all the field");
    } else {
      setisloading(true);
      const params = new FormData();
      params.append("lok_for", job.vendor);
      params.append("budget", job.budget);
      params.append("venue", job.venue);
      params.append("requirements", job.detail);
      for (let i = 0; i < job.skill.length; i++) {
        params.append("skills", job.skill[i]);
      }
      params.append("lat", 23.0225);
      params.append("long", 72.5714);
      params.append("wedding_date", dayjs(value).format("DD/MM/YYYY"));

      try {
        let config = {
          url: `${AppConfig.BASE_URL_v1}create_job`,
          method: "POST",
          data: params,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authentication: userToken,
          },
        };

        axios(config)
          .then((res) => res.data)
          .then((response) => {
            setisloading(false);
            setmsg(response.message);
            event.target.reset();
            setcreate(true);
          })
          .catch((error) => {
            const err = error.response.data.message;
            seterror(err);

            setisloading(false);
            if (error.response.data.code === 401) {
              localStorage.clear();
              navigate("/Login");
            }
          });
      } catch (error) {}
      setJob({
        vendor: "",
        budget: "",
        detail: "",
        venue: "",
        skill: [],
      });
    }
  };

  const latlong = (places, latLng) => {
    setJob((prevData) => {
      return {
        ...prevData,
        venue: places.formatted_address,
      };
    });

    setLat(latLng.lat);
    setLong(latLng.lng);
  };
  const jobsuccess = () => {
    setcreate(false);
    navigate("/Homelist_Gridview");
  };
  return (
    <>
      <div className="createjob_nav">
        <ArrowBackIcon
          onClick={() => navigate("/MyJobs")}
          style={{ cursor: "pointer" }}
        />
        <h3>Create Job</h3>
      </div>
      <div className="createjob_header">
        <h1>
          Adding the Memories <br /> of Every Moment
        </h1>
      </div>
      <div className="createjob_side">
        <div className="profession">
          <div>
            <VerifiedOutlinedIcon style={{ fontSize: "2.1rem" }} />
          </div>
          <div className="text">
            <h3>Professional</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. <br />
              Id numquam, non nulla quasi expedita illum
            </p>
          </div>
        </div>
        <div className="profession">
          <div>
            <PersonAddAltIcon style={{ fontSize: "2.1rem" }} />
          </div>
          <div className="text">
            <h3>Experience</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. <br />
              Id numquam, non nulla quasi expedita illum
            </p>
          </div>
        </div>
        <div className="profession">
          <div>
            <HeadphonesOutlinedIcon style={{ fontSize: "2.1rem" }} />
          </div>
          <div className="text">
            <h3>Support 24/7</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. <br />
              Id numquam, non nulla quasi expedita illum
            </p>
          </div>
        </div>
      </div>
      <div className="createjob_main">
        <form
          action="/action_page.php"
          onSubmit={AddJob}
          className="createjob_form"
        >
          <div className="createjob_formname">
            <FormControl variant="standard" sx={{ m: 1 }} className="jobinput">
              <InputLabel
                id="demo-simple-select-standard-label"
                className="jobinput_label"
              >
                What are you looking for?
              </InputLabel>
              <br />
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={job.vendor}
                label="select multiple skills"
                onChange={InputEventJob}
                name="vendor"
                placeholder="select"
              >
                {mdata?.prof.map((prof, i) => (
                  <MenuItem key={i} value={prof.name}>
                    {prof.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <br />
          </div>
          <div className="createjob_formname">
            <FormControl variant="standard" sx={{ m: 1 }} className="jobinput">
              <InputLabel
                id="demo-simple-select-standard-label"
                className="jobinput_label"
              >
                Select multiple skills
              </InputLabel>
              <br />
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={job.skill}
                label="select multiple skills"
                onChange={InputEventJob}
                name="skill"
                placeholder="select"
                multiple
              >
                {mdata?.skills.map((skill, i) => (
                  <MenuItem key={i} value={skill.name}>
                    {skill.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="createjob_formname" style={{ border: "none" }}>
            <label htmlFor="budget">Event Date</label>
            <br />
          </div>

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            style={{ border: "none", outline: "none" }}
          >
            <DatePicker
              value={value}
              onChange={(newValue) => setValue(newValue)}
            />
          </LocalizationProvider>

          <div className="createjob_formname">
            <label htmlFor="budget">Set your budget</label>
            <br />
            <input
              name="budget"
              type="number"
              id="budget"
              placeholder="$"
              className="form_data"
              value={job.budget}
              onChange={InputEventJob}
            />
            <br />
          </div>
          <p>Only round up value are allowed(e.g.$200,not $202.23)</p>

          <div className="createjob_formname">
            <label htmlFor="text">Describe your Requirement</label>
            <textarea
              name="detail"
              rows="8"
              cols="30"
              className="form_data"
              placeholder="Write here"
              onChange={InputEventJob}
            ></textarea>
          </div>
          <div className="createjob_formname">
            <label htmlFor="venue">Venue</label>
            <br />
            <SearchBox
              job={job.venue}
              InputEventJob={InputEventJob}
              onPlaceSelected={latlong}
              names="venue"
              ph="venue"
              className="form_data"
            />

            {/* <input type="text" name="venue"
              className="form_data"
              placeholder="Venue"
              value={job.venue}
              onChange={InputEventJob}
            /><br /> */}
          </div>
          <span className="p">{Error}</span>

          <button type="submit" className="form_button">
            POST
          </button>
        </form>
        {isloading && (
          <Backdrop
            open={true}
            sx={{
              color: "#0DC3D7",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress color="inherit" open={true} />
          </Backdrop>
        )}
      </div>
      {create && (
        <div className="placed_bg">
          <div className="placed">
            <div>
              <CheckCircleOutlineIcon
                style={{ color: "green", fontSize: "xx-large" }}
              />
              <h2 style={{ color: "green" }}>{msg}</h2>
            </div>
            <button onClick={jobsuccess}>OK</button>
          </div>
        </div>
      )}
    </>
  );
};
export default Createjob;
