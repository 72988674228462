import React from "react";
import "./MyJobs.css";
import Navbar from "../../UI/Navbar";
import MyjobCards from "./MyjobCards";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import FilterModal2 from "../common/FilterModal2";
import { useEffect, useState } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppConfig from "../../../Api/AppConfig";

const MyJobs = () => {
  const navigate = useNavigate();
  const [mainpostdata, setmainpostdata] = useState();
  const [postdata, setpostdata] = useState(mainpostdata);
  const [jobcard, setJobcard] = useState();
  const [isLoding, setIsloding] = useState(true);
  const [totalpage, setTotalpage] = useState();
  const [Error, seterror] = useState("");

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    Data();
  }, []);

  const Data = () => {
    setIsloding(true);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_job_list`,
        method: "POST",
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const pdata = response.data;
          setpostdata(pdata);
          setmainpostdata(pdata);
          const redata = response.data.post_data;
          setJobcard(redata);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
          setIsloding(false);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const getAllData = (e) => {
    setIsloding(true);
    const params = new FormData();
    params.append("from_date", e[0].from);
    params.append("to_date", e[0].to);
    params.append("filter_status", e[0].num);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_job_list`,
        method: "POST",
        data: params,

        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const pdata = response.data;
          setpostdata(pdata);
          const redata = response.data.post_data;
          setJobcard(redata);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
        })
        .catch((error) => {
          setIsloding(false);
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const click = (data) => {
    setIsloding(true);
    let currentpage = data.selected + 1;

    const params = new FormData();
    params.append("page", currentpage);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_job_list`,

        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const redata = response.data;
          // setVendordata(redata);
        })
        .catch((error) => {
          setIsloding(false);
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      <Navbar />
      <div className="mycontainer">
        <div className="myjobcntnt">
          <div className="topdiv">
            <div className="myjobs">
              <h2>My Jobs</h2>
              <div className="posts">
                <h3>{postdata?.total_posted}</h3>
                <span>Total Posted</span>
              </div>
              <div className="Confirmed">
                <h3>{postdata?.total_confirmed}</h3>
                <span>Total Confirmed</span>
              </div>
              <div className="Withdrawn">
                <h3>{postdata?.total_withdrawn}</h3>
                <span>Total Withdrawn</span>
              </div>
              <div className="TotalCancelled">
                <h3>{postdata?.total_cancelled}</h3>
                <span>Total Cancelled</span>
              </div>
            </div>
            <div className="jobicon">
              <div className="filterbutton">
                <AddCircleOutlineIcon
                  id="add"
                  onClick={() => navigate("/Createjob")}
                />
              </div>
              <FilterModal2 getAllData={getAllData} clearfilter={Data} />
            </div>
          </div>
          <span className="p">{Error}</span>

          <div className="jobcardss">
            <MyjobCards
              jobcard={jobcard}
              click={click}
              totalpage={totalpage}
              seterror={seterror}
            />
          </div>
        </div>
        {isLoding && (
          <Backdrop
            open={true}
            sx={{
              color: "#0DC3D7",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress color="inherit" open={true} />
          </Backdrop>
        )}
      </div>
    </>
  );
};

export default MyJobs;
