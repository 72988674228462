import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./jobdetails.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import card3 from "../../../Assets/jobdetailscouple.jpeg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import AppConfig from "../../../Api/AppConfig";
import { Backdrop, CircularProgress } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import dayjs from "dayjs";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right-start" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0)",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    maxWidth: 150,
  },
}));

const Jobdetails = ({ post, getAllData }) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [placed, setplaced] = useState(false);
  const [msg, setmsg] = useState();
  const [edit, setedit] = useState();
  const [Error, seterror] = useState("");
  const [value, setValue] = useState(null);
  // const editref = useRef();

  const userToken = localStorage.getItem("token");

  // useEffect(() => {
  //   const handler = (event) => {
  //     if (editref.current && !editref.current.contains(event.target)) {
  //       setedit(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);

  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // }, []);

  const cancel = () => {
    setloading(true);
    const params = new FormData();
    params.append("job_id", post.id);
    params.append("job_action", "Cancelled");

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_action_on_job`,

        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setloading(false);
          setmsg(response.message);
          setplaced(true);
          getAllData();
          // setassign(false)
        })
        .catch((error) => {
          setloading(false);
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const withdraw_post = () => {
    setloading(true);
    const params = new FormData();
    params.append("job_id", post.id);
    params.append("job_action", "Withdrawn");

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_action_on_job`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setloading(false);
          setmsg(response.message);
          setplaced(true);
          getAllData();

          // setassign(false)
        })
        .catch((error) => {
          setloading(false);

          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const headers = () => {
    return {
      headers: {
        Authentication: userToken,
      },
    };
  };

  const editevent = async (newValue) => {
    console.log("call");
    try {
      const params = new FormData();
      params.append("wedding_date", dayjs(newValue).format("DD/MM/YYYY"));
      params.append("job_id", post.id);

      const response = await axios.post(
        `${AppConfig.BASE_URL_v1}UpdateJob`,
        params,
        headers()
      );
      getAllData();
      setedit(false);
      console.log(response);
      setValue(newValue);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="Jobdetails_nav">
        <ArrowBackIcon
          onClick={() => navigate("/MyJobs")}
          style={{ cursor: "pointer" }}
        />
        <h3>Job details</h3>
        <div>
          <AddCircleOutlineIcon
            id="add"
            onClick={() => navigate("/Createjob")}
          />
        </div>
      </div>
      <div className="jobdetails_content">
        <div className="jobdetails_main">
          <div className="jobdetails_img">
            <img src={card3} alt="" />
          </div>
          <div className="jobdetails_details">
            <div className="jobdetails_text">
              <h3>{post?.lok_for}</h3>
              <p
                className={
                  post?.status === "Open"
                    ? "open_status"
                    : post?.status === "Confirmed"
                    ? "confirm_status"
                    : post?.status === "Cancelled"
                    ? "cancel_status"
                    : post?.status === "Completed"
                    ? "complete_status"
                    : post?.status === "Expired"
                    ? "expire_status"
                    : "withdrawn_status"
                }
              >
                {post?.status}
              </p>
            </div>
            <div className="myjobbtns">
              {post?.skills.map((skill, i) => (
                <button key={i}>{skill}</button>
              ))}
            </div>
            <div className="bugetdiv">
              <div className="div1">
                <span>Budget</span>
                <h4>${post?.budget}</h4>
              </div>
              <div className="div1">
                <span>Bid</span>
                <h4>{post?.bid}</h4>
              </div>
              <div className="div1">
                <span>Posted on</span>
                <h4>{post?.posted_on}</h4>
              </div>
              <div
                className="div1"
                style={{ zIndex: "1" }}
                // ref={editref}
              >
                <span>
                  Event date{" "}
                  <LightTooltip title="Edit Event Date ">
                    <BorderColorIcon
                      onClick={() => setedit(true)}
                      style={{
                        fontSize: "17px",
                        cursor: "pointer",
                        color: "black",
                      }}
                    />
                  </LightTooltip>
                </span>
                {edit ? (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ border: "none", outline: "none" }}
                  >
                    <DatePicker
                      value={value}
                      onChange={(newValue) => editevent(newValue)}
                      // ref={editref}
                    />
                  </LocalizationProvider>
                ) : (
                  <h4>{post?.event_date}</h4>
                )}
              </div>
            </div>
            <div className="div1">
              <span>Venue</span>
              <h4>{post?.venue}</h4>
            </div>
            <span className="p">{Error}</span>

            <p>{post?.requirements}</p>
            {post?.status === "Open" || post?.status === "Expired" ? (
              <button className="withdrawn_btn" onClick={withdraw_post}>
                WITHDRAW
              </button>
            ) : post?.status === "Confirmed" ? (
              <button className="cancel_btn" onClick={cancel}>
                CANCEL
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <ul className="jobdetails_list">
          <li>
            <NavLink className="bid" smooth="true" to="/Jobdetail_Bid">
              Bid
            </NavLink>
          </li>
          <li>
            <NavLink className="chat" smooth="true" to="/Jobdetail_Openchat">
              Open Chat
            </NavLink>
          </li>
          <li>
            <NavLink
              className="confirmed"
              smooth="true"
              to="/Jobdetail_Confirmed"
            >
              Confirmed
            </NavLink>
          </li>
        </ul>
        {placed && (
          <div className="placed_bg">
            <div className="placed">
              <div>
                <CheckCircleOutlineIcon
                  style={{ color: "green", fontSize: "xx-large" }}
                />
                <h2 style={{ color: "green" }}>{msg}</h2>
              </div>
              <button onClick={() => setplaced(false)}>OK</button>
            </div>
          </div>
        )}
        {loading && (
          <Backdrop
            open={true}
            sx={{
              color: "#0DC3D7",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress color="inherit" open={true} />
          </Backdrop>
        )}
      </div>
    </>
  );
};
export default Jobdetails;
