import React from "react";
import "./MyjobCards.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const MyjobCards = (prop) => {
  const Navigate = useNavigate();

  const click = (data) => {
    prop.click(data);
  };
  const viewDetails = (e) => {
    localStorage.setItem("job_id", e.id);
    Navigate(`/Jobdetail_Bid`);
  };

  return (
    <div>
      {prop.jobcard?.length > 0 ? (
        <>
          <div className="jobCards">
            {prop.jobcard?.map((value, index) => {
              return (
                <div key={index} className="myCard1">
                  <div className="confirmdiv">
                    <div className="jobtext">
                      <h3>{value.lok_for}</h3>
                      <p
                        className={
                          value.status === "Open"
                            ? "open_status"
                            : value.status === "Confirmed"
                            ? "confirm_status"
                            : value.status === "Cancelled"
                            ? "cancel_status"
                            : value.status === "Completed"
                            ? "complete_status"
                            : value.status === "Expired"
                            ? "expire_status"
                            : "withdrawn_status"
                        }
                      >
                        {value.status}
                      </p>
                    </div>
                    <div className="myjobbtns">
                      {value.skills?.map((skill, i) => (
                        <button key={i}>{skill}</button>
                      ))}
                    </div>
                    <div className="bugetdiv postdetailsdiv">
                      <div className="div1">
                        <span>Budget</span>
                        <h4>${value.budget}</h4>
                      </div>
                      <div className="div1">
                        <span>Bid</span>
                        <h4>{value.bid}</h4>
                      </div>
                      <div className="div1">
                        <span>Post on</span>
                        <h4>{value.date_created}</h4>
                      </div>
                    </div>
                    <div className="jobbtn">
                      <button onClick={() => viewDetails(value, index)}>
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            <ReactPaginate
              pageCount={prop.totalpage}
              onPageChange={click}
              previousLabel="Previous"
              nextLabel="Next"
              containerClassName="nextslider"
              pageClassName="sliderbuttons"
              pageLinkClassName="linkbuttons"
              previousClassName="sliderbuttons"
              previousLinkClassName="linkbuttons"
              nextClassName="sliderbuttons"
              nextLinkClassName="linkbuttons"
              activeClassName="activepage"
              activeLinkClassName="activepage"
              breakClassName="sliderbuttons"
              breakLinkClassName="linkbuttons"
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              disabledLinkClassName="disable"
            />
          </div>
        </>
      ) : (
        <div className="nodata">No Jobs</div>
      )}
    </div>
  );
};

export default MyjobCards;
