import React, { useState, useEffect } from "react";
import "./Login.css";
import wedvedlogo from "../../Assets/Wedved_Logo.png";
import Cimage from "../../Assets/wedvedcouple.png";
import SecondButton from "../UI/SecondButton";
import sms from "../../Assets/sms.png";
import lock from "../../Assets/lock1.png";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { messaging } from "../../Firebase";
import { getToken } from "firebase/messaging";
import { signInWithPopup } from "firebase/auth";
import { auth, google } from "../../Firebase";
import GoogleIcon from "@mui/icons-material/Google";
import { useCallback } from "react";
import AppConfig from "../../Api/AppConfig";
import TiktokPixel from "tiktok-pixel";

const Login = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoding, setIsloding] = useState(false);
  const [Error, seterror] = useState("");
  const [devicetoken, setdevicetoken] = useState();
  const [providers, setProvider] = useState("");
  const [profile, setProfile] = useState();

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const user_type = localStorage.getItem("user_type");

  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        const permissionResult = await Notification.requestPermission();
        // console.log('Notification permission:', permissionResult);

        if (permissionResult === "granted") {
          const currentToken = await getToken(messaging, {
            vapidKey:
              "BEE4soE8v6Uav9SkEwC3s4nav05S8MaN_qOqy75l2jsGT7uG4FFM8kaEpmlJ08tOjf5Tumd0frBASfST8gz46bQ",
          });
          // console.log('Device token:', currentToken);
          setdevicetoken(currentToken);
          // Use the token as needed
        }
      } catch (error) {
        console.error("Error obtaining device token:", error);
      }
    };

    requestNotificationPermission();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("user_type")) {
      navigate("/Selectprofile");
    }
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    setIsloding(true);
    const params = new FormData();
    params.append("email", data.email);
    params.append("password", data.password);
    params.append("device_token", devicetoken);
    params.append("device_type", "web");

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}login`,
        method: "POST",
        data: params,
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const resdata = JSON.stringify(response);
          localStorage.setItem("data", resdata);
          localStorage.setItem("token", response.token);
          const user_type = response.data.user_type;
          const cp_complete = response.data.complete_profile;
          const status = response.status;

          if (status == 0) {
            seterror(response.message);
          } else {
            if (cp_complete == true) {
              if (user_type == 1) {
                navigate("/HomeList_Gridview");
              } else {
                navigate("/VendorHomelist_Gridview");
              }
            } else {
              if (user_type == 1) {
                navigate("/CompleteProfile");
              } else {
                navigate("/VendorHomelist_Gridview");
              }
            }
          }
          e.target.reset();
          const advancedMatching = {
            email: response.data.email,
            phone_number: "",
          };

          const options = {
            debug: true, // enable logs
          };
          TiktokPixel.init("CMN6AAJC77UEKGPKJK2G", advancedMatching, options);
        })
        .catch((error) => {
          const err = error.response.data.message;
          setIsloding(false);
          seterror(err);
        });
    } catch (error) {}
  };
  const togglebtn = () => {
    setOpen((prevopen) => !prevopen);
  };

  const onLoginStart = async (h) => {
    signInWithPopup(auth, h)
      .then((result) => {
        sociallogin(result.user.providerData);
      })
      .catch((error) => {
        if (error.code === "auth/cancelled-popup-request") {
          seterror("Failed to login.Please try again");
          setOpen(true);
        } else {
          seterror("Failed to login.Please try again");
          setOpen(true);
        }
      });
  };

  const sociallogin = (data) => {
    const params = new FormData();
    params.append("f_name", data[0].displayName);
    params.append("email", data[0].email);
    params.append("user_type", user_type);
    params.append("social_id", data[0].uid);
    params.append("social_type", "google");
    params.append("profile_pic", data[0].photoURL);
    params.append("device_token", devicetoken);
    params.append("device_type", "web");

    try {
      let config = {
        url: "https://admin.wedved.com/api/v1/social_api",
        method: "POST",
        data: params,
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          // setIsloding(false);
          const resdata = JSON.stringify(response);
          localStorage.setItem("data", resdata);
          if (user_type == 1) {
            navigate("/CompleteProfile");
          } else {
            navigate("/VendorCompleteProfile");
          }
          const userdata = JSON.parse(localStorage.getItem("data"));
          const token = userdata.token;
          localStorage.setItem("token", token);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);

          // setIsloding(false);
        });
    } catch (error) {}
  };

  return (
    <div>
      <div className="loginpage">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="leftsidelogin"
        >
          <img src={Cimage} alt="" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: -1 }}
          transition={{ duration: 1 }}
          className="rightsidelogin"
        >
          <img src={wedvedlogo} alt="" />
          <h3>Login</h3>
          <form onSubmit={handlesubmit} className="login">
            <div className="logininput">
              <div className="loginfirstinput">
                <img src={sms} alt="" className="loginiconimg" />
                <input
                  onChange={handlechange}
                  name="email"
                  style={{ outline: "none" }}
                  type="email"
                  placeholder="Enter Email"
                  required
                />
              </div>
              <div className="loginsecondinput">
                <img src={lock} alt="" className="loginiconimg" />
                <input
                  onChange={handlechange}
                  name="password"
                  type={open ? "Text" : "password"}
                  placeholder="Enter Password"
                  required
                />
                {open ? (
                  <AiOutlineEye onClick={togglebtn} />
                ) : (
                  <AiOutlineEyeInvisible onClick={togglebtn} />
                )}
              </div>
            </div>
            <span onClick={() => navigate("/Forgot")}>Forgot Password?</span>
            <div className="signinbtn">
              <span className="p">{Error}</span>
              <div>
                <SecondButton type="submit">
                  {!isLoding ? "Sign in" : "Loading..."}
                </SecondButton>
                {/* <SecondButton type="submit">Sign in</SecondButton> */}
                <p style={{ marginTop: "20px" }}>or login with</p>
                <div className="login_icon">
                  <GoogleIcon
                    id="lg_icon"
                    onClick={() => onLoginStart(google)}
                  />
                </div>
              </div>
              <h4>
                Don’t have an account?{" "}
                <button
                  className="signupbtn"
                  onClick={() => navigate("/Selectprofile")}
                >
                  Sign Up
                </button>
              </h4>
            </div>
          </form>
        </motion.div>
      </div>
      {!isLoding && <div></div>}
      {isLoding && (
        <Backdrop
          open={true}
          sx={{ color: "#0DC3D7", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </div>
  );
};

export default Login;
