import React from "react";
import "./Forgot.css";
import Screensback from "../UI/Screensback";
import forgotgirl from "../../Assets/forgot.png";
import wedvedlogo from "../../Assets/Wedved_Logo.png";
import sms from "../../Assets/sms.png";
import Leftarrow from "../UI/Leftarrow";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import SecondButton from "../UI/SecondButton";
import { useState } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppConfig from "../../Api/AppConfig";
import { Alert, Snackbar } from "@mui/material";

const Forgot = () => {
  const navigate = useNavigate();
  const [isLoding, setIsloding] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [Error, seterror] = useState("");

  const [msg, setmsg] = useState();

  const [data, setData] = useState({
    email: "",
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setIsloding(true);
    const params = new FormData();
    params.append("email", data.email);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}forgot_password`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          console.log(response);
          const respo = response.message;
          setmsg(respo);
          setOpen(true);
          setIsloding(false);
          e.target.reset();
        })
        .catch((error) => {
          setIsloding(false);
          const err = error.message;
          seterror(err);
          setOpen2(true);
        });
    } catch (error) {}
  };
  console.log(msg);
  const backbtn = () => {
    navigate("/login");
  };
  return (
    <div>
      <Screensback>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 1 }}
          transition={{ duration: 0.3 }}
          className="mainC"
        >
          <motion.form
            onSubmit={handlesubmit}
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: -1 }}
            transition={{ duration: 1 }}
            className="content"
          >
            {/* <div className="backabtn" > */}
            <ArrowBackIcon
              id="backicon"
              className="backabtn"
              onClick={backbtn}
              style={{ cursor: "pointer" }}
            />
            {/* </div> */}

            <img src={wedvedlogo} alt="" />
            <h2>Forgot Password</h2>
            <img src={forgotgirl} alt="" />
            <p>
              Enter the email associated with your account and we’ll send <br />{" "}
              an email with instructions to reset your password.
            </p>
            <div className="fE">
              <img src={sms} alt="" />
              <input
                onChange={handlechange}
                type="email"
                placeholder="Enter Email"
                required
                name="email"
              />
            </div>
            <span className="p">{Error}</span>
            <div id="sbtn">
              <SecondButton type="submit">Submit</SecondButton>
            </div>
          </motion.form>
        </motion.div>
      </Screensback>
      {!isLoding && <div></div>}
      {isLoding && (
        <Backdrop
          open={true}
          sx={{ color: "#0DC3D7", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert severity="success" variant="filled" sx={{ width: "70vw" }}>
          {msg}
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={4000} onClose={handleClose2}>
        <Alert severity="error" variant="filled" sx={{ width: "70vw" }}>
          {Error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Forgot;
