import placeorder from "../../../Assets/place_order (1).png";
import { useState, useEffect } from "react";
import axios from "axios";
import locationicon from "../../../Assets/locationIcon.png";
import Jobdetails from "./jobdetails";
import "./Jobconfirmed.css";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { useNavigate } from "react-router-dom";
import AppConfig from "../../../Api/AppConfig";
import { Backdrop, CircularProgress } from "@mui/material";

const Confirmed = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState();
  const [loading, setloading] = useState(false);
  const [conf, setconf] = useState(false);
  const [Error, seterror] = useState("");

  const [value, setvalue] = useState({
    rate: "",
    detail: "",
  });

  const base = `${AppConfig.BASE_URL_PROFILE}`;
  const id = JSON.parse(localStorage.getItem("job_id"));
  const userToken = localStorage.getItem("token");
  const status = post?.status;

  useEffect(() => {
    getAllData();
  }, []);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setvalue((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const getAllData = () => {
    const params = new FormData();
    params.append("job_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_job_details`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = response.data;
          setPost(resdata);
          const statuss = response.data.status;
          if (statuss === "Completed") {
            setconf(true);
          } else {
            setconf(false);
          }
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const rate = () => {
    setloading(true);
    const params = new FormData();
    params.append("rate", value.rate);
    params.append("details", value.detail);
    params.append("vender_id", post?.confirmed.vender_id);
    params.append("post_id", post.id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}rate_vender_api`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setloading(false);
          getAllData();
        })
        .catch((error) => {
          setloading(false);
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      <Jobdetails post={post} getAllData={getAllData} />

      {status === "Completed" || status === "Confirmed" ? (
        <>
          <div className="jobconfirmed_bids">
            <div className="jobconfirmedbid_div">
              <img src={base + post?.confirmed.vender_profile_pic} alt="" />
              <div className="jobconfirmed_bidsright">
                <h2>
                  {post?.confirmed.vender_first_name +
                    " " +
                    post?.confirmed.vender_last_name}
                </h2>
                <div>
                  <span>
                    {" "}
                    <Box
                      sx={{
                        "& > legend": { mt: 2 },
                      }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Rating
                        name="read-only"
                        value={parseInt(post?.confirmed.rate)}
                        size="small"
                        style={{ zIndex: "0" }}
                        readOnly
                      />
                      ({post?.confirmed.reviews} Reviews)
                    </Box>
                  </span>
                </div>

                <div className="jobconfirmed_miles">
                  <img src={locationicon} alt="" />
                  <span>{post?.confirmed.distance} miles away</span>
                </div>
                <div className="jobconfirmed_bidinfo">
                  <div className="jobdetails_biddetails">
                    <p>bid</p>
                    <h4>${post?.confirmed.bid}</h4>
                  </div>
                  <div className="jobconfirmed_biddetails">
                    <p>Job Given On</p>
                    <h4>{post?.confirmed.job_given_on}</h4>
                  </div>
                </div>
                {conf && (
                  <div className="jobconfirmed_biddetails">
                    <p>Job Completed On</p>
                    <h4>{post?.confirmed.job_complete_on}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
          {conf && post?.is_rate === false && (
            <div className="ratings">
              <h2>
                Rate{" "}
                {post?.confirmed.vender_first_name +
                  " " +
                  post?.confirmed.vender_last_name}
              </h2>
              <Box
                sx={{
                  "& > legend": { mt: 2 },
                }}
              >
                <Rating
                  name="rate"
                  value={value.rate}
                  onChange={handlechange}
                  className="rate_star"
                />
              </Box>
              <div className="rate_text">
                <label>Describe your Experience</label>
                <br />
                <textarea
                  name="detail"
                  className="rating_data"
                  value={value.detail}
                  onChange={handlechange}
                  rows="8"
                  cols="30"
                  placeholder="write here"
                ></textarea>
              </div>
              <button className="rate_button" onClick={rate}>
                RATE
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="nodata">
          <div>
            <img src={placeorder} />
            <h5>
              Place order to view <br /> the vendor winner details
            </h5>
          </div>
        </div>
      )}
      <span className="p">{Error}</span>
      {loading && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </>
  );
};
export default Confirmed;
