import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import wedvedlogo from "../../../Assets/Wedved_Logo.png";
import SecondButton from "../../UI/SecondButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import "./CompleteProfile.css";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import axios from "axios";
import AppConfig from "../../../Api/AppConfig";

const Vendorcompleteprofile = () => {
  const navigate = useNavigate();

  const [image, setImage] = useState([]);
  const [video, setvideo] = useState([]);
  const [preview, setPreview] = useState([]);
  const [vpreview, setVpreview] = useState([]);
  const [isLoding, setIsloding] = useState(false);
  const [status, setstatus] = useState(null);
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [Error, seterror] = useState();
  const [mdata, setmdata] = useState();
  const [data, setData] = useState({
    profession: "",
    about_proff: "",
    t_year: "",
    skills: [],
    t_month: "",
    work_pics: image,
    lat: "",
    long: "",
  });

  const userToken = localStorage.getItem("token");

  navigator.geolocation.getCurrentPosition((position) => {
    setstatus(null);
    const letitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLat(letitude);
    setLong(longitude);
  });

  useEffect(() => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}master_data`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          setmdata(redata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  }, []);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const uploadMultipleFiles = (e) => {
    const file = e.target.files;
    let images = [];
    let videos = [];
    for (let i = 0; i < file.length; i++) {
      if (file[i].type.startsWith("video/")) {
        videos.push(URL.createObjectURL(file[i]));
        video.push(file[i]);
      } else if (file[i].type.startsWith("image/")) {
        images.push(URL.createObjectURL(file[i]));
        image.push(file[i]);
      }
    }
    setImage(image);
    setvideo(video);
    var array = preview.concat(images);
    setPreview(array);
    var videoarray = vpreview.concat(videos);
    setVpreview(videoarray);

    // method:2
    // const selectedFIles =[];
    //     const targetFiles =e.target.files;
    //     const targetFilesObject= [...targetFiles]
    //     targetFilesObject.map((file)=>{
    //        return selectedFIles.push(URL.createObjectURL(file))
    //     })
    //     setPreview(selectedFIles);
  };

  const deleteFile = (e) => {
    const s = preview.filter((URL, i) => i !== e);
    const i = image.filter((URL, i) => i !== e);
    setPreview(s);
    setImage(i);
  };
  const deletevFile = (e) => {
    const s = vpreview.filter((URL, i) => i !== e);
    const i = video.filter((URL, i) => i !== e);
    setvideo(i);
    setVpreview(s);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    setIsloding(true);

    const params = new FormData();
    params.append("profession", data.profession);
    for (let i = 0; i < data.skills.length; i++) {
      params.append("skills", data.skills[i]);
    }
    params.append("t_year", data.t_year);
    params.append("about_proff", data.about_proff);
    params.append("t_month", data.t_month);
    [...image].forEach((file) => {
      params.append("work_pics", file);
    });
    [...video].forEach((file) => {
      params.append("work_pics", file);
    });
    params.append("lat", lat);
    params.append("long", long);
    params.append("company_name", data.company_name);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_cm_profile`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          e.target.reset();
          navigate("/VendorHomelist_Gridview");

          // const redata = JSON.stringify(response);
          // localStorage.setItem("data", redata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <div>
      <div className="vendorscreenback">
        <motion.div
          // initial={{ opacity: 0, x: -100 }}
          // whileInView={{ opacity: 1, x: 1 }}
          // transition={{ duration: 0.3 }}
          className="vendorprofilebackw"
        >
          <motion.form
            onSubmit={handlesubmit}
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 1 }}
            transition={{ duration: 1 }}
            className="vendorprofilectn"
          >
            <div className="arrowbtn">
              <div
                className="vmainarrow"
                onClick={() => navigate("/VendorHomelist_Gridview")}
              >
                <ArrowBackIcon
                  onClick={() => navigate("/VendorHomelist_Gridview")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="cprotext">
              <img className="VendorClogo" src={wedvedlogo} alt="" />
              <h2>Complete Profile</h2>
            </div>
            <span className="p">{Error}</span>

            <div className="inputfgrp">
              <FormControl className="inputf">
                <InputLabel id="demo-simple-select-label">
                  Select your profession
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.profession}
                  label="select your profession"
                  onChange={handlechange}
                  name="profession"
                >
                  {mdata?.prof.map((skill, i) => (
                    <MenuItem key={i} value={skill.name}>
                      {skill.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="inputf">
                <InputLabel id="demo-simple-select-label">
                  Select multiple skills
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.skills}
                  label="select multiple skills"
                  onChange={handlechange}
                  name="skills"
                  multiple
                >
                  {mdata?.skills.map((skill, i) => (
                    <MenuItem key={i} value={skill.name}>
                      {skill.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <TextField
              id="outlined-multiline-static"
              label="Company Name"
              className="inputT"
              name="company_name"
              value={data.company_name}
              onChange={handlechange}
            />
            <TextField
              id="outlined-multiline-static"
              label="About Your Profession"
              multiline
              rows={4}
              className="inputT"
              name="about_proff"
              value={data.about_proff}
              onChange={handlechange}
            />
            <div className="inputfgrp">
              <FormControl className="inputf">
                <InputLabel id="demo-simple-select-label">
                  Total Experience(In year)
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.t_year}
                  label="Total Experience(In year)"
                  onChange={handlechange}
                  name="t_year"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                  <MenuItem value="7">7</MenuItem>
                  <MenuItem value="8">8</MenuItem>
                  <MenuItem value="9">9</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="3">10+</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="inputf">
                <InputLabel id="demo-simple-select-label">
                  Total Experience(In month)
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.t_month}
                  label="Total Experience(In month)"
                  onChange={handlechange}
                  name="t_month"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="3">4</MenuItem>
                  <MenuItem value="3">5</MenuItem>
                  <MenuItem value="3">6</MenuItem>
                  <MenuItem value="3">7</MenuItem>
                  <MenuItem value="3">8</MenuItem>
                  <MenuItem value="3">9</MenuItem>
                  <MenuItem value="3">10</MenuItem>
                  <MenuItem value="3">11</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="imgupload_div">
              <label htmlFor="file" id="uploadbtn">
                <div className="imgupload">
                  <AddIcon id="addimg" />
                </div>
              </label>

              <input
                type="file"
                id="file"
                // accept="image/*"
                onChange={uploadMultipleFiles}
                name="work_pics"
                multiple
              />
              {preview.map((URL, i) => (
                <div className="imgbox">
                  <img
                    src={URL}
                    alt={"image-" + i}
                    key={i}
                    style={{ width: "92px", height: "100px" }}
                  />
                  <CancelOutlinedIcon
                    id="deleteimg"
                    onClick={() => deleteFile(i)}
                  />
                </div>
              ))}
              {vpreview.map((URL, i) => (
                <div className="videobox">
                  <video
                    src={URL}
                    alt={"video-" + i}
                    key={i}
                    style={{ width: "184px", height: "100px" }}
                    controls={true}
                  />
                  <CancelOutlinedIcon
                    id="deletevideo"
                    onClick={() => deletevFile(i)}
                  />
                </div>
              ))}
            </div>
            <div className="Cprofilebtns">
              <div className="addbtn">
                <SecondButton type="submit">Add</SecondButton>
              </div>
            </div>
          </motion.form>
        </motion.div>
      </div>
      {!isLoding && <div></div>}
      {isLoding && (
        <Backdrop
          open={true}
          sx={{ color: "#0DC3D7", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </div>
  );
};
export default Vendorcompleteprofile;
