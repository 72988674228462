import { useState, useEffect } from "react";
import axios from "axios";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import "./Postratting.css";
import { useNavigate } from "react-router-dom";
import AppConfig from "../../../Api/AppConfig";

const Ratting = () => {
  const navigate = useNavigate();

  const [Rate, setRate] = useState({});
  const [Error, seterror] = useState("");

  const base = `${AppConfig.BASE_URL_PROFILE}`;
  const userToken = localStorage.getItem("token");
  const id = JSON.parse(localStorage.getItem("job_id"));

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    const params = new FormData();
    params.append("post_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_view_couple_post`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const biderdata = response.data.rate;
          setRate(biderdata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  return (
    <>
      <span className="p">{Error}</span>

      {Object.keys(Rate).length > 0 ? (
        <div className="ratting_div">
          <img src={base + Rate?.profile_pic} />
          <div className="ratting_right">
            <div className="rating_updiv">
              <div className="rating_name">
                <h3>{Rate?.first_name + " " + Rate?.last_name}</h3>
                <p>{Rate?.date_review}</p>
              </div>
              <Box
                sx={{
                  "& > legend": { mt: 2 },
                }}
              >
                <Rating
                  name="read-only"
                  value={parseInt(Rate?.rate)}
                  readOnly
                />
              </Box>
            </div>

            <p className="rating_detail">{Rate?.details}</p>
          </div>
        </div>
      ) : (
        <div className="nodata">No rating </div>
      )}
    </>
  );
};
export default Ratting;
