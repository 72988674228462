import React, { useEffect, useState } from "react";
import "./Selectprofile.css";
import wedvedlogo from "../../Assets/Wedved_Logo.png";
import profile1 from "../../Assets/profileimg1.png";
import profile2 from "../../Assets/profileimg2.png";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Screensback from "../UI/Screensback";
import Leftarrow from "../UI/Leftarrow";

const Selectprofile = () => {
  const navigate = useNavigate();

  const handlesubmitcouple = () => {
    // setSelectedOption(1)
    localStorage.setItem("user_type", JSON.stringify(1));
    navigate("/Splash");
  };
  const handlesubmitvendor = () => {
    // setSelectedOption(2)
    localStorage.setItem("user_type", JSON.stringify(2));
    navigate("/Splash");
  };
  // useEffect(() => {
  //   localStorage.setItem("user_type",JSON.stringify(selectedOption))
  //   // navigate('/Splash')
  // }, [selectedOption])

  return (
    <div>
      <Screensback>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
          className="back"
        >
          <div className="backarrow_option" onClick={() => navigate("/")}>
            <Leftarrow />
          </div>

          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="main"
          >
            <img src={wedvedlogo} alt="" />
            <h1>Are You</h1>
            <div className="proimg">
              <div
                name="couple"
                className="proone"
                onClick={handlesubmitcouple}
              >
                <img src={profile2} alt="" />
                <h3>Couple</h3>
              </div>
              <div
                name="vendor"
                className="protwo"
                onClick={handlesubmitvendor}
              >
                <img src={profile1} alt="" />
                <h3>Vendor</h3>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </Screensback>
    </div>
  );
};

export default Selectprofile;
