import React, { useEffect, useState } from "react";
import MapContainer from "./VendorGoogleMap";
import VendorHomeList from "./VendorHomelist";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const VendorMapview = () => {
  const [coupledata, setCoupledata] = useState();
  const [totaluser, settotaluser] = useState();
  const [isLoding, setIsloding] = useState(true);
  const [Error, seterror] = useState("");
  const userToken = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        getAllData({ latitude, longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  const getAllData = (e) => {
    try {
      const params = new FormData();
      params.append("lat", e.latitude);
      params.append("long", e.longitude);

      let config = {
        url: `${process.env.REACT_APP_BASE_URL_v1}vender_home_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          console.log(response);
          const resdata = response.data;
          setCoupledata(resdata);
          setIsloding(false);
          settotaluser(response.pagination.total_users);
        })
        .catch((error) => {
          const err = error.response.data.message;
          setIsloding(false);
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {
      setIsloding(false);
    }
  };

  return (
    <>
      <VendorHomeList totalusers={totaluser} Error={Error} />
      <div className="mapback">
        <MapContainer coupledata={coupledata} />
      </div>
      {isLoding && (
        <Backdrop
          open={true}
          sx={{ color: "#0DC3D7", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </>
  );
};

export default VendorMapview;
