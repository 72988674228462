import React from "react";
import Navbar from "../../UI/Navbar";
import { NavLink } from "react-router-dom";
import filtericon from "../../../Assets/filtericon.png";

const VendorHomeList = ({
  totalusers,
  Error,
  setfilter,
  setisloading,
  isfilter,
}) => {
  return (
    <div>
      <Navbar setisloading={setisloading} />
      <div className="contentdiv">
        <div className="vendorview">
          <div className="vendortext">
            <h1>Couple ({totalusers})</h1>
          </div>
          <div className="vendorrightside">
            <div className="vendorbtns">
              <NavLink to="/Vendorhomelist_Gridview" className="gridbtn">
                Grid View
              </NavLink>
              <NavLink to="/VendorHomelist_Mapview" className="mapbtn">
                Map View
              </NavLink>
            </div>
            <div className="filter" onClick={() => setfilter(true)}>
              <div
                className={isfilter ? "filterbutton_filter" : "filterbutton"}
              >
                <img src={filtericon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="p">{Error}</span>
    </div>
  );
};

export default VendorHomeList;
