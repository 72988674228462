import React from 'react'
import "./Screenback.css";

const Screensback = ({children}) => {
  return (
    <div>
        <div className="screenback">
            <div className="backoverlay">{children}</div>
        </div>
    </div>
  )
}

export default Screensback