import React, { useState } from "react";
import "./FilterModal.css";
import closeIcon from "../../../Assets/closeIcon.png";
import { motion } from "framer-motion";
import filtericon from "../../../Assets/filtericon.png";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";

const FilterModal2 = (props) => {
  const [showmodal, setShowmodal] = useState(false);
  const [value1, setValue1] = useState(dayjs());
  const [value2, setValue2] = useState(dayjs());

  const [selected, setSelected] = useState();

  const datesubmit = (e) => {
    setSelected(e.target.value);
  };
  const checkboxes = [
    { key: "checkbox1", value: "0", label: "All", name: "num" },
    { key: "checkbox2", value: "1", label: "Open", name: "num" },
    { key: "checkbox3", value: "2", label: "Confirm", name: "num" },
    { key: "checkbox4", value: "3", label: "Withdrawn", name: "num" },
    { key: "checkbox5", value: "4", label: "Cancel", name: "num" },
    { key: "checkbox6", value: "5", label: "Complete", name: "num" },
    { key: "checkbox7", value: "6", label: "Expired", name: "num" },
  ];

  const values = [
    {
      from: value1.format("YYYY-MM-DD"),
      to: value2.format("YYYY-MM-DD"),
      num: selected,
    },
  ];

  const getData = () => {
    props.getAllData(values);
    setShowmodal(false);
  };
  const clearData = () => {
    props.clearfilter(values);
    setSelected();
    setValue1(dayjs());
    setValue2(dayjs());
    setShowmodal(false);
  };

  return (
    <div>
      <div className="filter" onClick={() => setShowmodal(!showmodal)}>
        <div className="filterbutton">
          <img src={filtericon} alt="" />
        </div>
      </div>

      {showmodal && (
        <div className="filter_background">
          <motion.div
            initial={{ x: 100 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.2 }}
            className="filterwhitebg"
          >
            <div className="filtercntnt">
              <div className="filtertext">
                <h4>Filter By</h4>
                <div>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={() => setShowmodal(false)}
                  />
                </div>
              </div>
              <div className="filter_date">
                <h4>Job Posted</h4>
                <div className="filterdate">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <DatePicker
                        name="from"
                        label="From date"
                        value={value1}
                        onChange={(newValue) => setValue1(newValue)}
                        format="DD/MM/YYYY"
                      />
                      <DatePicker
                        label="To"
                        value={value2}
                        onChange={(newValue) => setValue2(newValue)}
                        format="DD/MM/YYYY"
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="professiondiv">
                <h4>Status</h4>
                <div className="checkboxdiv">
                  {checkboxes.map((checkbox) => (
                    <div key={checkbox.key} className="firstC">
                      <input
                        type="checkbox"
                        value={checkbox.value}
                        checked={selected === checkbox.value}
                        onChange={datesubmit}
                        name={checkbox.name}
                      />
                      {checkbox.label}
                    </div>
                  ))}
                </div>
              </div>
              <div className="filterbtns">
                <div className="applebtn">
                  <button id="applybtn" onClick={getData}>
                    Apply
                  </button>
                </div>
                <div className="applebtn">
                  <button id="applybtn" onClick={clearData}>
                    clear
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default FilterModal2;
