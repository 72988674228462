import locationicon from "../../../Assets/locationIcon.png";
import "./JobBid.css";
import Jobdetails from "./jobdetails";
import { useState, useEffect } from "react";
import axios from "axios";
import Assign from "../../UI/Assignvendor";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppConfig from "../../../Api/AppConfig";
import cardicon2 from "../../../Assets/cardicon2.png";
import TiktokPixel from "tiktok-pixel";

const Jobbid = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState();
  const [assign, setassign] = useState(false);
  const [bidid, setbidid] = useState();
  const [bid, setbid] = useState();
  const [placed, setplaced] = useState(false);
  const [isLoding, setIsloding] = useState(true);
  const [Error, seterror] = useState("");
  const [showcard, setshowcard] = useState(false);
  const [msg, setmsg] = useState();
  const [card, setcard] = useState([]);
  const [cardid, setcardid] = useState();
  const [iscard, setiscard] = useState(false);

  const base = `${AppConfig.BASE_URL_PROFILE}`;
  const id = JSON.parse(localStorage.getItem("job_id"));
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    cardlist();

    if (id) {
      getAllData();
    }
  }, []);

  const getAllData = () => {
    const params = new FormData();
    params.append("job_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_job_details`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = response.data;
          setIsloding(false);
          setPost(resdata);
          //implement tiktok-pexels

          TiktokPixel.track("ViewContent", {
            contents: [
              {
                content_id: resdata.id,
                content_type: "product",
                content_name: "JobDetails",
              },
            ],
            value: resdata.budget,
            currency: "USD",
          });
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  //onclick on place order
  const placeorder = (e, value) => {
    e.stopPropagation();
    const bidid = value.bid_id;
    setbidid(bidid);
    setbid(value.bid);
    // implement tiktok-pexel

    TiktokPixel.track("InitiateCheckout", {
      contents: [
        {
          content_id: value.bid_id,
          content_type: "product",
          content_name: "checkout-process-start",
        },
      ],
      value: value.bid,
      currency: "USD",
    });

    card.length === 0 ? navigate("/Addcard") : setassign(true);
  };

  //onclick on chat
  const chat = (e, value) => {
    e.stopPropagation();
    const room = value.room_id;
    console.log(value);
    localStorage.setItem("room_id", room);
    navigate("/Chat", {
      state: {
        name: value.vender_first_name + " " + value.vender_last_name,
        pic: base + value.vender_profile_pic,
        id: value.vender_id,
      },
    });
  };

  const assignjob = (h) => {
    const params = new FormData();
    params.append("bid_id", bidid);
    params.append("card_id", h.id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_place_order`,

        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setmsg(response.message);
          setplaced(true);
          setassign(false);
          getAllData();

          // implement tiktok-pexel

          TiktokPixel.track("PlaceAnOrder", {
            contents: [
              {
                content_id: bidid,
                content_type: "product",
                content_name: "checkout-process-start",
              },
            ],
            value: bid,
            currency: "USD",
          });
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const cardlist = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_save_or_get_cards`,

        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          setcard(redata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          //   seterror(err)
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const handleCheckboxChange = (index) => {
    setiscard(false);
    setcard((prevCard) => {
      const updatedCard = prevCard.map((data, i) => {
        if (i === index) {
          return { ...data, is_selected: 1 };
        } else {
          return { ...data, is_selected: 0 };
        }
      });

      return updatedCard;
    });
  };
  const cardpay = () => {
    const h = card.find((data) => data.is_selected === 1);

    if (h === undefined) {
      setiscard(true);
    } else {
      setIsloding(true);
      const params = new FormData();
      params.append("card_id", h.id);

      try {
        let config = {
          url: `${AppConfig.BASE_URL_v1}save_as_default_card`,

          method: "POST",
          data: params,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authentication: userToken,
          },
        };

        axios(config)
          .then((res) => res.data)
          .then((response) => {
            assignjob(h);
            setIsloding(false);

            // implement tiktok-pexel

            TiktokPixel.track("CompletePayment", {
              contents: [
                {
                  content_id: bidid,
                  content_type: "product",
                  content_name: "Payforjob",
                },
              ],
              value: bid,
              currency: "USD",
            });
          })
          .catch((error) => {
            const err = error.response.data.message;
            //   seterror(err)
            setIsloding(false);
            if (error.response.data.code === 401) {
              localStorage.clear();
              navigate("/Login");
            }
          });
      } catch (error) {}
    }
  };

  const handleprofile = (value) => {
    localStorage.getItem("vendor_id", value.vender_id);
    navigate("/VendorProfile/profession");
  };

  return (
    <>
      <Jobdetails post={post} getAllData={getAllData} />
      {post?.total_bids.length > 0 ? (
        post?.total_bids.map((value, index) => (
          <div
            className="jobdetails_bids"
            key={index}
            onClick={() => handleprofile(value)}
          >
            <div className="jobdetailsbid_div">
              <img src={base + value.vender_profile_pic} alt="" />
              <div className="jobdetails_bidsright">
                <h2>
                  {value.vender_first_name + " " + value.vender_last_name}
                </h2>
                <div>
                  <span>
                    {" "}
                    <Box
                      sx={{
                        "& > legend": { mt: 2 },
                      }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Rating
                        name="read-only"
                        value={parseInt(value.rate)}
                        size="small"
                        style={{ zIndex: "0" }}
                        readOnly
                      />
                      ({value.reviews} Reviews)
                    </Box>
                  </span>
                </div>

                <div className="jobdetails_miles">
                  <img src={locationicon} alt="" />
                  <span>{value.distance} miles away</span>
                </div>
                <div className="jobdetails_bidinfo">
                  <div className="jobdetails_biddetails">
                    <p>bid</p>
                    <h4>${value.bid}</h4>
                  </div>
                  <div className="jobdetails_biddetails">
                    <p>bid On</p>
                    <h4>{value.bid_on}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="jobdetailsbid_button">
              <button
                onClick={(e) => chat(e, value)}
                disabled={
                  post?.status === "Withdrawn" ||
                  post?.status === "Expired" ||
                  post?.status === "Cancelled"
                    ? true
                    : false
                }
              >
                Chat
              </button>
              <button
                onClick={(e) => placeorder(e, value)}
                disabled={post?.status === "Open" ? false : true}
              >
                Placeorder
              </button>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="nodata">No Bids</div>
        </>
      )}
      <span className="p">{Error}</span>

      {card.length > 0 && assign && (
        <div className="assign_b">
          <div className="assign">
            <div className="assign_main">
              <div className="cardselected">
                <label htmlFor="card">Choose card</label>
                <br />
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <div className="cardlist">
                    {card.map((data, index) => (
                      <div className="card_main" key={index}>
                        <div className="card_mainup">
                          <img
                            src={data.card_img}
                            alt=""
                            className="cardbrandimg"
                          />

                          <div
                            key={data.index}
                            className="selectcard"
                            onClick={() => setcardid(data.id)}
                          >
                            <input
                              type="checkbox"
                              checked={data.is_selected}
                              onChange={() => handleCheckboxChange(index)}
                              name="selectcard"
                            />
                          </div>
                        </div>

                        <h4>XXXX XXXX XXXX {data.last4}</h4>
                        <div className="carddate">
                          <p>Expiry date</p>
                          <h4>
                            {data.exp_month}/{data.exp_year}
                          </h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {iscard && (
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Please select Card
                </p>
              )}

              <div className="assign_btngroup">
                <button
                  className="assign_button"
                  onClick={() => setassign(false)}
                >
                  Cancel
                </button>
                <button className="assign_button" onClick={cardpay}>
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {placed && (
        <div className="placed_bg">
          <div className="placed">
            <div>
              <CheckCircleOutlineIcon
                style={{ color: "green", fontSize: "xx-large" }}
              />
              <h2 style={{ color: "green" }}>{msg}</h2>
            </div>
            <button onClick={() => setplaced(false)}>OK</button>
          </div>
        </div>
      )}
      {isLoding && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </>
  );
};
export default Jobbid;
