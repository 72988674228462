import React, { useEffect } from "react";
import "./Footer.css";
import logo from "../../Assets/Wedved_Logo.png";
import { Link } from "react-scroll";
import fIcon from "../../Assets/fvector.png";
import tIcon from "../../Assets/tvector.png";
import { motion } from "framer-motion";
import instaIcon from "../../Assets/ivector.png";
import LinkIcon from "../../Assets/Lvector.png";
import yIcon from "../../Assets/yvector.png";

const Footer = () => {
  useEffect(() => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }, []);
  return (
    <div>
      <div className="footer">
        <motion.a
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: -1 }}
          transition={{ delay: 0.1, duration: 1 }}
          href="/home"
        >
          <img src={logo} alt="" />
        </motion.a>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 1 }}
          transition={{ delay: 0.2, duration: 1 }}
          className="navlinks"
        >
          <li>
            <Link to="HeaderNav" smooth={true}>
              Home
            </Link>
          </li>
          <li>
            <Link to="AboutUs" smooth={true}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="Services" smooth={true}>
              Services
            </Link>
          </li>
          <li>
            <Link to="Testimonials" smooth={true}>
              Testimonials
            </Link>
          </li>
          <li>
            <Link to="Vendor" smooth={true}>
              Vendor
            </Link>
          </li>
          <li>
            <a href="mailto:snaved556@gmail.com" smooth={true}>
              Contact Us
            </a>
          </li>
        </motion.div>

        {/* Social Icons */}
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: -1 }}
          transition={{ delay: 0.2, duration: 1 }}
          className="sIcons"
        >
          <div>
            <a
              href="https://www.facebook.com/profile.php?id=61551921865691"
              target="_blank"
            >
              <img src={fIcon} alt="" />
            </a>
          </div>

          <div>
            <a href="https://www.instagram.com/wedvedapp/" target="_blank">
              <img src={instaIcon} alt="" />
            </a>
          </div>
        </motion.div>
      </div>
      <div className="copyright">
        <p>Copyright@2022. WedVed</p>
      </div>
    </div>
  );
};

export default Footer;
