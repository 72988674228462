import React, { useEffect, useRef } from "react";
import "./Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
import wedlogo from "../../Assets/wedvedlogo2.png";
import LogoutModal from "../Pages/userflow/LogoutModal";
import { useState } from "react";
import { Fade as Hamburger } from "hamburger-react";
import Badge from "@mui/material/Badge";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Link } from "react-router-dom";
import LogoutVendorModal from "../Pages/vendorflow/LogoutModal";
import NotificationModal from "./NotificationModal";
import NotificationVendorModal from "./NotificationVendorModal";
import axios from "axios";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../Firebase";
import logo from "../../Assets/Wedved_Logo.png";
import AppConfig from "../../Api/AppConfig";

const Navbar = ({ setisloading }) => {
  const menuref = useRef();
  const [open, setIsopen] = useState(false);
  const [showmodal, SetshowModal] = useState(false);
  const [shownf, setshownf] = useState(false);
  const [user, setuser] = useState(false);
  const [nf_count, setnf_count] = useState();
  const [nfv_count, setnfv_count] = useState();

  const navigate = useNavigate();

  const url = `${AppConfig.BASE_URL_PROFILE}`;
  const userdata = JSON.parse(localStorage.getItem("data"));
  const user_type = userdata.data.user_type;
  const myimg = userdata.data.profile_pic;
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    if (!localStorage.getItem("data")) {
      navigate("/Login");
    } else if (user_type == 1) {
      setuser(true);
      couple();
    } else if (user_type == 2) {
      setuser(false);
      vendor();
    }
  }, []);

  useEffect(() => {
    let handler = (event) => {
      if (!menuref.current.contains(event.target)) {
        setIsopen(false);
        SetshowModal(false);
        setshownf(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const couple = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_notification_api`,
        method: "GET",
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setnf_count(response.notification_count);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const vendor = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_notification_api`,
        method: "GET",
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setnfv_count(response.notification_count);
        })
        .catch((error) => {});
    } catch (error) {}
  };
  const handleIncomingMessage = () => {
    onMessage(messaging, (payload) => {
      if (payload) {
        couple();
        vendor();

        if (Notification.permission === "granted") {
          new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: logo,
          });
        }
      }
    });
    // onMessage(async remoteMessage => {
    //   alert('A new FCM message arrived!', JSON.stringify(remoteMessage));
    // });
  };

  handleIncomingMessage();

  const show_handle = () => {
    SetshowModal(true);
  };

  const backtohome = () => {
    localStorage.setItem("login2", true);
  };
  const show_notification = () => {
    setshownf(true);
    user ? couple_nf() : vendor_nf();
  };

  const vendor_nf = () => {
    const params = new FormData();
    params.append("notification_read", 1);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_notification_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          vendor();
        })
        .catch((error) => {});
    } catch (error) {}
  };
  const couple_nf = () => {
    const params = new FormData();
    params.append("notification_read", 1);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_notification_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          couple();
        })
        .catch((error) => {});
    } catch (error) {}
  };
  const isActive =
    window.location.pathname === "/VendorHomelist_Mapview" ||
    window.location.pathname === "/Homelist_Mapview";
  console.log(isActive);
  return (
    <div className="navdiv">
      <div className="homelogo">
        <Link to="/" onClick={backtohome}>
          <img src={wedlogo} alt="" />
        </Link>
        <Hamburger onToggle={() => setIsopen(!open)} toggled={open} />
      </div>
      <nav className="homenav" ref={menuref}>
        <ul className={open ? "menulinks menuhides" : "menulinks"}>
          <li>
            <NavLink
              className={isActive ? "activenav" : "activehome"}
              smooth="true"
              to={user ? "/HomeList_Gridview" : "/VendorHomelist_Gridview"}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              className="activemyjob"
              smooth="true"
              to={user ? "/MyJobs" : "/VendorMyJobs"}
            >
              MyJobs
            </NavLink>
          </li>
        </ul>

        <div className="SIPP">
          <Badge
            badgeContent={user ? `${nf_count}` : `${nfv_count}`}
            color="primary"
            onClick={show_notification}
          >
            <NotificationsOutlinedIcon style={{ cursor: "pointer" }} />
          </Badge>

          <div className="dropdown">
            <div to="" className="profilemainimg" onClick={show_handle}>
              <img src={url + myimg} alt="" />
            </div>

            {/* <div className="modallogout" ref={menuref}> */}
            {showmodal && user && <LogoutModal />}
            {showmodal && !user && (
              <LogoutVendorModal setisloading={setisloading} />
            )}
            {shownf && user && <NotificationModal />}
            {shownf && !user && <NotificationVendorModal />}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
