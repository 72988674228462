import React from "react";
import "./VendorMyjobCard.css";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AppConfig from "../../../Api/AppConfig";

const VendorMyjobCards = (prop) => {
  const navigate = useNavigate();

  const userToken = localStorage.getItem("token");

  const click = (data) => {
    prop.click(data);
  };
  const viewDetails = (e) => {
    const params = new FormData();
    params.append("post_id", e.id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_view_couple_post`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = JSON.stringify(response.data.id);
          localStorage.setItem("job_id", resdata);
          // setIsloding(false);
          navigate("/couple_post_details/bid");
          prop.seterror("");
        })
        .catch((error) => {
          const err = error.response.data.message;
          prop.seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }

          // setIsloding(false);
        });
    } catch (error) {}
  };

  return (
    <div>
      {prop.jobcard?.length > 0 ? (
        <>
          <div className="vjobCards">
            {prop.jobcard?.map((value, index) => {
              return (
                <div key={index} className="vmyCard1">
                  <div className="vendorconfirmdiv">
                    <div className="vendorjobtext">
                      <h3>{value.lok_for}</h3>
                      {/* <p>{value.status}</p> */}
                      <p
                        className={
                          value.status === "Open"
                            ? "open_status"
                            : value.status === "Confirmed"
                            ? "confirm_status"
                            : value.status === "Cancelled"
                            ? "cancel_status"
                            : value.status === "Completed"
                            ? "complete_status"
                            : "withdrawn_status"
                        }
                      >
                        {value.status}
                      </p>
                    </div>
                    <div className="vmyjobbtns">
                      {value.skills.map((skill, i) => (
                        <button key={i}>{skill}</button>
                      ))}
                    </div>
                    <div className="vendorbugetdiv">
                      <div className="vendordiv1">
                        <span>Budget</span>
                        <h4>{value.budget}</h4>
                      </div>
                      <div className="vendordiv1">
                        <span>Bid</span>
                        <h4>{value.bid}</h4>
                      </div>
                      <div className="vendordiv1">
                        <span>Won</span>
                        <h4>${value.won}</h4>
                      </div>
                      <div className="vendordiv1">
                        <span>Job Won On</span>
                        <h4>{value.job_won_on}</h4>
                      </div>
                    </div>
                    <div className="vendordetails">
                      <p>{value.requirements}</p>
                    </div>
                    <div className="detailbtn">
                      <button onClick={() => viewDetails(value, index)}>
                        View Job Details
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            <ReactPaginate
              pageCount={prop.totalpage}
              onPageChange={click}
              previousLabel="Previous"
              nextLabel="Next"
              containerClassName="nextslider"
              pageClassName="sliderbuttons"
              pageLinkClassName="linkbuttons"
              previousClassName="sliderbuttons"
              previousLinkClassName="linkbuttons"
              nextClassName="sliderbuttons"
              nextLinkClassName="linkbuttons"
              activeClassName="activepage"
              activeLinkClassName="activepage"
              breakClassName="sliderbuttons"
              breakLinkClassName="linkbuttons"
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              disabledLinkClassName="disable"
            />
          </div>
        </>
      ) : (
        <div className="nodata">No Jobs</div>
      )}
    </div>
  );
};

export default VendorMyjobCards;
