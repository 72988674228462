import { useState, useEffect } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import "./VendorGoogleMap.css";
// import { useCallback } from "react";

function MapContainer(props) {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [coupledata, setCoupledata] = useState();

  useEffect(() => {
    setCoupledata(props.coupledata);
  }, []);
  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onMapClick = (props) => {
    if (showingInfoWindow) {
      setSelectedPlace(null);
      setActiveMarker(null);
      setShowingInfoWindow(false);
    }
  };

  const onInfoWindowClose = () => {
    setSelectedPlace(null);
    setActiveMarker(null);
    setShowingInfoWindow(false);
  };

  // const viewDetails = useCallback((e) => {
  //   const params = new FormData();
  //   params.append("post_id", e.id);

  //   try {
  //     let config = {
  //       url: `${AppConfig.BASE_URL_v1}vender_view_couple_post`,
  //       method: "POST",
  //       data: params,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Accept: "application/json",
  //         Authentication: userToken,
  //       },
  //     };

  //     axios(config)
  //       .then((res) => res.data)
  //       .then((response) => {
  //         const resdata = JSON.stringify(response.data.id);
  //         localStorage.setItem("postview_id", resdata);
  //         navigate("/couple_post_details/bid");
  //       })
  //       .catch((error) => {
  //         if (error.response.data.code === 401) {
  //           localStorage.clear();
  //           navigate("/Login");
  //         }
  //       });
  //   } catch (error) {}
  // }, []);

  return (
    <div
      style={{
        position: "absolute",
        zIndex: "-1",
        width: "100%",
        height: "100%",
      }}
    >
      <span className="p">{Error}</span>

      <Map
        google={props.google}
        onClick={onMapClick}
        zoom={14}
        style={{ zIndex: "1" }}
      >
        {coupledata?.map((d, index) => (
          <Marker
            key={index}
            onClick={onMarkerClick}
            name={d}
            position={{ lat: d.lat, lng: d.long }}
          />
        ))}

        <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}
          onClose={onInfoWindowClose}
        >
          <div className="mapbox">
            <div className="map_pic">
              <img
                src={
                  process.env.REACT_APP_BASE_URL_PROFILE +
                  selectedPlace?.name.user.profile_pic
                }
                alt=""
              />
              <h2>{selectedPlace?.name.user.first_name}</h2>
            </div>
            <div className="map_profession">
              <h3>{selectedPlace?.name.lok_for}</h3>
              <div className="map_skill">
                {selectedPlace?.name.skills.map((skill, i) => (
                  <button key={i}>{skill}</button>
                ))}
              </div>
            </div>
            <div className="csinger">
              <h3>Venue</h3>
              <span>{selectedPlace?.name.venue}</span>
            </div>
            <div className="csinger">
              <h3>Budget</h3>
              <span>${selectedPlace?.name.budget}</span>
            </div>
            <div className="csinger">
              <h3>Requirement</h3>
              <span>{selectedPlace?.name.requirements}</span>
            </div>

            <div className="map_detailbtn">
              {/* <button
              //  onClick={handleButtonClick}
              //onClick={() => viewDetails(selectedPlace?.name)}
              >
                View Job Details
              </button> */}
            </div>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY,
})(MapContainer);
