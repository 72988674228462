import React from "react";
import "./CreateAccount.css";
import wedvedlogo from "../../Assets/Wedved_Logo.png";
import SecondButton from "../UI/SecondButton";
import usericon from "../../Assets/user.png";
import sms from "../../Assets/sms.png";
import lock1 from "../../Assets/lock1.png";
import Screensback from "../UI/Screensback";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import Leftarrow from "../UI/Leftarrow";
import cameraicon from "../../Assets/cameraicon.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import AppConfig from "../../Api/AppConfig";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import personimg from "../../Assets/persondefault_profile.png";
import { messaging } from "../../Firebase";
import { getToken } from "firebase/messaging";
import { Link } from "react-router-dom";
import TiktokPixel from "tiktok-pixel";

const CreateAccount = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const [devicetoken, setdevicetoken] = useState();
  const [preview, setPreview] = useState();
  const [isLoding, setIsloding] = useState(false);
  const [Error, seterror] = useState("");

  const [validate, setvalidate] = useState({
    password: "",
    email: "",
  });

  const user_type = localStorage.getItem("user_type");
  const [datas, setDatas] = useState({
    f_name: "",
    l_name: "",
    email: "",
    password: "",
    user_type: user_type,
  });

  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        const permissionResult = await Notification.requestPermission();
        // console.log('Notification permission:', permissionResult);

        if (permissionResult === "granted") {
          const currentToken = await getToken(messaging, {
            vapidKey:
              "BEE4soE8v6Uav9SkEwC3s4nav05S8MaN_qOqy75l2jsGT7uG4FFM8kaEpmlJ08tOjf5Tumd0frBASfST8gz46bQ",
          });
          // console.log('Device token:', currentToken);
          setdevicetoken(currentToken);
          // Use the token as needed
        }
      } catch (error) {
        console.error("Error obtaining device token:", error);
      }
    };

    requestNotificationPermission();
  }, []);

  // useEffect(() => {
  //   if (image) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setPreview(reader.result);
  //     };
  //     reader.readAsDataURL(image);
  //   } else {
  //     setPreview(null);
  //   }
  // }, [image]);

  useEffect(() => {
    if (!localStorage.getItem("user_type")) {
      navigate("/Selectprofile");
    }
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    setDatas((prev) => {
      return { ...prev, [name]: value };
    });

    validation(e);
  };

  const imagehandle = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const validation = (e) => {
    let { name, value } = e.target;
    setvalidate((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "please enter password";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    validation(e);
    setIsloding(true);

    const params = new FormData();
    params.append("email", datas.email);
    params.append("f_name", datas.f_name);
    params.append("l_name", datas.l_name);
    params.append("password", datas.password);
    params.append("user_type", user_type);
    params.append("profile_pic", image);
    params.append("device_token", devicetoken);
    params.append("device_type", "web");
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}register`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          e.target.reset();
          const resdata = JSON.stringify(response);
          localStorage.setItem("data", resdata);
          const userdata = JSON.parse(localStorage.getItem("data"));
          const token = userdata.token;
          localStorage.setItem("token", token);
          const user_type = userdata.data.user_type;

          if (user_type == 1) {
            navigate("/CompleteProfile");
          } else {
            navigate("/VendorCompleteProfile");
          }

          // implement tiktok-pexel

          TiktokPixel.track("CompleteRegistration", {
            contents: [
              {
                content_id: userdata.data.id,
                content_type: "product",
                content_name: `register-${userdata.data.user_type}`,
              },
            ],
            value: "",
            currency: "USD",
          });
        })
        .catch((error) => {
          const err = error.response.data.message;
          setIsloding(false);
          seterror(err);
        });
    } catch (error) {}
  };

  const togglebtn = () => {
    setOpen((prevopen) => !prevopen);
  };

  return (
    <div>
      <Screensback>
        <motion.div
          // initial={{ opacity: 0, x: -100 }}
          // whileInView={{ opacity: 1, x: 0 }}
          // transition={{ duration: 0.3 }}
          className="cAwhiteback"
        >
          <motion.form
            onSubmit={handlesubmit}
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="cmainCnt"
            id="myForm"
          >
            <div className="backarrow" onClick={() => navigate("/Splash")}>
              <Leftarrow />
            </div>

            <img src={wedvedlogo} alt="" />
            <h1>Create an Account</h1>
            <div className="proimage">
              <div className="profile">
                {preview ? (
                  <img
                    src={preview}
                    alt=""
                    style={{
                      objectFit: "cover",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                ) : (
                  <img
                    src={personimg}
                    alt=""
                    style={{
                      objectFit: "cover",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                )}
              </div>
              <input
                type="file"
                id="file"
                accept="image/*"
                onChange={imagehandle}
                name="profile_pic"
              />
              <label htmlFor="file" id="uploadbtn">
                <img src={cameraicon} alt="" />
              </label>
            </div>

            <div className="khainwaladiv2">
              <div className="userNinputs">
                <div className="userNfirst">
                  <img src={usericon} alt="" />
                  <input
                    required
                    onChange={handlechange}
                    name="f_name"
                    type="text"
                    placeholder="Enter Firstname"
                  />
                </div>
                <div className="userNfirst">
                  <img src={usericon} alt="" />
                  <input
                    required
                    onChange={handlechange}
                    name="l_name"
                    type="text"
                    placeholder="Enter Lastname"
                  />
                </div>
              </div>

              <div className="Epasinputs">
                <div className="errordiv">
                  <div className="epinput">
                    <img src={sms} alt="" />
                    <input
                      required
                      onChange={handlechange}
                      name="email"
                      type="Email"
                      placeholder="Enter Email"
                    />
                  </div>
                  <span className="p">{Error}</span>
                </div>
                <div className="errordiv">
                  <div className="epinput">
                    <img src={lock1} alt="" />
                    <input
                      required
                      onChange={handlechange}
                      id="eyeinput"
                      type={open ? "text" : "Password"}
                      placeholder="Enter Password"
                      name="password"
                      onBlur={validation}
                    />
                    {open ? (
                      <AiOutlineEye onClick={togglebtn} />
                    ) : (
                      <AiOutlineEyeInvisible onClick={togglebtn} />
                    )}
                  </div>
                  {validate.password && (
                    <span className="err">{validate.password}</span>
                  )}
                </div>
              </div>
              <div className="createaccount_checkbox">
                <input type="checkbox" required />

                <p>
                  By continue, you agree to accept our
                  <Link to="/Terms&Condition">&nbsp;Term of Service&nbsp;</Link>
                  and{" "}
                  <Link to="/PrivacyPolicy">&nbsp;privacy policy&nbsp;</Link>
                </p>
              </div>
            </div>

            <div className="subbtn3">
              <SecondButton type="submit">
                {!isLoding ? "Sign in" : "Loading..."}
              </SecondButton>
            </div>
            <p className="havean">
              Already have an account?{" "}
              <span onClick={() => navigate("/Login")}>Sign In</span>
            </p>
          </motion.form>
        </motion.div>
      </Screensback>
      {!isLoding && <div></div>}
      {isLoding && (
        <Backdrop
          open={true}
          sx={{ color: "#0DC3D7", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </div>
  );
};

export default CreateAccount;
