import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import card3 from "../../../Assets/jobdetailscouple.jpeg";
// import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import "./CouplePostdetails.css";
import locationicon from "../../../Assets/locationIcon.png";
// import Staricon from "../../../Assets/Staricon.png";
// import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useEffect, useState } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import sendicon from "../../../Assets/send_ic.png";
import img from "../../../Assets/chat_ic.png";
import { NavLink } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import AppConfig from "../../../Api/AppConfig";
import { Modal } from "@mui/material";
const style = {
  position: "absolute",
  top: { xs: "60%", sm: "40%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "380px" },
  bgcolor: "white",
  boxShadow: 10,
  p: 2,
  border: "none",
  outline: "none",
};

const CouplePostdetail = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [isLoding, setIsloding] = useState(true);
  const [stripe, setstripe] = useState(true);
  const [post, setPost] = useState();
  const [bider, setBider] = useState();
  const [bid, setBid] = useState();
  const [Error, seterror] = useState("");
  const base = `${AppConfig.BASE_URL_PROFILE}`;
  const userToken = localStorage.getItem("token");
  const id = localStorage.getItem("job_id");
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const id = searchParams.get("job");
  let redirect = "";

  useEffect(() => {
    stripecheck();
  }, []);

  useEffect(() => {
    if (id) {
      getAllData();
    } else {
      navigate("/VendorHomelist_Gridview");
    }
  }, []);

  const stripecheck = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}get_user_data`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = JSON.stringify(response);
          localStorage.setItem("data", resdata);
          // const data = JSON.parse(localStorage.getItem("data"));
          console.log("stripe", response.data.is_stripe_validate);
          setstripe(response.data.is_stripe_validate);
          redirect = response.data.stripe_url;
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const getAllData = () => {
    const params = new FormData();
    params.append("post_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_view_couple_post`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const resdata = response.data;

          setPost(resdata);
          const biderdata = response.data.total_bids;
          setBider(biderdata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const handlechange = (e) => {
    const value = e.target.value;
    // const { name, value } = e.target;
    setBid(value);
  };
  const handleClose = () => setOpen(false);

  const handlecomplete = () => {
    setOpen(false);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}get_user_data`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          window.location.assign(response.data.stripe_url);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const Bidsubmit = (e) => {
    e.preventDefault();
    if (stripe === false) {
      setOpen(true);
    } else {
      setIsloding(true);
      sendbid();
    }
  };
  const sendbid = () => {
    setIsloding(true);
    const params = new FormData();
    params.append("bid_price", bid);
    params.append("post_id", post.id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_bid_on_job`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setBid("");
          setIsloding(false);

          getAllData();
        })
        .catch((error) => {
          const err = error.response.data.message;
          setIsloding(false);
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const Chat = (e) => {
    console.log(e);
    localStorage.setItem("room_id", e.room_id);
    navigate("/Chat", {
      state: {
        name: e.first_name + " " + e.last_name,
        pic: base + e.profile_pic,
        id: e.id,
      },
    });
  };

  const viewprofile = (e) => {
    localStorage.setItem("couple_id", e);
    navigate("/CoupleProfile");
  };
  const completejob = () => {
    setIsloding(true);
    try {
      const params = new FormData();
      params.append("job_id", post.id);
      let config = {
        url: `${AppConfig.BASE_URL_v1}complete_job_api`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res)
        .then((response) => {
          setIsloding(false);
          navigate("bid");
          getAllData();
        })
        .catch((error) => {
          const err = error.response.data.message;
          setIsloding(false);
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  return (
    <>
      <div className="Couplepostdetails_nav">
        <ArrowBackIcon
          onClick={() => navigate("/VendorMyJobs")}
          style={{ cursor: "pointer" }}
        />
        <h3>Couple Post details</h3>
      </div>
      <div className="Couplepostdetails_content">
        <div className="Couplepostdetails_main">
          <div className="Couplepostdetails_img">
            <img src={card3} alt="" />
          </div>
          <div className="Couplepostdetails_details">
            <div className="Couplepostdetails_text">
              <h2>{post?.lok_for}</h2>
              <p
                className={
                  post?.status === "Open"
                    ? "open_status"
                    : post?.status === "Confirmed"
                    ? "confirm_status"
                    : post?.status === "Cancelled"
                    ? "cancel_status"
                    : post?.status === "Completed"
                    ? "complete_status"
                    : post?.status === "Expired"
                    ? "expire_status"
                    : "withdrawn_status"
                }
              >
                {post?.status}
              </p>
            </div>
            <div className="myjobbtns">
              {post?.skills.map((skill, i) => (
                <button key={i}>{skill}</button>
              ))}
            </div>
            <div className="bugetdiv postdetailsdiv">
              <div className="vendordiv1">
                <span>Budget</span>
                <h4>${post?.budget}</h4>
              </div>
              <div className="vendordiv1">
                <span>Bid</span>
                <h4>{post?.bid}</h4>
              </div>
              <div className="vendordiv1">
                <span>Event Date</span>
                <h4>{post?.event_date}</h4>
              </div>

              {post?.status === "Confirmed" ? (
                <>
                  <div className="vendordiv1">
                    <span>Won</span>
                    <h4>${post?.won}</h4>
                  </div>
                  <div className="vendordiv1">
                    <span>Job Won On</span>
                    <h4>{post?.job_won_on}</h4>
                  </div>
                </>
              ) : post?.status === "Cancelled" ? (
                <>
                  <div className="vendordiv1">
                    <span>Job Cancelled On</span>
                    <h4>{post?.job_cancel_on}</h4>
                  </div>
                </>
              ) : post?.status === "Completed" ? (
                <div className="vendordiv1">
                  <span>Job Completed on</span>
                  <h4>{post?.job_complete_on}</h4>
                </div>
              ) : (
                <div className="vendordiv1">
                  <span>Posted On</span>
                  <h4>{post?.date_created}</h4>
                </div>
              )}
            </div>
            <div className="vendordiv1">
              <span>Venue</span>
              <h4>{post?.venue}</h4>
            </div>

            <p>{post?.requirements}</p>
            {post?.status === "Confirmed" ? (
              <>
                <h3>Job Track</h3>
                <div className="bugetdiv">
                  <div className="vendordiv1">
                    <span>Job Posted on</span>
                    <h4>{post?.date_created}</h4>
                  </div>
                  <div className="vendordiv1">
                    <span>Job Won on</span>
                    <h4>{post?.job_won_on}</h4>
                  </div>
                </div>
              </>
            ) : post?.status === "Cancelled" ? (
              <>
                <h3>Job Track</h3>
                <div className="bugetdiv">
                  <div className="vendordiv1">
                    <span>Job Posted on</span>
                    <h4>{post?.date_created}</h4>
                  </div>
                  <div className="vendordiv1">
                    <span>Job Won on</span>
                    <h4>{post?.job_won_on}</h4>
                  </div>
                  <div className="vendordiv1">
                    <span>Job Cancelled on</span>
                    <h4>{post?.job_cancel_on}</h4>
                  </div>
                </div>
              </>
            ) : post?.status === "Completed" ? (
              <>
                <h3>Job Track</h3>
                <div className="bugetdiv">
                  <div className="vendordiv1">
                    <span>Job Posted on</span>
                    <h4>{post?.date_created}</h4>
                  </div>
                  <div className="vendordiv1">
                    <span>Job Won on</span>
                    <h4>{post?.job_won_on}</h4>
                  </div>
                  <div className="vendordiv1">
                    <span>Job Completed on</span>
                    <h4>{post?.job_complete_on}</h4>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <span className="p">{Error}</span>

            {post?.status === "Confirmed" ? (
              <button className="complete_btn" onClick={completejob}>
                Mark As Completed
              </button>
            ) : (
              ""
            )}

            <h4>User Details</h4>
            <div className="Userprofile">
              <div className="userprofileinfo">
                {post ? (
                  <img
                    src={base + post?.user_details.profile_pic}
                    alt=""
                    className="userprofileinfo_img"
                  />
                ) : (
                  <div className="userprofileinfo_img" />
                )}
                <div className="profilechat">
                  <h2>
                    {post?.user_details.first_name +
                      " " +
                      post?.user_details.last_name}
                  </h2>
                  {(post?.is_bid ||
                    post?.status === "Open" ||
                    post?.status === "Confirmed") && (
                    <img src={img} onClick={() => Chat(post?.user_details)} />
                  )}
                </div>
              </div>
              <div
                className="Viewuserprofile"
                onClick={() => viewprofile(post?.user_details.id)}
              >
                View Profile
              </div>
            </div>
          </div>
        </div>
        {post?.status === "Completed" ? (
          <>
            <ul className="couplepostdetails_list">
              <li>
                <NavLink className="bid" smooth="true" to="bid">
                  Bidder
                </NavLink>
              </li>
              <li>
                <NavLink className="chat" smooth="true" to="ratting">
                  Ratting
                </NavLink>
              </li>
            </ul>
            <Outlet />
          </>
        ) : (
          <>
            <div className="couplepost_totalbids">
              <h2>Total Bids</h2>
            </div>
            {bider?.length > 0 ? (
              bider?.map((value, index) => (
                <div key={index} className="couplepost_bids">
                  <img src={base + value.vender_profile_pic} alt="" />
                  <div className="couplepost_bidsright">
                    <h2>
                      {value.vender_first_name + " " + value.vender_last_name}
                    </h2>
                    <div>
                      <Box
                        sx={{
                          "& > legend": { mt: 2 },
                        }}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Rating
                          name="read-only"
                          value={parseInt(value.rate)}
                          readOnly
                        />
                        <span style={{ padding: "10px" }}>
                          ({value.reviews} Reviews)
                        </span>
                      </Box>
                    </div>

                    <div className="couplepost_miles">
                      <img src={locationicon} alt="" />
                      <span>{value.distance} miles away</span>
                    </div>
                    <div className="couplepostdetails_bidinfo">
                      <div className="couplepost_biddetails">
                        <p>bid</p>
                        <h4>${value.bid}</h4>
                      </div>
                      <div className="couplepost_biddetails">
                        <p>bid On</p>
                        <h4>{value.bid_on}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="nodata">No Bids</div>
            )}
            {post?.status === "Open" ? (
              <>
                <div className="couplepost_bidinput">
                  <form onSubmit={Bidsubmit} className="buttonIn">
                    <input
                      onChange={handlechange}
                      value={bid}
                      type="number"
                      placeholder="Bid On Job"
                      // disabled={post?.is_bid ? "disable" : ""}
                      min={`${post?.budget - 200}`}
                      max={`${Number(post?.budget) + 200}`}
                    />
                    <button
                      className="couplepost_icon"
                      // disabled={post?.is_bid ? "disable" : ""}
                      type="submit"
                    >
                      <img src={sendicon} alt="send_icon" />
                    </button>
                  </form>
                  <p>Only round up value are allowed(e.g.$200,not $200.23)</p>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      {isLoding && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="couplepost_modalname">Payment account issue</h1>
          <p className="couplepost_modal">
            KYC Required for Bidding on Jobs – Please Complete the Process
          </p>
          <button
            type="button"
            className="couplepost_button"
            onClick={handlecomplete}
          >
            Complete KYC
          </button>
          <button onClick={() => setOpen(false)} className="couplepost_button">
            Cancel
          </button>
        </Box>
      </Modal>
    </>
  );
};
export default CouplePostdetail;
