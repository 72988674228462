import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "./Vendorfilter.css";
import "../userflow/Searchbox";
import SearchBox from "../userflow/Searchbox";
import { useEffect } from "react";
import AppConfig from "../../../Api/AppConfig";
import axios from "axios";

const style = {
  position: "absolute",
  top: { xs: "60%", sm: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "580px" },
  bgcolor: "white",
  boxShadow: 10,
  p: { xs: 1, sm: 4 },
  fontFamily: "DM Sans",
  borderRadius: "30px",
  overflow: "auto",
  height: "620px",
  border: "none",
};

const VFilter = ({
  showfilter,
  setShowfilter,
  apply,
  seterror,
  clearfilter,
}) => {
  const [value, setValue] = useState(10);
  const [venue, setvenue] = useState();
  const [valid, setvalid] = useState();
  const [lat, setlat] = useState();
  const [long, setlong] = useState();
  const [data, setdata] = useState([]);
  const [selected, setselected] = useState();
  const navigate = useNavigate();

  const handleClose = () => setShowfilter(false);

  const InputEventJob = (e) => {
    setvenue(e.target.value);
  };
  const latlong = (places, latLng) => {
    setvalid();
    setvenue(places.formatted_address);
    setlat(latLng.lat);
    setlong(latLng.lng);
  };

  useEffect(() => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}master_data`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data.prof;
          setdata(redata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  }, []);

  const handlechange = (e) => {
    setvalid();
    setselected(e.target.value);
  };

  const handleapply = async (e) => {
    e.preventDefault();
    if (lat === undefined) {
      setvalid("Please select place");
    } else if (selected === undefined) {
      setvalid("Please select profession");
    } else {
      setShowfilter(false);
      apply(value, lat, long, selected);
    }
  };
  const clear = () => {
    setlat();
    setlong();
    setvenue();
    setselected();
    setShowfilter(false);
    clearfilter();
  };
  return (
    <>
      <Modal
        open={showfilter}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="vendorfilter_nav">
            <div></div>
            <CloseIcon
              style={{ cursor: "pointer", color: "var(--black)" }}
              onClick={handleClose}
            />
          </div>

          <h1 className="filter_heading">Filter By</h1>
          <form className="filter_data" onSubmit={handleapply}>
            <h1 className="filter_name">Location</h1>
            <SearchBox
              venue={venue}
              InputEventJob={InputEventJob}
              onPlaceSelected={latlong}
              names="Place"
              ph="Place"
              className="filter_input"
            />

            <div className="filter_location">
              <h1 className="filter_name">Distance</h1>
              <p className="filter_mile">{value} miles</p>
            </div>

            <Slider
              defaultValue={10}
              aria-label="Default"
              valueLabelDisplay="auto"
              max={100}
              onChange={(v) => setValue(v.target.value)}
            />
            <h1 className="filter_name">Profession</h1>
            <div className="filter_checkbox">
              {data.map((d, index) => (
                <div className="filter_checkbox_data" key={index}>
                  <input
                    type="checkbox"
                    id="professions"
                    value={d.name}
                    checked={selected === d.name}
                    onChange={handlechange}
                  />
                  <label htmlFor="professions">{d.name}</label>
                </div>
              ))}
            </div>
            <p className="error">{valid}</p>

            <button type="submit" className="filter_button">
              Apply
            </button>
            <button type="button" className="filter_button2" onClick={clear}>
              clear all
            </button>
          </form>
        </Box>
      </Modal>
    </>
  );
};
export default VFilter;
