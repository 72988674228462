import React from "react";
import "./AboutUs.css";
import aboutimg from "../../Assets/About.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import card1 from "../../Assets/band-mates-performing-together.jpg";
import card2 from "../../Assets/unique vendor.jpg";
import card3 from "../../Assets/photographer.jpg";
import card4 from "../../Assets/light-wedding-open-air-terrace-with-floral-arch.jpg";
import card5 from "../../Assets/officiants.jpg";
import card6 from "../../Assets/cake design.jpg";
import card7 from "../../Assets/female-wedding-planner-working-with-client.jpg";
import card8 from "../../Assets/techno-party-lifestyle.jpg";
import card9 from "../../Assets/women-making-preparations-wedding.jpg";
import card10 from "../../Assets/florist-making-beautiful-floral-arrangement.jpg";
import Underline from "../UI/Underline";
import { motion } from "framer-motion";
import { duration } from "@mui/material";
import Navbar from "../UI/Navbar";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="container">
      {/* <Navbar/> */}
      <div className="about" id="AboutUs">
        <h2>About Us</h2>
        <p>
          Wedved is a wedding app where vendors bid on your wedding needs. Just
          post your requirements, location, date, and budget, and available
          vendors will bid, saving you time and effort.
        </p>
      </div>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: -1 }}
        transition={{ delay: 0.1, duration: 1 }}
        className="projects"
      >
        <div className="firstdiv">
          <h1>417+</h1>
          <p>Project Done</p>
          <div>
            <Underline />
          </div>
          <p>
            {/* Urna purus netus ut aptent <br /> inceptos faucibus turpis tincidunt{" "}
            <br /> amit jusso veverra */}
          </p>
        </div>
        <div className="aboutimg">
          <motion.img
            src={aboutimg}
            alt=""
            initial={{ height: 0, opacity: 1 }}
            whileInView={{ height: 350, opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
          />
        </div>
        <div className="seconddiv">
          <h1>500+</h1>
          <p>Happy client</p>
          <div>
            <Underline />
          </div>
          <p>
            {/* Urna purus netus ut aptent <br /> inceptos faucibus turpis tincidunt
            <br /> amit jusso veverra */}
          </p>
        </div>
      </motion.div>

      {/* second section */}

      <div className="WeAre">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 1 }}
          transition={{ delay: 0.2, duration: 1 }}
          className="ERROR"
        >
          <div>
            <h4>Who we are</h4>
            <h1>
              Making Precious Moments <br /> of Your Special Day.
            </h1>
            <div style={{ marginTop: "1rem" }}>
              <Underline />
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: -1 }}
          transition={{ delay: 0.2, duration: 1 }}
          className="right"
        >
          <div className="khenidiv">
            <p style={{ paddingBottom: "1rem", marginRight: "60px" }}>
              Your special day deserves nothing less than extraordinary, and
              with WedVed, every moment becomes a cherished memory. Uncover
              themagic that awaits and embark on a journey to create timeless
              moments that will be etched in your heart forever.
            </p>
            <div>
              <button
                className="btn1"
                onClick={() => navigate("/Homelist_Gridview")}
              >
                Discover More <ArrowRightAltIcon />
              </button>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Card section */}
      <section className="Cardlist">
        <div className="cardlist_up">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="Cards"
          >
            <img src={card1} alt="" />
            <h2>Live Music</h2>
            <p>Feel the rhythm of your love with our Live Music vendors. </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="Cards"
          >
            <img src={card2} alt="" />
            <h2>Unique Vendors</h2>
            <p>Discover the extraordinary with our Unique Vendors. </p>
          </motion.div>
        </div>
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="Cards"
          >
            <img src={card3} alt="" />

            <h2>Photography</h2>
            <p>
              Capture the essence of your love through the lens of our expert
              photographers.{" "}
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: -1 }}
            transition={{ delay: 0.1, duration: 0.5 }}
            className="Cards"
          >
            <img src={card4} alt="" />
            <h2>Decoration</h2>
            <p>
              Elevate the aesthetic of your celebration with our talented
              Decorators.{" "}
            </p>
          </motion.div>
        </div>

        <div className="cardlist_up">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="Cards"
          >
            <img src={card5} alt="" />
            <h2>Officiants </h2>
            <p>Seal your love with a ceremony as unique as your story. </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="Cards"
          >
            <img src={card6} alt="" />
            <h2>Bakers</h2>
            <p>
              Satisfy your sweetest cravings with our exceptional Dessert
              Bakers.
            </p>
          </motion.div>
        </div>
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="Cards"
          >
            <img src={card7} alt="" />

            <h2>Wedding Planners</h2>
            <p>
              Transform your wedding dreams into a seamless reality with our
              dedicated Wedding Planners.{" "}
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: -1 }}
            transition={{ delay: 0.1, duration: 0.5 }}
            className="Cards"
          >
            <img src={card8} alt="" />
            <h2>DJ’s</h2>
            <p>
              Set the perfect soundtrack for your love story with our talented
              DJs.{" "}
            </p>
          </motion.div>
        </div>

        <div className="cardlist_up">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: -1 }}
            transition={{ delay: 0.1, duration: 0.5 }}
            className="Cards"
          >
            <img src={card9} alt="" />
            <h2>Hair & Makeup</h2>
            <p>
              Enhance your natural beauty and radiate confidence on your special
              day with our skilled Hair & Makeup artists{" "}
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: -1 }}
            transition={{ delay: 0.1, duration: 0.5 }}
            className="Cards"
          >
            <img src={card10} alt="" />
            <h2>Florists</h2>
            <p>
              Breathe life into your love story with the enchanting blooms
              curated by our skilled Florists{" "}
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
