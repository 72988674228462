import React from "react";
import "./Vendor.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Vendor1 from "../../Assets/vendor1.png";
import Vendor2 from "../../Assets/vendor2.png";
import Vendor3 from "../../Assets/vendor3.png";
import Vendor4 from "../../Assets/vendor4.png";
import ratingicon from "../../Assets/rating.png";
import Underline from "../UI/Underline";
import { motion } from "framer-motion";
import { Rating } from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

const Vendor = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {/* <Navbar/> */}
      <section className="vendor" id="Vendor">
        <motion.div
          initial={{ opacity: 0, y: 15 }}
          whileInView={{ opacity: 1, y: -5 }}
          transition={{ delay: 0.2, duration: 1 }}
          className="vText"
        >
          <span>Our Team</span>
          <h1>Our Top Rated Vendors</h1>
          <div style={{ marginTop: "1rem" }}>
            <Underline />
          </div>
        </motion.div>
        <div className="cards">
          {/* <Slider {...settings}> */}
          <motion.div
            // initial={{ opacity: 0, x: -100 }}
            // whileInView={{ opacity: 1, x: 1 }}
            // transition={{ delay: 0.2, duration: 0.5 }}
            className="mycard"
            layout
          >
            <img src={Vendor1} alt="" />
            <h3>Arnov Royce</h3>
            <p>Wedding Planner</p>
            <Rating
              name="read-only"
              value={5}
              readOnly
              style={{ gap: "3px", fontSize: "22px" }}
            />
          </motion.div>

          <motion.div
            // nitial={{ opacity: 0,  }}
            // whileInView={{ opacity: 1, }}
            // transition={{ delay: 0.2, duration: 0.2 }}
            layout
            className="mycard"
          >
            <img src={Vendor2} alt="" />
            <h3>Lacy Evence</h3>
            <p>Dancer, Singer & Photographer</p>
            <Rating
              name="read-only"
              value={5}
              readOnly
              style={{ gap: "3px", fontSize: "22px" }}
            />
          </motion.div>

          <motion.div
            // nitial={{ opacity: 0, }}
            // whileInView={{ opacity: 1, }}
            // transition={{ delay: 0.2, duration: 0.2 }}
            layout
            className="mycard"
          >
            <img src={Vendor3} alt="" />
            <h3>Michal Jeams</h3>
            <p>Photographer</p>
            <Rating
              name="read-only"
              value={5}
              readOnly
              style={{ gap: "3px", fontSize: "22px" }}
            />
          </motion.div>

          <motion.div className="mycard">
            <img src={Vendor4} alt="" />
            <h3>Mikke Furry</h3>
            <p>Florist</p>
            <Rating
              name="read-only"
              value={5}
              readOnly
              style={{ gap: "3px", fontSize: "22px" }}
            />
          </motion.div>
          {/* </Slider> */}
        </div>
        <div className="findbtn btnM">
          <button className="btn1">
            More Vendors <ArrowRightAltIcon />{" "}
          </button>
        </div>
      </section>
    </div>
  );
};

export default Vendor;
