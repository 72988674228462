import React from 'react'
import './Leftarrow.css'
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";


const Leftarrow = (prop) => {
  return (
    <div>
        <div className="leftarrow" onClick={prop.onClick}>
            <KeyboardArrowLeftIcon />
        </div>
    </div>
  )
}

export default Leftarrow