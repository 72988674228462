import "./App.css";
import { Routes, Route } from "react-router-dom";
import HeaderNav from "./components/LandingPage/HeaderNav";
import AboutUs from "./components/LandingPage/AboutUs";
import Services from "./components/LandingPage/Services";
import Vendor from "./components/LandingPage/Vendor";
import Testimonials from "./components/LandingPage/Testimonials";
import Footer from "./components/LandingPage/Footer";
// import Contactus from "./components/LandingPage/Contactus";
import ProtectedRoute from "./ProtectedRouter_Common";

import Login from "./components/Auth/Login";
import Selectprofile from "./components/Auth/Selectprofile";
import Forgot from "./components/Auth/Forgot";
import Accountoption from "./components/Auth/Accountoption";
import CreateAccount from "./components/Auth/CreateAccount";
import ResetPassword from "./components/Pages/common/ResetPassword";
import EditProfile from "./components/Pages/common/EditProfile";
import Ab from "./components/Pages/common/About";
import Nf from "./components/Pages/common/Notification_setting";
import Term from "./components/Pages/common/Term&condition";
import Privacypolicy from "./components/Pages/common/Privacypolicy";
import ChangePassword from "./components/Pages/common/Changepassword";
import Settingsidebar from "./components/Pages/common/Setting";

import Vendorcompleteprofile from "./components/Pages/vendorflow/CompleteProfile";
import Profession from "./components/Pages/vendorflow/Profession";
import VendorHomeList from "./components/Pages/vendorflow/VendorHomelist";
import VendorGridview from "./components/Pages/vendorflow/VendorGridview";

import HomeList from "./components/Pages/userflow/HomeList";
import Mapview from "./components/Pages/userflow/Mapview";
import MyJobs from "./components/Pages/userflow/MyJobs";
import VendorProfile from "./components/Pages/userflow/VendorProfile";
import Cards from "./components/Pages/userflow/Cards";
import Addcard from "./components/Pages/userflow/Addcards";

import Createjob from "./components/Pages/userflow/Createjob.jsx";
import CompleteProfile from "./components/Pages/userflow/CompleteProfile";
import VendorMapview from "./components/Pages/vendorflow/VendorMapview";
import VendorMyJobs from "./components/Pages/vendorflow/VendorMyJob";
import CouplePostdetail from "./components/Pages/vendorflow/CouplePostdetails";
import CoupleProfile from "./components/Pages/vendorflow/CoupleProfile";
import Jobbid from "./components/Pages/userflow/JobBid";
import Vendorprofessions from "./components/Pages/userflow/Vendorprofession";
import Gridview from "./components/Pages/userflow/Gridview";
import ProtectedRoute_Couple from "./ProtectedRouter_Couple";
import ProtectedRoute_Vendor from "./ProtectedRoute_Vendor";
import Updateprofession from "./components/Pages/vendorflow/UpdateProfession";
import Openchat from "./components/Pages/userflow/Openchat";
import Confirmed from "./components/Pages/userflow/Jobconfirmed";
import Bidder from "./components/Pages/vendorflow/Postbid";
import Ratting from "./components/Pages/vendorflow/Postratting";
import AddProfession from "./components/Pages/vendorflow/AddProfession";
import RecentlyBid from "./components/Pages/vendorflow/Recentlybid";
import Chat from "./components/Pages/common/Chat";
import { useEffect } from "react";
import TiktokPixel from "tiktok-pixel";

function App() {
  useEffect(() => {
    // Initialize TikTok Pixel with your actual TikTok Pixel ID
    const email = JSON.parse(localStorage.getItem("data"))?.data?.email;
    const advancedMatching = {
      email: email ? email : "",
      phone_number: "",
    };

    const options = {
      debug: true, // enable logs
    };
    TiktokPixel.init("CMN6AAJC77UEKGPKJK2G", advancedMatching, options);
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/*" exact element={<HeaderNav />} />
        <Route path="/selectprofile" element={<Selectprofile />} />
        <Route path="/Splash" element={<Accountoption />} />
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />
        <Route path="/Forgot" element={<Forgot />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route
          path="/Cards"
          element={
            <ProtectedRoute_Couple>
              <Cards />
            </ProtectedRoute_Couple>
          }
        />

        <Route
          path="/AddCard"
          element={
            <ProtectedRoute_Couple>
              <Addcard />
            </ProtectedRoute_Couple>
          }
        />
        <Route
          path="/CompleteProfile"
          element={
            <ProtectedRoute_Couple>
              <CompleteProfile />
            </ProtectedRoute_Couple>
          }
        />
        <Route
          path="/VendorCompleteProfile"
          element={
            <ProtectedRoute_Vendor>
              <Vendorcompleteprofile />
            </ProtectedRoute_Vendor>
          }
        />

        <Route
          path="/HomeList_Gridview"
          element={
            <ProtectedRoute_Couple>
              <Gridview />
            </ProtectedRoute_Couple>
          }
        />
        <Route
          path="/HomeList_Mapview"
          element={
            <ProtectedRoute_Couple>
              <Mapview />
            </ProtectedRoute_Couple>
          }
        />
        <Route
          path="/VendorHomelist_Gridview"
          element={
            <ProtectedRoute_Vendor>
              <VendorGridview />
            </ProtectedRoute_Vendor>
          }
        ></Route>
        <Route
          path="/VendorHomelist_Mapview"
          element={
            <ProtectedRoute_Vendor>
              <VendorMapview />
            </ProtectedRoute_Vendor>
          }
        />

        <Route
          path="/Professions"
          element={
            <ProtectedRoute_Vendor>
              <Profession />
            </ProtectedRoute_Vendor>
          }
        />
        <Route
          path="/EditProfession"
          element={
            <ProtectedRoute_Vendor>
              <Updateprofession />
            </ProtectedRoute_Vendor>
          }
        ></Route>
        <Route
          path="/AddProfession"
          element={
            <ProtectedRoute_Vendor>
              <AddProfession />
            </ProtectedRoute_Vendor>
          }
        ></Route>

        <Route
          path="/EditProfile"
          element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path="MyJobs"
          element={
            <ProtectedRoute_Couple>
              <MyJobs />
            </ProtectedRoute_Couple>
          }
        />
        <Route
          path="/RecentBid"
          element={
            <ProtectedRoute_Vendor>
              <RecentlyBid />
            </ProtectedRoute_Vendor>
          }
        />
        <Route
          path="VendorMyJobs"
          element={
            <ProtectedRoute_Vendor>
              <VendorMyJobs />
            </ProtectedRoute_Vendor>
          }
        ></Route>

        <Route
          path="VendorProfile"
          element={
            <ProtectedRoute_Couple>
              <VendorProfile />
            </ProtectedRoute_Couple>
          }
        >
          <Route
            path="profession"
            element={
              <ProtectedRoute_Couple>
                <Vendorprofessions />
              </ProtectedRoute_Couple>
            }
          ></Route>
        </Route>

        <Route
          path="CoupleProfile"
          element={
            <ProtectedRoute_Vendor>
              <CoupleProfile />
            </ProtectedRoute_Vendor>
          }
        ></Route>

        <Route
          exact
          path="/Setting"
          element={
            <ProtectedRoute>
              <Settingsidebar />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/Setting_T&C"
          element={
            <ProtectedRoute>
              <Term />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/Setting_ChangePassword"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/Setting_Notification"
          element={
            <ProtectedRoute>
              <Nf />
            </ProtectedRoute>
          }
        ></Route>
        <Route exact path="/AboutUs" element={<Ab />}></Route>

        <Route exact path="/Terms&Condition" element={<Term />} />
        <Route exact path="/PrivacyPolicy" element={<Privacypolicy />} />

        <Route
          path="/Jobdetail_Bid"
          element={
            <ProtectedRoute_Couple>
              <Jobbid />
            </ProtectedRoute_Couple>
          }
        ></Route>
        <Route
          path="/Jobdetail_Openchat"
          element={
            <ProtectedRoute_Couple>
              <Openchat />
            </ProtectedRoute_Couple>
          }
        ></Route>
        <Route
          path="/Jobdetail_Confirmed"
          element={
            <ProtectedRoute_Couple>
              <Confirmed />
            </ProtectedRoute_Couple>
          }
        ></Route>
        <Route
          path="/couple_post_details"
          element={
            <ProtectedRoute_Vendor>
              <CouplePostdetail />
            </ProtectedRoute_Vendor>
          }
        >
          <Route
            path="bid"
            element={
              <ProtectedRoute_Vendor>
                <Bidder />
              </ProtectedRoute_Vendor>
            }
          />
          <Route
            path="ratting"
            element={
              <ProtectedRoute_Vendor>
                <Ratting />
              </ProtectedRoute_Vendor>
            }
          />
        </Route>

        <Route
          path="/Createjob"
          element={
            <ProtectedRoute_Couple>
              <Createjob />
            </ProtectedRoute_Couple>
          }
        />
        <Route
          path="/Chat"
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
