import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import { motion } from "framer-motion";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../../Firebase";
import AppConfig from "../../../Api/AppConfig";

const LogoutVendorModal = ({ setisloading }) => {
  const navigate = useNavigate();
  const [userdata, setuserdata] = useState();

  const url = `${AppConfig.BASE_URL_PROFILE}`;
  const userToken = localStorage.getItem("token");
  // const myimg = userdata.data.profile_pic;
  // const name = userdata.data.first_name + " " + userdata.data.last_name;
  // const rate = userdata.data.rate;
  // const review = userdata.data.reviews;

  useEffect(() => {
    getuser();
  }, []);

  const getuser = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}get_user_data`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = JSON.stringify(response);
          localStorage.setItem("data", resdata);
          setuserdata(response.data);
        })
        .catch((error) => {
          const err = error.response.data.message;
          // seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const handlelogOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.clear();
        navigate("/");

        if (!localStorage.getItem("token")) {
          navigate("/");
        }
      })
      .catch((error) => {
        // An error happened.
      });
  };
  const width = window.innerWidth < 500;
  const payment = () => {
    setisloading(true);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_payments`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res)
        .then((response) => {
          const redirect = response.data.data.stripe_url;
          window.location.href = redirect;
        })
        .catch((error) => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  return (
    <div>
      <motion.div
        initial={{ scale: 0.5 }}
        whileInView={{ scale: 1 }}
        transition={{ duration: 0.2 }}
        className="dropdown-content"
      >
        <div className="drop"></div>
        {userdata ? (
          <>
            <img
              src={url + userdata?.profile_pic}
              alt=""
              className="dropdown-content_img"
            />
            <h4 className="dropdown-content_heading">
              {userdata?.first_name + " " + userdata?.last_name}
            </h4>
          </>
        ) : (
          <>
            <div className="dropdown-content_img" />
            <div className="skeleton-heading" />
          </>
        )}
        <div style={{ marginBottom: "10px" }}>
          <Box
            sx={{
              "& > legend": { mt: 2 },
            }}
          >
            <Rating
              name="read-only"
              value={parseInt(userdata?.rate)}
              size="small"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "white" }}
                  fontSize="inherit"
                />
              }
              readOnly
            />
          </Box>
          <p style={{ fontSize: "15px" }}>({userdata?.reviews} Reviews)</p>
        </div>
        <Link smooth="true" to="" onClick={payment}>
          <span className="icon">
            <PaymentOutlinedIcon />
          </span>
          Bank Account
        </Link>
        <Link smooth="true" to="/EditProfile">
          <span className="icon">
            <BorderColorOutlinedIcon />
          </span>
          Edit Profile
        </Link>
        <Link smooth="true" to="/Professions">
          <span className="icon">
            <ContactPageOutlinedIcon />
          </span>
          Professions
        </Link>
        <Link smooth="true" to={width ? "/Setting" : "/Setting_Notification"}>
          <span className="icon">
            <SettingsOutlinedIcon />
          </span>
          Setting
        </Link>
        <Link smooth="true" to="/RecentBid">
          <span className="icon">
            <AttachMoneyIcon />
          </span>
          Recent Bid
        </Link>

        <Link smooth="true" to="Login" onClick={handlelogOut}>
          <span className="icon">
            <LogoutOutlinedIcon />
          </span>
          Logout
        </Link>
      </motion.div>
      {/* {isLoading && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )} */}
    </div>
  );
};

export default LogoutVendorModal;
