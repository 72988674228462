import React from "react";
import { useNavigate } from "react-router-dom";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import "./Profilemodal.css";
import AppConfig from "../../Api/AppConfig";

const Profilemodal = ({ setLogin }) => {
  const navigate = useNavigate();
  const [user, setuser] = useState(false);

  const url = `${AppConfig.BASE_URL_PROFILE}`;
  const userdata = JSON.parse(localStorage.getItem("data"));
  const myimg = userdata.data.profile_pic;
  const name = userdata.data.first_name + " " + userdata.data.last_name;
  const user_type = userdata.data.user_type;

  useEffect(() => {
    if (!localStorage.getItem("data")) {
      navigate("/Login");
    } else if (user_type == 1) {
      setuser(true);
    } else if (user_type == 2) {
      setuser(false);
    }
  });

  const handlelogOut = () => {
    localStorage.removeItem("data");
    localStorage.removeItem("user_type");
    localStorage.removeItem("token");
    localStorage.removeItem("couple_id");
    localStorage.removeItem("postview_id");
    localStorage.removeItem("update_id");
    localStorage.removeItem("update_data");
    localStorage.removeItem("login2");
    localStorage.removeItem("job_id");
    localStorage.removeItem("vender_id");
    localStorage.removeItem("room_id");
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    setLogin(false);
  };

  const hidden = () => {
    if (window.innerWidth < 500) {
      navigate("/Setting");
    } else {
      navigate("/Setting_Notification");
    }
  };

  return (
    <div>
      <motion.div
        initial={{ scale: 0.5 }}
        whileInView={{ scale: 1 }}
        transition={{ duration: 0.2 }}
        className="profilemodal-content"
      >
        <div className="drop"></div>
        <img src={url + myimg} alt="" />
        <h4>{name}</h4>
        {user && (
          <>
            <a href="" onClick={() => navigate("/HomeList_Gridview")}>
              <span className="icon">
                <HomeOutlinedIcon />
              </span>
              Home
            </a>
            <a href="#" onClick={() => navigate("/MyJobs")}>
              <span className="icon">
                <HomeRepairServiceOutlinedIcon />
              </span>
              MyJobs
            </a>
            <a href="#">
              <span className="icon">
                <PaymentOutlinedIcon />
              </span>
              Payment
            </a>
          </>
        )}
        {!user && (
          <>
            <a href="/VendorHomelist_Gridview">
              <span className="icon">
                <HomeOutlinedIcon />
              </span>
              Home
            </a>
            <a href="/VendorMyJobs">
              <span className="icon">
                <HomeRepairServiceOutlinedIcon />
              </span>
              MyJobs
            </a>
            <a href="#">
              <span className="icon">
                <PaymentOutlinedIcon />
              </span>
              Bank Account
            </a>
            <a href="#" onClick={() => navigate("/Professions")}>
              <span className="icon">
                <ContactPageOutlinedIcon />
              </span>
              Professions
            </a>
          </>
        )}
        <a href="#" onClick={() => navigate("/EditProfile")}>
          <span className="icon">
            <BorderColorOutlinedIcon />
          </span>
          Edit Profile
        </a>
        <a href="#" onClick={hidden}>
          <span className="icon">
            <SettingsOutlinedIcon />
          </span>
          Setting
        </a>
        <a href="#" onClick={handlelogOut}>
          <span className="icon">
            <LogoutOutlinedIcon />
          </span>
          Logout
        </a>
      </motion.div>
    </div>
  );
};

export default Profilemodal;
