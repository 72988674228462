import React from 'react'
import './SecondButton.css';

const SecondButton = ({children, type, onClick}) => {
  return (
    <div>
        <div className="mainbtndiv">
            <button onClick={onClick} type={type} id='Lbtn'>{children}</button>
        </div>
    </div>
  )
}

export default SecondButton