import React, { Fragment } from "react";
import "./HeaderNav.css";
import Button from "../UI/Button";
import wedlogo from "../../Assets/Wedved_Logo.png";
import couple from "../../Assets/couple.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { Fade as Hamburger } from "hamburger-react";
import AboutUs from "./AboutUs";
import Testimonials from "./Testimonials";
import Vendor from "./Vendor";
import Footer from "./Footer";
import Services from "./Services";
import { useState, useRef } from "react";
import { motion } from "framer-motion";
import { useEffect } from "react";
import Profilemodal from "../UI/Profilemodal";
import AppConfig from "../../Api/AppConfig";
import { Helmet } from "react-helmet/es/Helmet";

const HeaderNav = () => {
  // const [isMobile, setisMobile] = useState(false);
  const [isMobile, setisMobile] = useState(false);
  const [login, setLogin] = useState(false);
  const [showmodal, SetshowModal] = useState(false);
  const menuref = useRef();

  const url = `${AppConfig.BASE_URL_PROFILE}`;
  let myimg = "";
  let user_type = "";

  if (login) {
    const userdata = JSON.parse(localStorage.getItem("data"));
    myimg = userdata.data.profile_pic;
    user_type = userdata.data.user_type;
  }

  useEffect(() => {
    if (login) {
      const l2 = localStorage.getItem("login2");
      if (!l2) {
        if (user_type == 1) {
          navigate("/Homelist_Gridview");
        } else if (user_type == 2) {
          navigate("/VendorHomelist_Gridview");
        }
      }
    }
  });

  const checkUserToken = () => {
    const userToken = localStorage.getItem("token");
    if (!userToken || userToken === "undefined") {
      setLogin(false);
      return navigate("/");
    }
    setLogin(true);
  };
  useEffect(() => {
    let handler = (event) => {
      if (!menuref.current.contains(event.target)) {
        SetshowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    checkUserToken();
  }, [login]);

  const handlelogOut = () => {
    localStorage.removeItem("data");
    localStorage.removeItem("token");
    setLogin(!login);
  };
  const closemenu = () => {
    setisMobile(false);
  };
  const navigate = useNavigate();

  return (
    <Fragment>
      <Helmet>
        <title>Wedved</title>
        <link rel="icon" href="../../Assets/Wedved_Logo.png" />
        <meta
          name="description"
          content="Wed ved is a event hosting app. that lets couples to organize their Wedding and engagement ceremony. and wedding anniversary through an Event. In this app Event vendors can also list their Professions and they can bid on couples Events."
        />
        <meta
          name="keywords"
          content="Wedved,Wedding planning,Event Planning,event vendors,events,professionals,engagement,wedding anniversary planner,wedding anniversary"
        />
        <meta property="og:title" content="Wedved" />
        <meta
          property="og:description"
          content="Wed ved is a event hosting app. that lets couples to organize their Wedding and engagement ceremony. and wedding anniversary through an Event. In this app Event vendors can also list their Professions and they can bid on couples Events."
        />
      </Helmet>

      <nav className="main-nav" id="HeaderNav" ref={menuref}>
        <div className="logo" onClick={closemenu}>
          <a href="/home">
            <img alt="logo-image" className="nav_img" src={wedlogo} />
          </a>
        </div>

        <motion.ul className={isMobile ? "menulist-show menulist" : "menulist"}>
          <li>
            <Link to="HeaderNav" smooth="true" onClick={closemenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="AboutUs" smooth="true" onClick={closemenu}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="Services" smooth="true" onClick={closemenu}>
              Services
            </Link>
          </li>
          <li>
            <Link to="Testimonials" smooth="true" onClick={closemenu}>
              Testimonials
            </Link>
          </li>
          <li>
            <Link to="Vendor" smooth="true" onClick={closemenu}>
              Vendor
            </Link>
          </li>
          {/* <li>
            <Link to="/ContactUs" onClick={closemenu}>
              Contact Us
            </Link>
          </li> */}
          {!login && (
            <div className="btndiv">
              <button
                className="btn"
                onClick={() => navigate("/selectprofile")}
              >
                Login
              </button>
            </div>
          )}
        </motion.ul>
        <div className="responsive_toggle">
          {login && (
            <>
              <li>
                <Link
                  to=""
                  className="profilemainimg"
                  onClick={() => SetshowModal(true)}
                >
                  <img alt="user-image" src={url + myimg} />
                </Link>
              </li>
              {showmodal && <Profilemodal setLogin={setLogin} />}

              {/* <div className="btndiv">
            <button className="btn" onClick={handlelogOut}>
              LogOut
            </button>
          </div> */}
            </>
          )}

          <div className="toggle">
            <Hamburger
              size={27}
              cursor="pointer"
              color="#0dc3dc"
              onToggle={() => setisMobile(!isMobile)}
              toggled={isMobile}
            />
          </div>
        </div>
      </nav>

      {/* nav complete */}

      <section className="img-section">
        <div>
          <div></div>
          <img alt="image" src={couple} />
        </div>

        <div className="findvendor">
          <h1>
            Find a vendor to make your <br /> wedding a wonderful story
          </h1>
          <span>
            Welcome to WedVed – where your wedding dreams become an enchanting
            reality. Discover the perfect vendors to craft your love story into
            a masterpiece. Our curated selection ensures every detail is as
            extraordinary as the love you share
          </span>
          <div className="findbtn">
            <Button onClick={() => navigate("/Homelist_Gridview")}>
              Find Vendor
            </Button>
          </div>
        </div>
      </section>
      <AboutUs />
      <Services />
      <Vendor />
      <Testimonials />
      <Footer />
    </Fragment>
  );
};

export default HeaderNav;
