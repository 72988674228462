import { useEffect, useState } from "react";
import axios from "axios";
import renderHTML from "react-render-html";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppConfig from "../../../Api/AppConfig";

const Ab = () => {
  const navigate = useNavigate();
  const [ab, setAbout] = useState("");
  const [Error, seterror] = useState("");

  useEffect(() => {
    getAlldata();
  }, []);

  const getAlldata = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}about_us`,
        method: "GET",
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const ab = response.data.text;
          const newab = renderHTML(ab);
          setAbout(newab);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
        });
    } catch (error) {}
  };
  return (
    <>
      <div className="setting_titlenav">
        <ArrowBackIcon
          onClick={() => window.history.go(-1)}
          style={{ cursor: "pointer" }}
        />
        <h3>About Us</h3>
      </div>
      <div className="About_data">
        {ab}
        <span className="p">{Error}</span>
        <Outlet />
      </div>
    </>
  );
};
export default Ab;
