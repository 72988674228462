import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Addcards.css";
import card from "../../../Assets/card_img.png";
import usericon from "../../../Assets/user.png";
import cardicon from "../../../Assets/cardicon2.png";
import dateicon from "../../../Assets/calendar.png";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  useStripe,
  useElements,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppConfig from "../../../Api/AppConfig";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const Addcard = () => {
  const navigate = useNavigate();
  const [user, setuser] = useState(false);
  const [Error, seterror] = useState();
  const [isLoding, setIsloding] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const predata = JSON.parse(localStorage.getItem("data"));
  const user_type = predata.data.user_type;
  const userToken = localStorage.getItem("token");

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(220,220,220)",
      color: "rgba(0, 0, 0)",
      boxShadow: theme.shadows[1],
      fontSize: 13,
      maxWidth: 300,
    },
  }));

  useEffect(() => {
    if (!localStorage.getItem("data")) {
      navigate("/Login");
    } else if (user_type == 1) {
      setuser(true);
    } else if (user_type == 2) {
      setuser(false);
    }
  }, []);

  const cardElementOptions = {
    style: {
      base: {
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "20px",
        ":-webkit-autofill": {},
        "::placeholder": {},
      },
      invalid: {
        iconColor: "#FFC7EE",
        color: "#FFC7EE",
      },
    },
  };

  const backbtn = () => {
    navigate("/Homelist_Gridview");
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setIsloding(true);
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { token, error } = await stripe.createToken(cardNumberElement);

    if (error) {
      setIsloding(false);

      seterror(error.message);
    } else {
      const params = new FormData();
      params.append("card_token_id", token.id);

      try {
        let config = {
          url: `${AppConfig.BASE_URL_v1}couple_save_or_get_cards`,

          method: "POST",
          data: params,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authentication: userToken,
          },
        };

        axios(config)
          .then((res) => res.data)
          .then((response) => {
            setIsloding(false);
            if (user) {
              window.history.go(-1);
            } else {
              navigate("/VendorHomelist_Gridview");
            }
          })
          .catch((error) => {
            const err = error.response.data.message;
            seterror(err);

            setIsloding(false);
            if (error.response.data.code === 401) {
              localStorage.clear();
              navigate("/Login");
            }
          });
      } catch (error) {
        setIsloding(false);
      }
    }
  };
  return (
    <>
      <div className="addcard_nav">
        <div className="addcard_header">
          <ArrowBackIcon onClick={backbtn} style={{ cursor: "pointer" }} />
          <h3>Add Card</h3>
        </div>
        <img src={card} />
      </div>
      <div>
        <div className="addcard_details">
          <form id="card" onSubmit={handlesubmit}>
            <div className="details_input">
              <img src={usericon} alt="" />
              <input
                required
                name="name"
                type="text"
                placeholder="Name on Card"
              />
            </div>

            <div className="details_input">
              <img src={cardicon} alt="" />

              <div style={{ width: "70%" }}>
                <CardNumberElement options={cardElementOptions} />
              </div>
            </div>
            <div className="details2_input">
              <div className="addcard_date">
                <img src={dateicon} alt="" />
                <h3>Expiry Date</h3>
              </div>
            </div>
            <div className="date_field">
              <div className="date_input">
                <div style={{ width: "70%" }}>
                  <CardExpiryElement options={cardElementOptions} />
                </div>
              </div>
              <div className="date_input">
                <div style={{ width: "70%" }}>
                  <CardCvcElement options={cardElementOptions} />
                </div>
                <LightTooltip title="The CVC, also known as the CVV, is a three or four digit number printed on a debit or credit card to provide additional protection for the cardholder. ">
                  <HelpOutlineOutlinedIcon id="help" />
                </LightTooltip>
              </div>
            </div>
            <span className="p">{Error}</span>
            <button type="submit" className="addcard_button">
              submit
            </button>
          </form>
        </div>
        {isLoding && (
          <Backdrop
            open={true}
            sx={{
              color: "#0DC3D7",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress color="inherit" open={true} />
          </Backdrop>
        )}
      </div>
    </>
  );
};
export default function stripe() {
  return (
    <Elements stripe={stripePromise}>
      <Addcard />
    </Elements>
  );
}
