import React from "react";
import "../UI/Button.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Button = ({children,onClick}) => {
  return (
    <div className="findbtn">
      <button className="btn1" onClick={onClick}>{children}<ArrowRightAltIcon/></button>
      
    </div>
  );
};

export default Button;
