import React from "react";
import lock1 from "../../../Assets/lock1.png";
import SecondButton from "../../UI/SecondButton";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./ResetPassword.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "./Changepassword.css";
import Settingsidebar from "./Setting";
import { Outlet } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppConfig from "../../../Api/AppConfig";

const ChangePassword = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [opene, setOpene] = useState(false);
  const [Error, seterror] = useState("");
  const [isLoding, setIsloding] = useState(false);
  const [show, setshow] = useState(true);
  const [data, setData] = useState({
    old_pass: "",
    new_pass: "",
    confirmpassword: "",
  });
  const [validate, setvalidate] = useState({
    old_pass: "",
    new_pass: "",
    confirmPassword: "",
  });

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    if (window.innerWidth < 500) {
      setshow(false);
    } else {
      setshow(true);
    }
  }, []);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });

    validation(e);
  };

  const validation = (e) => {
    let { name, value } = e.target;
    setvalidate((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "new_pass":
          if (!value) {
            stateObj[name] = "Please enter New Password.";
          } else if (data.confirmPassword && value !== data.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password must be same.";
          } else {
            stateObj["confirmPassword"] = data.confirmPassword
              ? ""
              : validate.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (data.new_pass && value !== data.new_pass) {
            stateObj[name] = "Password and Confirm Password must be same.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setIsloding(true);

    const params = new FormData();
    params.append("old_pass", data.old_pass);
    params.append("new_pass", data.new_pass);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}change_password`,
        method: "PUT",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          e.target.reset();
          navigate("/Login");
        })
        .catch((error) => {
          const err = error.response.data.message;
          setIsloding(false);
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const togglebtn = () => {
    setOpen((prevopen) => !prevopen);
  };
  const togglebtns = () => {
    setOpens((prevopens) => !prevopens);
  };
  const togglebtne = () => {
    setOpene((prevopene) => !prevopene);
  };

  return (
    <>
      {show && <Settingsidebar />}
      {!show && (
        <div className="setting_titlenav">
          <ArrowBackIcon
            onClick={() => navigate("/Setting")}
            style={{ cursor: "pointer" }}
          />
          <h3>Change Password</h3>
        </div>
      )}
      <div className="change_data">
        <div className="pcontnt">
          <div className="cnp">
            <div className="texttt">
              <p>
                Your new password must be different from previous used password.
              </p>
            </div>
            <form onSubmit={handlesubmit} className="resetinputs">
              <div className="Rfirstinput">
                <img src={lock1} alt="" />
                <input
                  type={open ? "Text" : "password"}
                  id="oldpassword"
                  placeholder="Old Password"
                  name="old_pass"
                  onChange={handlechange}
                  required
                />

                {open ? (
                  <AiOutlineEye onClick={togglebtn} />
                ) : (
                  <AiOutlineEyeInvisible onClick={togglebtn} />
                )}
              </div>

              <div className="Rfirstinput">
                <img src={lock1} alt="" />
                <input
                  type={opens ? "Text" : "password"}
                  id="newpassword"
                  placeholder="New Password"
                  name="new_pass"
                  onChange={handlechange}
                  required
                  onBlur={validation}
                />

                {opens ? (
                  <AiOutlineEye onClick={togglebtns} />
                ) : (
                  <AiOutlineEyeInvisible onClick={togglebtns} />
                )}
              </div>
              {validate.new_pass && (
                <span className="err">{validate.new_pass}</span>
              )}
              <div className="Rfirstinput">
                <img src={lock1} alt="" />
                <input
                  type={opene ? "Text" : "password"}
                  id="confirmpassword"
                  placeholder="Confirm New Password"
                  name="confirmPassword"
                  onChange={handlechange}
                  required
                  onBlur={validation}
                />

                {opene ? (
                  <AiOutlineEye onClick={togglebtne} />
                ) : (
                  <AiOutlineEyeInvisible onClick={togglebtne} />
                )}
              </div>
              {validate.confirmPassword && (
                <span className="err">{validate.confirmPassword}</span>
              )}
              <span className="p">{Error}</span>
              <div className="Rbtn">
                <SecondButton type="submit">Change Password</SecondButton>
              </div>
            </form>
          </div>
        </div>
        {!isLoding && <div></div>}
        {isLoding && (
          <Backdrop
            open={true}
            sx={{
              color: "#0DC3D7",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress color="inherit" open={true} />
          </Backdrop>
        )}
        <Outlet />
      </div>
    </>
  );
};

export default ChangePassword;
