import { useState, useEffect } from "react";
import axios from "axios";
import locationicon from "../../../Assets/locationIcon.png";
// import Staricon from "../../../Assets/Staricon.png";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import AppConfig from "../../../Api/AppConfig";

const Bidder = () => {
  const navigate = useNavigate();
  const [bider, setBider] = useState();
  const [Error, seterror] = useState("");

  const base = `${AppConfig.BASE_URL_PROFILE}`;
  const userToken = localStorage.getItem("token");
  const id = JSON.parse(localStorage.getItem("job_id"));

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    const params = new FormData();
    params.append("post_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_view_couple_post`,

        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const biderdata = response.data.total_bids;
          setBider(biderdata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      <span className="p">{Error}</span>

      {bider?.map((value, index) => (
        <div key={index} className="couplepost_bids">
          <img src={base + value.vender_profile_pic} alt="" />
          <div className="couplepost_bidsright">
            <h2>{value.vender_first_name + " " + value.vender_last_name}</h2>
            <div>
              <Box
                sx={{
                  "& > legend": { mt: 2 },
                }}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Rating
                  name="read-only"
                  value={parseInt(value.rate)}
                  size="small"
                  readOnly
                />
                <span style={{ padding: "10px" }}>
                  ({value.reviews} Reviews)
                </span>
              </Box>
            </div>

            <div className="couplepost_miles">
              <img src={locationicon} alt="" />
              <span>{value.distance} miles away</span>
            </div>
            <div className="couplepostdetails_bidinfo">
              <div className="couplepost_biddetails">
                <p>bid</p>
                <h4>${value.bid}</h4>
              </div>
              <div className="couplepost_biddetails">
                <p>bid On</p>
                <h4>{value.bid_on}</h4>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default Bidder;
