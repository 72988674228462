import HomeList from "./HomeList";
import VendorCards from "./VendorCards";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import AppConfig from "../../../Api/AppConfig";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar } from "@mui/material";

const Gridview = () => {
  const [open, setOpen] = useState(false);
  const [totaluser, settotaluser] = useState([]);
  const [vendordata, setVendordata] = useState([]);
  const [tampData, setTampData] = useState(vendordata);
  const [latitude, setLat] = useState(null);
  const [longitude, setLong] = useState();
  const [cpage, setcpage] = useState();
  const [totalpage, setTotalpage] = useState(cpage);
  const [searchtext, setSearchText] = useState();
  const [searchclick, setsearchclick] = useState(false);
  const [isLoding, setIsloding] = useState(true);
  const [error, seterror] = useState([]);
  const userToken = localStorage.getItem("token");
  const Navigate = useNavigate();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        getAllData({ latitude, longitude });
        setLat(latitude);
        setLong(longitude);
        const search = document.getElementById("search");
        search.addEventListener("search", handleSearchClear);

        return () => {
          search.removeEventListener("search", handleSearchClear);
        };
      },
      (error) => {
        seterror("Please Enable Location");
        setIsloding(false);
        setOpen(true);
        console.error(error);
      }
    );
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSearchClear = () => {
    setSearchText("");
    setTampData(vendordata);
    setsearchclick(false);
    setcpage(totalpage);
  };
  const getAllData = (e) => {
    setIsloding(true);
    const params = new FormData();
    params.append("lat", e.latitude);
    params.append("long", e.longitude);
    params.append("miles", "");
    params.append("skill", "");

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_home_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const redata = response.pagination;
          settotaluser(redata.total_users);
          const newdata = response.data;
          setVendordata(newdata);
          setTampData(newdata);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
          setcpage(tpage);
        })
        .catch((error) => {
          setIsloding(false);
          const err = error.response.data.message;
          seterror(err);
          setOpen(true);
          if (error.response.data.code === 401) {
            localStorage.clear();
            Navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const click = (data) => {
    setIsloding(true);
    let currentpage = data.selected + 1;
    const params = new FormData();
    params.append("page", currentpage);
    params.append("lat", latitude);
    params.append("long", longitude);

    try {
      let config = {
        url: searchclick
          ? `${AppConfig.BASE_URL_v1}couple_search_api`
          : `${AppConfig.BASE_URL_v1}couple_home_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const redata = response.data;
          setTampData(redata);
        })
        .catch((error) => {
          setIsloding(false);
          const err = error.response.data.message;
          seterror(err);
          setOpen(true);
          if (error.response.data.code === 401) {
            localStorage.clear();
            Navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const handlesearch = (e) => {
    setSearchText(e.target.value);
    //   // if (   === 1){
    if (e.key === "Enter") {
      setIsloding(true);
      e.preventDefault();

      const params = new FormData();
      params.append("search_text", e.target.value);
      try {
        let config = {
          url: `${AppConfig.BASE_URL_v1}couple_search_api`,
          method: "POST",
          data: params,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authentication: userToken,
          },
        };

        axios(config)
          .then((res) => res.data)
          .then((response) => {
            setsearchclick(true);
            setIsloding(false);
            let filterResponse = response;

            if (filterResponse.data.length === 0) {
              setTampData();
            } else {
              setTampData(filterResponse.data);
              settotaluser(filterResponse.pagination.total_users);
              setTotalpage(filterResponse.pagination.total_pages);
            }
          })
          .catch((error) => {
            setIsloding(false);
            const err = error.response.data.message;
            seterror(err);
            setOpen(true);
            if (error.response.data.code === 401) {
              localStorage.clear();
              Navigate("/Login");
            }
          });
      } catch (error) {}
    }
    if (e.target.value.length === 1) {
      setTampData(vendordata);
      setTotalpage(cpage);
      setsearchclick(false);
    } else if (e.target.value === " ") {
      setTampData(vendordata);
      setTotalpage(cpage);
      setsearchclick(false);
    }
  };
  return (
    <>
      <HomeList totaluser={totaluser} />
      <div className="vendorcards">
        <VendorCards
          tampData={tampData}
          totalpage={totalpage}
          handlesearch={handlesearch}
          click={click}
        />
      </div>
      {isLoding && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={error}
      />
    </>
  );
};
export default Gridview;
