import { useState } from "react";
import CoupleCards from "./CoupleCards";
import VendorHomeList from "./VendorHomelist";
import { useEffect } from "react";
import axios from "axios";
import AppConfig from "../../../Api/AppConfig";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Snackbar } from "@mui/material";
import VFilter from "./Vendorfilter";

const VendorGridview = () => {
  const [open, setOpen] = useState(false);
  const [totaluser, settotaluser] = useState();
  const [coupledata, setCoupledata] = useState([]);
  const [tampData, setTampData] = useState(coupledata);
  const [latitude, setLat] = useState();
  const [longitude, setLong] = useState();
  const [isLoading, setisloading] = useState(false);
  const [filter, setfilter] = useState(false);
  const [isfilter, setisfilter] = useState(false);
  const [error, seterror] = useState();
  const [totalpage, setTotalpage] = useState();
  const [totalfpage, setTotalfpage] = useState();
  const [page, setpage] = useState();
  const userToken = localStorage.getItem("token");
  const data = JSON.parse(localStorage.getItem("data"));
  const stripe = data.data.is_stripe_validate;
  const navigate = useNavigate();

  useEffect(() => {
    if (stripe === false) {
      setOpen(true);
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        getAllData({ latitude, longitude });
        setLat(latitude);
        setLong(longitude);
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  const getAllData = (e) => {
    setisloading(true);
    const params = new FormData();
    params.append("lat", e.latitude);
    params.append("long", e.longitude);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_home_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = response.pagination;
          settotaluser(resdata.total_users);
          setTotalpage(resdata.total_pages);
          const alldata = response.data;
          setCoupledata(alldata);
          setTampData(alldata);
          setisloading(false);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const nextclick = (e) => {
    setisloading(true);
    setpage(e - 1);
    const params = new FormData();
    params.append("page", e);
    params.append("lat", latitude);
    params.append("long", longitude);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_home_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setisloading(false);
          const redata = response.data;
          setCoupledata(redata);
          setTampData(redata);
          const resdata = response.pagination;
          setTotalpage(resdata.total_pages);
        })
        .catch((error) => {
          setisloading(false);
          const err = error.response.data.message;
          seterror(err);
          setisloading(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const nextfclick = (e) => {
    setisloading(true);
    const params = new FormData();
    params.append("page", e);
    params.append("lat", latitude);
    params.append("long", longitude);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_search_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setisloading(false);
          const redata = response.data;
          setCoupledata(redata);
          setTampData(redata);
          const resdata = response.pagination;
          setTotalfpage(resdata.total_pages);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          setisloading(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const handleapply = async (value, lat, long, selected) => {
    setisfilter(true);
    setisloading(true);
    try {
      const params = new FormData();
      params.append("lat", lat);
      params.append("long", long);
      params.append("profession", selected);
      params.append("max_distance", value);
      const response = await axios.post(
        `${AppConfig.BASE_URL_v1}vender_search_api`,
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authentication: userToken,
          },
        }
      );
      const redata = response.data.data;
      setTampData(redata);
      setisloading(false);
      const resdata = response.data.pagination;
      settotaluser(resdata.total_users);
      setTotalfpage(resdata.total_pages);
    } catch (error) {
      const err = error.response.data.message;
      seterror(err);
      setisloading(false);
    }
  };

  const clearfilter = () => {
    setisfilter(false);
    const params = new FormData();
    params.append("lat", latitude);
    params.append("long", longitude);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_home_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = response.pagination;
          settotaluser(resdata.total_users);
          setTotalpage(resdata.total_pages);
          const alldata = response.data;
          setCoupledata(alldata);
          setTampData(alldata);
          setisloading(false);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  return (
    <>
      <VendorHomeList
        totalusers={totaluser}
        setfilter={setfilter}
        setisloading={setisloading}
        Error={error}
        isfilter={isfilter}
      />
      <div className="vendorcards">
        <CoupleCards
          tampData={tampData}
          nextclick={nextclick}
          nextfclick={nextfclick}
          totalpage={totalpage}
          isfilter={isfilter}
          totalfpage={totalfpage}
        />
      </div>
      <VFilter
        showfilter={filter}
        setShowfilter={setfilter}
        apply={handleapply}
        clearfilter={clearfilter}
      />

      {isLoading && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Please Verify Stripe Account"
      />
    </>
  );
};
export default VendorGridview;
