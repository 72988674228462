import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Screensback from "../../UI/Screensback";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SecondButton from "../../UI/SecondButton";
import "./EditProfile.css";
import sms from "../../../Assets/sms.png";
import usericon from "../../../Assets/user.png";
import cameraicon from "../../../Assets/cameraicon.png";
import axios from "axios";
import AppConfig from "../../../Api/AppConfig";

const EditProfile = () => {
  const navigate = useNavigate();

  const [isLoding, setIsloding] = useState(false);
  const [preview, setPreview] = useState();
  const [image, setImage] = useState();
  const [user, setuser] = useState(false);
  const [Error, seterror] = useState("");

  const profile = `${AppConfig.BASE_URL_PROFILE}`;
  const predata = JSON.parse(localStorage.getItem("data"));
  const user_type = predata.data.user_type;
  const userToken = localStorage.getItem("token");

  const [newdata, setNewdata] = useState({
    f_name: predata.data.first_name,
    l_name: predata.data.last_name,
    email: predata.data.email,
    profile_pic: predata.data.profile_pic,
    company_name: predata.data.company_name,
  });

  useEffect(() => {
    if (!localStorage.getItem("data")) {
      navigate("/Login");
    } else if (user_type == 1) {
      setuser(true);
    } else if (user_type == 2) {
      setuser(false);
    }
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    setNewdata((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const imagehandle = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setIsloding(true);
    const params = new FormData();
    params.append("f_name", newdata.f_name);
    params.append("l_name", newdata.l_name);
    params.append("l_name", newdata.l_name);
    params.append("profile_pic", image);
    {
      user_type === 2 && params.append("company_name", newdata.company_name);
    }

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}update_profile`,
        method: "PUT",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          e.target.reset();
          if (user_type == 1) {
            navigate("/Homelist_Gridview");
          } else if (user_type == 2) {
            navigate("/VendorHomelist_Gridview");
          }
          const redata = JSON.stringify(response);
          localStorage.setItem("data", redata);
        })
        .catch((error) => {
          setIsloding(false);
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const backbtn = () => {
    if (user) {
      navigate("/Homelist_Gridview");
    } else {
      navigate("/VendorHomelist_Gridview");
    }
  };
  console.log(newdata);

  return (
    <div>
      <div className="Editnav">
        <ArrowBackIcon onClick={backbtn} style={{ cursor: "pointer" }} />
        <h3>Edit Profile</h3>
      </div>

      <Screensback>
        <motion.div className="Editprofileback ">
          <motion.form
            onSubmit={handlesubmit}
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 1 }}
            transition={{ duration: 1 }}
            className="Editprofilectn"
          >
            <div className="Editimage">
              <div className="profile">
                {preview ? (
                  <img
                    src={preview}
                    alt=""
                    style={{
                      objectFit: "cover",
                      width: "110px",
                      height: "110px",
                    }}
                  />
                ) : (
                  <img
                    src={profile + newdata.profile_pic}
                    alt=""
                    style={{
                      objectFit: "cover",
                      width: "110px",
                      height: "110px",
                    }}
                  />
                )}
              </div>
              <input
                type="file"
                id="file"
                accept="image/*"
                onChange={imagehandle}
                name="profile_pic"
              />
              <label htmlFor="file" id="uploadbtn">
                <img src={cameraicon} alt="" />
              </label>
            </div>

            <div className="editinputsDiv">
              <div className="Editproinput">
                <img src={usericon} alt="" />
                <input
                  required
                  onChange={handlechange}
                  name="f_name"
                  type="text"
                  value={newdata.f_name}
                  placeholder="Enter Firstname"
                />
              </div>
              <div className="Editproinput">
                <img src={usericon} alt="" />
                <input
                  required
                  onChange={handlechange}
                  name="l_name"
                  type="text"
                  value={newdata.l_name}
                  placeholder="Enter Lastname"
                />
              </div>
            </div>
            {user_type == 2 ? (
              <div className="editinputsDiv">
                <div className="Editproemailinput">
                  <img src={usericon} alt="" />
                  <input
                    required
                    onChange={handlechange}
                    name="company_name"
                    type="text"
                    value={newdata.company_name}
                    placeholder="Enter Company Name"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="editinputsDiv">
              <div className="Editproemailinput">
                <img src={sms} alt="" />
                <input
                  readOnly
                  onChange={handlechange}
                  name="email"
                  type="email"
                  value={newdata.email}
                />
              </div>
            </div>
            <span className="p">{Error}</span>

            <div className="Cprofilebtns">
              <div className="savebtn">
                <SecondButton type="submit">Save</SecondButton>
              </div>
            </div>
          </motion.form>
        </motion.div>
      </Screensback>
      {!isLoding && <div></div>}
      {isLoding && (
        <Backdrop
          open={true}
          sx={{ color: "#0DC3D7", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </div>
  );
};
export default EditProfile;
