import React from "react";
import "./Cards.css";
import dots from "../../../Assets/threeDots.png";
import { useNavigate } from "react-router";
// import Modelpop from "../userflow/Modelpop";
import { useState, useEffect } from "react";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import cardicon2 from "../../../Assets/cardicon2.png";
import { Close } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppConfig from "../../../Api/AppConfig";

const Cards = () => {
  const [card, setcard] = useState();
  const [showimg, setShowimg] = useState(false);
  // const [openmodal, setOpenmodal] = useState(false);
  const [placed, setplaced] = useState(false);
  const [Error, seterror] = useState("");
  const [msg, setmsg] = useState();
  const [dlt, setdlt] = useState(false);
  const [id, setid] = useState();
  const [isLoding, setIsloding] = useState(true);

  const navigate = useNavigate();
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_save_or_get_cards`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          setcard(redata);
          setIsloding(false);
          // const initialShowimg = redata.reduce((acc, data, index) => {
          //   if (data.is_selected === 1) {
          //     acc.push(index);
          //   }
          //   return acc;
          // }, {});
          // setSelected(initialShowimg);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {
      setIsloding(false);
    }
  };
  const backbtn = () => {
    navigate("/Homelist_Gridview");
  };
  const savecard = () => {
    setIsloding(true);

    const params = new FormData();
    params.append("card_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}save_as_default_card`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          setmsg(response.message);
          setplaced(true);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);

          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {
      setIsloding(false);
    }
  };

  const deletecard = (id, index) => {
    setIsloding(true);

    const params = new FormData();
    params.append("card_id", id);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}delete_couple_card`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setdlt({ ...showimg, [index]: false });
          setIsloding(false);
          setmsg(response.message);
          setplaced(true);
          getdata();
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const toggleShowimg = (index) => {
    setShowimg((prevShowimg) => ({
      ...prevShowimg,
      [index]: !prevShowimg[index],
    }));
  };

  const handleCheckboxChange = (index) => {
    setcard((prevCard) => {
      const updatedCard = prevCard.map((data, i) => {
        if (i === index) {
          return { ...data, is_selected: !data.is_selected };
        } else {
          return { ...data, is_selected: false };
        }
      });

      return updatedCard;
    });
  };

  return (
    <div>
      <div>
        <div>
          <div>
            <div className="cards_nav">
              <ArrowBackIcon onClick={backbtn} style={{ cursor: "pointer" }} />
              <h3>Cards</h3>
            </div>
            <div className="card_div">
              <div className="card_list">
                {card?.map((data, index) => (
                  <div className="card_number">
                    <img src={data.card_img} alt="" className="brandimg" />
                    <h4>XXXX XXXX XXXX {data.last4}</h4>
                    <div
                      key={data.index}
                      className="firstC"
                      onClick={() => setid(data.id)}
                    >
                      <input
                        type="checkbox"
                        checked={data.is_selected}
                        onChange={() => handleCheckboxChange(index)}
                        name="selectcard"
                      />
                    </div>

                    {!dlt[index] ? (
                      <img
                        src={dots}
                        alt=""
                        className="dotimg"
                        onClick={() => setdlt({ ...showimg, [index]: true })}
                      />
                    ) : (
                      <Close
                        onClick={() => setdlt({ ...showimg, [index]: false })}
                      />
                    )}
                    {dlt[index] && (
                      <div
                        className="delete_modal"
                        onClick={() => deletecard(data.id, index)}
                      >
                        <p>delete</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <span className="p">{Error}</span>

              <div className="addcarddiv">
                <h3>Add New Cards</h3>
                <div className="dabitcard">
                  <div className="debiticon">
                    <img src={cardicon2} alt="" />
                    <h4>Add Credit/Debit Cards</h4>
                  </div>
                  <div
                    id="khainiiii"
                    className="addcbtn"
                    onClick={() => navigate("/Addcard")}
                  >
                    <button>Add</button>
                  </div>
                </div>
                {/* <div className="dabitcard">
                <div>
                  <img src={paypalicon} alt="" />
                  <h4>Add Paypal Account</h4>
                </div>
                  <div className="addcbtn">
                    <button>Add</button>
                  </div>
                </div>
                <div className="dabitcard">
                <div>
                  <img src={paypalAicon} alt="" />
                  <h4>Add Paypal Account</h4>
                </div>
                  <div className="addcbtn">
                    <button>Add</button>
                  </div>
                </div> */}
              </div>
              {card?.length > 0 && (
                <button className="cards_button" onClick={savecard}>
                  Save
                </button>
              )}
            </div>
            {card?.length === 0 && (
              <h3 style={{ textAlign: "center", marginTop: "20%" }}>
                Cards not added yet
              </h3>
            )}
          </div>
        </div>
      </div>
      {placed && (
        <div className="placed_bg">
          <div className="placed">
            <div>
              <CheckCircleOutlineIcon
                style={{ color: "green", fontSize: "xx-large" }}
              />
              <h2 style={{ color: "green" }}>{msg}</h2>
            </div>
            <button onClick={() => setplaced(false)}>OK</button>
          </div>
        </div>
      )}
      {isLoding && (
        <Backdrop
          open={true}
          sx={{ color: "#0DC3D7", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}

      {/* {openmodal && <Modelpop closemodal={setOpenmodal} />} */}
    </div>
  );
};

export default Cards;
