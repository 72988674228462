import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import "./Chat.css";
import sendicon from "../../../Assets/send_ic.png";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import AppConfig from "../../../Api/AppConfig";

const Chat = () => {
  const navigate = useNavigate();
  const [chatdata, setchatdata] = useState([]);
  const [groupedChatData, setGroupedChatData] = useState([]);
  const [interval, setinter] = useState();
  const [id, setid] = useState();
  const [msg, setmsg] = useState();
  const [user, setuser] = useState();
  const [Error, seterror] = useState("");
  const [img, setImage] = useState();
  const [imagePreview, setImagePreview] = useState(null);

  const userToken = localStorage.getItem("token");
  const room_id = localStorage.getItem("room_id");
  const vendor_profile = localStorage.getItem("profile");
  const messagesEnd = useRef(null);

  const base = `${AppConfig.BASE_URL_PROFILE2}`;

  const predata = JSON.parse(localStorage.getItem("data"));
  const user_type = predata.data.user_type;
  const location = useLocation();
  const vendorinfo = location.state;
  const inter = useEffect(() => {
    scrollToBottom();
  }, [msg]);

  useEffect(() => {
    if (!localStorage.getItem("data")) {
      navigate("/Login");
      if (interval) {
        clearInterval(interval);
      }
    } else if (user_type == 1) {
      setuser(true);
    } else if (user_type == 2) {
      setuser(false);
    }
  });

  const data = JSON.parse(localStorage.getItem("data"));

  useEffect(() => {
    getdata();
    setid(data.data.id);
    const inter = setInterval(getdata, 10000);
    setinter(inter);
  }, []);

  useEffect(() => {
    const groupedData = [];
    let currentDate = null;

    chatdata.forEach((message) => {
      const messageDate = new Date(message.timestamp).toDateString();

      if (messageDate !== currentDate) {
        groupedData.push({ date: messageDate, messages: [message] });
        currentDate = messageDate;
      } else {
        const lastGroupIndex = groupedData.length - 1;
        groupedData[lastGroupIndex].messages.push(message);
      }
    });

    setGroupedChatData(groupedData);
  }, [chatdata]);

  const getdata = () => {
    const params = new FormData();
    params.append("room_id", room_id);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}get_chat_date`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          setchatdata(redata);
          if (response.data.length > chatdata.length) {
            scrollToBottom();
          }
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
            if (interval) {
              clearInterval(interval);
            }
          }
        });
    } catch (error) {}
  };

  const scrollToBottom = () => {
    // messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight;
    messagesEnd.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const send = (e) => {
    const value = e.target.value;
    setmsg(value);
  };

  const sendmsg = (e) => {
    e.preventDefault();

    const params = new FormData();
    params.append("user_type", data.data.user_type);
    {
      msg && params.append("send_msg", msg);
    }
    {
      img && params.append("imgs", img);
    }
    params.append("room_id", room_id);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}send_msgs_api`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setmsg("");
          getdata(true);
          setImagePreview("");
          setImage("");
          scrollToBottom();
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
            if (interval) {
              clearInterval(interval);
            }
          }
        });
    } catch (error) {}
  };
  const backbtn = () => {
    if (interval) {
      clearInterval(interval);
    }
    window.history.go(-1);
  };
  const imagehandle = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };
  const showprofile = () => {
    if (user) {
      localStorage.setItem("vendor_id", vendorinfo.id);
      navigate("/VendorProfile/profession");
    } else {
      localStorage.setItem("couple_id", vendorinfo.id);
      navigate("/CoupleProfile");
    }
  };
  return (
    <>
      <div className="chat_title">
        <ArrowBackIcon onClick={backbtn} style={{ cursor: "pointer" }} />
        <h3>Chat</h3>
      </div>

      <div className="py-5" style={{ backgroundColor: "#eee" }}>
        <div className="d-flex justify-content-center chatbox ">
          <div md="10" lg="8" xl="6" className="chatboxi">
            <div
              id="chat2"
              className="chatcard"
              style={{ borderRadius: "15px" }}
            >
              <div
                className="d-flex justify-content-between align-items-center p-3 chatheader"
                onClick={showprofile}
              >
                <img src={vendorinfo.pic} />
                <h5 className="mb-0">{vendorinfo.name}</h5>
              </div>
              <div className="overflow">
                {groupedChatData?.map((group, index) => (
                  <div key={index}>
                    <p
                      style={{ textAlign: "center", color: "grey" }}
                      key={index}
                    >
                      {new Date().toDateString() ===
                      new Date(group.date).toDateString()
                        ? "Today"
                        : new Date(group.date).toDateString()}
                    </p>
                    {group.messages.map((message, i) => (
                      <div
                        key={i}
                        className={
                          message.sender_id !== id
                            ? "d-flex flex-row justify-content-start senderchat"
                            : "d-flex flex-row justify-content-end mb-4 pt-1 mychat"
                        }
                      >
                        {/* ... (previous code) */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          {message.imgs.length != 0 && (
                            <img
                              src={base + message.imgs}
                              style={{ width: "100px", height: "70px" }}
                            />
                          )}
                          {message.msg === null ||
                            (message.msg.length != 0 && (
                              <p
                                className={
                                  message.sender_id !== id
                                    ? "small p-2 ms-3 mb-1 rounded-3"
                                    : "small p-2 me-3 mb-1 text-white rounded-3 bg-primary"
                                }
                              >
                                {message.msg}
                              </p>
                            ))}
                          <p
                            className="small ms-3 mb-3 rounded-3 text-muted chat_time"
                            ref={messagesEnd}
                          >
                            {new Date(message.timestamp).toLocaleTimeString(
                              [],
                              { hour: "2-digit", minute: "2-digit" }
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                {/* {chatdata?.map((value,i) =>
                <>
                <p>--------------------------------{(new Date().toDateString()===new Date(value.timestamp).toDateString())?"today":new Date(value.timestamp).toDateString()}---------------------------------</p>
                  <div key={i} className={(value.sender_id !== id) ? ("d-flex flex-row justify-content-start senderchat") : ("d-flex flex-row justify-content-end mb-4 pt-1 mychat")} >
                    <div>
                      <p
                        className={(value.sender_id !== { id }) ? ("small p-2 ms-3 mb-1 rounded-3") : ("small p-2 me-3 mb-1 text-white rounded-3 bg-primary")}
                      >

                        {value.msg}
                      </p>

                      <p className="small ms-3 mb-3 rounded-3 text-muted chat_time"
                        ref={messagesEnd}
                      >
                        {new Date(value.timestamp).toLocaleTimeString('en-US', { timeStyle: 'short' })}
                      </p>
                    </div>
                  </div>
</>
                )} */}
              </div>
              <span className="p">{Error}</span>

              <div className="text-muted d-flex justify-content-start align-items-center p-3 chatfooter">
                <form onSubmit={sendmsg} className="chat_form">
                  <div className="chat_input">
                    {imagePreview && (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{ width: "80px", height: "50px" }}
                      />
                    )}
                    <input
                      type="text"
                      className="form-control form-control-lg typmsg"
                      id="exampleFormControlInput1"
                      placeholder="Type message"
                      onChange={send}
                      value={msg}
                    />
                    <input
                      type="file"
                      id="file"
                      accept="image/*"
                      onChange={imagehandle}
                      name="profile_pic"
                      multiple
                    />
                    <label htmlFor="file" id="uploadbtn">
                      <AttachmentOutlinedIcon
                        fontSize="medium"
                        style={{ rotate: "325deg", margin: "10px" }}
                      />
                    </label>
                  </div>
                  <button type="submit" className="sendbtn">
                    <a className="ms-3" href="#!">
                      <img src={sendicon} alt="send_button" />
                    </a>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
