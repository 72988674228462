import React from "react";
import "./Testimonials.css";
import ellipse1 from "../../Assets/ellipsef.png";
import ellipse2 from "../../Assets/ellipse2.png";
import ellipse3 from "../../Assets/ellipse3.png";
import rated from "../../Assets/rating.png";
import apple from "../../Assets/applestore.png";
import gstore from "../../Assets/googlestore.png";
import phone from "../../Assets/phones.png";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Underline from "../UI/Underline";
import { motion } from "framer-motion";
import Navbar from "../UI/Navbar";
import { Box, Rating } from "@mui/material";

const Testimonials = () => {
  return (
    <div>
      {/* <Navbar/> */}
      <motion.div
        initial={{ opacity: 0, y: 130 }}
        whileInView={{ opacity: 1, y: -5 }}
        transition={{ delay: 0.2, duration: 1 }}
        className="testimonials"
        id="Testimonials"
      >
        <div className="review">
          <span>Testimonial</span>
          <h1>What Our Clients Say’s About Us</h1>
          <div>
            <Underline />
          </div>
        </div>
        <div className="tcards">
          <div className="card1">
            <img src={ellipse1} alt="" />
            <h3>Jerry Willams</h3>
            <p>
              Choosing WedVed was the best decision for our wedding journey. Our
              day was nothing short of magical, thanks to WedVed's dedication to
              creating a personalized and stress-free experience
            </p>
            <Rating
              name="read-only"
              value={5}
              readOnly
              size="small"
              style={{ gap: "3px" }}
            />
          </div>
          <div className="card1">
            <img src={ellipse2} alt="" />
            <h3>Michelle Denton</h3>
            <p>
              WedVed exceeded all our expectations! The app's intuitive design
              simplified the wedding planning process, and their support team
              was incredibly responsive
            </p>
            <Rating
              name="read-only"
              value={5}
              readOnly
              size="small"
              style={{ gap: "3px" }}
            />
          </div>
          <div className="card1">
            <img src={ellipse3} alt="" />
            <h3>Alisa Reacher</h3>
            <p>
              WedVed turned our wedding dreams into reality! The app's
              user-friendly features made planning a joy. I highly recommends
              WedVed for a seamless, stress- free,and magical wedding
              experience.
            </p>
            <Rating
              name="read-only"
              value={5}
              readOnly
              size="small"
              style={{ gap: "3px" }}
            />
          </div>
        </div>
        <div className="arrow">
          <div className="leftr">
            <KeyboardArrowLeftIcon />
          </div>
          <div className="rightr">
            <KeyboardArrowRightIcon />
          </div>
        </div>
      </motion.div>

      <div className="getApp">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 1 }}
          transition={{ delay: 0.3, duration: 1 }}
          className="Leftside"
        >
          <span>Get Our App</span>
          <h1>
            Download the WedVed
            <br />
            App to your phone today!
          </h1>
          <Underline />
          <p>
            Download the WedVed app today and embark on your journey to a <br />
            stress-free, magical wedding experience
          </p>
          <div className="links">
            <a
              href="https://play.google.com/store/apps/details?id=com.wedved&pcampaignid=web_share"
              target="blank"
            >
              <img className="kheniicon2" src={gstore} alt="" />
            </a>
            <a
              href="https://apps.apple.com/in/app/wed-ved/id6449662239"
              target="blank"
            >
              <img className="kheniicon" src={apple} alt="" />
            </a>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: -1 }}
          transition={{ delay: 0.2, duration: 1 }}
          className="Rightside"
        >
          <img src={phone} alt="" />
          <div className="blur1"></div>
          <div className="blur"></div>
        </motion.div>
      </div>
    </div>
  );
};

export default Testimonials;
