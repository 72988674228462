import React from "react";
import locationicon from "../../../Assets/locationIcon.png";
import "./CoupleCards.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "../../UI/Nextslider.css";
import AppConfig from "../../../Api/AppConfig";

const CoupleCards = ({
  tampData,
  nextclick,
  nextfclick,
  totalpage,
  totalfpage,
  isfilter,
}) => {
  const navigate = useNavigate();
  const [Error, seterror] = useState();

  const base = `${AppConfig.BASE_URL_PROFILE}`;
  const userToken = localStorage.getItem("token");

  const viewDetails = (e) => {
    localStorage.setItem("job_id", e.id);
    navigate("/couple_post_details/bid");
  };

  const click = (data) => {
    let currentpage = data.selected + 1;
    nextclick(currentpage);
  };
  const fclick = (data) => {
    let currentpage = data.selected + 1;
    nextfclick(currentpage);
  };

  return (
    <div>
      <span className="p">{Error}</span>

      {tampData?.length > 0 ? (
        <>
          <div className="couplesprofile">
            {tampData?.map((value, index) => {
              return (
                <div key={index} className="couplebox">
                  <div className="cboxcntnt">
                    <div className="cname">
                      <img src={base + value.user.profile_pic} alt="" />
                      <div className="clocation">
                        <h3>{value.user.first_name}</h3>
                        <div className="cmiles">
                          <img src={locationicon} alt="" />
                          <span>{value.distance} Mile away</span>
                        </div>
                        <div className="cbudget">
                          <span>${value.budget}</span>Budget
                        </div>
                      </div>
                    </div>
                    <div className="csinger">
                      <h3>{value.lok_for}</h3>
                      <div className="csingerbtn">
                        {value.skills.map((skill, i) => (
                          <button key={i}>{skill}</button>
                        ))}
                        {/* <button>Hip - Hop</button>
                    <button>Wedding</button> */}
                      </div>
                    </div>
                    <div className="detailbtn">
                      <button onClick={() => viewDetails(value)}>
                        View Job Details
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            {isfilter && (
              <ReactPaginate
                pageCount={totalfpage}
                onPageChange={fclick}
                previousLabel="Previous"
                nextLabel="next"
                containerClassName="nextslider"
                pageClassName="sliderbuttons"
                pageLinkClassName="linkbuttons"
                previousClassName="sliderbuttons"
                previousLinkClassName="linkbuttons"
                nextClassName="sliderbuttons"
                nextLinkClassName="linkbuttons"
                activeClassName="activepage"
                activeLinkClassName="activepage"
                breakClassName="sliderbuttons"
                breakLinkClassName="linkbuttons"
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                disabledLinkClassName="disable"
              />
            )}
            {!isfilter && (
              <ReactPaginate
                pageCount={totalpage}
                onPageChange={click}
                previousLabel="Previous"
                nextLabel="Next"
                containerClassName="nextslider"
                pageClassName="sliderbuttons"
                pageLinkClassName="linkbuttons"
                previousClassName="sliderbuttons"
                previousLinkClassName="linkbuttons"
                nextClassName="sliderbuttons"
                nextLinkClassName="linkbuttons"
                activeClassName="activepage"
                activeLinkClassName="activepage"
                breakClassName="sliderbuttons"
                breakLinkClassName="linkbuttons"
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                disabledLinkClassName="disable"
              />
            )}
          </div>
        </>
      ) : (
        <div className="nodata">No data available</div>
      )}
    </div>
  );
};

export default CoupleCards;
