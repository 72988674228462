import React from "react";
import "./VendorMyJob.css";
import Navbar from "../../UI/Navbar";
// import { useState } from 'react';
// import VendorProfile from "./VendorProfile";
import VendorMyjobCards from "./VendorMyJobsCard";
import FilterModal2 from "../common/FilterModal2";
import { useEffect, useState } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import AppConfig from "../../../Api/AppConfig";

const VendorMyJobs = () => {
  const navigate = useNavigate();
  const [postdata, setpostdata] = useState();
  const [jobcard, setJobcard] = useState();
  const [totalpage, setTotalpage] = useState();
  const [isLoding, setIsloding] = useState(true);
  const [Error, seterror] = useState("");

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    getAlData();
  }, []);

  const getAlData = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_my_jobs`,
        method: "POST",
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);

          const pdata = response.data;
          setpostdata(pdata);
          const redata = response.data.post_data;
          setJobcard(redata);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
          seterror("");
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const click = (data) => {
    let currentpage = data.selected + 1;
    setIsloding(true);

    const params = new FormData();
    params.append("page", currentpage);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_my_jobs`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const redata = response.data.post_data;
          setJobcard(redata);
          seterror("");
        })
        .catch((error) => {
          setIsloding(false);
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const getAllData = (e) => {
    const params = new FormData();
    params.append("from_date", e[0].from);
    params.append("to_date", e[0].to);
    params.append("filter_status", e[0].num);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_my_jobs`,
        method: "POST",
        data: params,

        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const pdata = response.data;
          setpostdata(pdata);
          const redata = response.data.post_data;
          setJobcard(redata);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
          seterror("");
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      <Navbar />
      <div className="vmycontainer">
        <div className="vmyjobcntnt">
          <div className="vendortopdiv">
            <div className="vmyjobs">
              <h2>Posted Requirement</h2>
              <div className="vendorposts">
                <h3>{postdata?.total_posted}</h3>
                <span>Total Posted</span>
              </div>
              <div className="vendorConfirmed">
                <h3>{postdata?.total_confirmed}</h3>
                <span>Total Confirmed</span>
              </div>
              <div className="vendorWithdrawn">
                <h3>{postdata?.total_withdrawn}</h3>
                <span>Total Withdrawn</span>
              </div>
              <div className="vendorTotalCancelled">
                <h3>{postdata?.total_cancelled}</h3>
                <span>Total Cancelled</span>
              </div>
            </div>
            <div className="vendorjobicon">
              {/* <FilterModal /> */}
              <FilterModal2 getAllData={getAllData} clearfilter={getAlData} />
            </div>
          </div>
        </div>
      </div>
      <div className="vjobcardss">
        <VendorMyjobCards
          jobcard={jobcard}
          click={click}
          totalpage={totalpage}
          seterror={seterror}
        />
      </div>
      {isLoding && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </>
  );
};

export default VendorMyJobs;
