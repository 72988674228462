import React from "react";
import "./HomeList.css";
import Navbar from "../../UI/Navbar";
import { NavLink, Outlet } from "react-router-dom";

const HomeList = ({ totaluser, Error }) => {
  return (
    <div>
      <Navbar />
      <div className="contentdiv">
        <div className="vendorview">
          <div className="vendortext">
            <h1>Vendors ({totaluser})</h1>
          </div>
          <div className="vendorrightside">
            <div className="vendorbtns">
              <NavLink to="/Homelist_Gridview" className="gridbtn">
                Grid View
              </NavLink>
              <NavLink to="/Homelist_Mapview" className="mapbtn">
                Map View
              </NavLink>
              {/* <div className="filtericon">
                <FilterModal getvalues={getvalues}/>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <span className="p">{Error}</span>

      <Outlet />
    </div>
  );
};

export default HomeList;
