import React from "react";
import Screensback from "../../UI/Screensback";
import wedvedlogo from "../../../Assets/Wedved_Logo.png";
import lock1 from "../../../Assets/lock1.png";
import SecondButton from "../../UI/SecondButton";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useState } from "react";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import "./ResetPassword.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import AppConfig from "../../../Api/AppConfig";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [opene, setOpene] = useState(false);
  const [Error, seterror] = useState("");

  const [message, setmessage] = useState("");
  const [isLoding, setIsloding] = useState(false);
  const [data, setData] = useState({
    old_pass: "",
    new_pass: "",
    confirmpassword: "",
  });
  const [validate, setvalidate] = useState({
    old_pass: "",
    new_pass: "",
    confirmPassword: "",
  });

  const predata = JSON.parse(localStorage.getItem("data"));
  const userToken = localStorage.getItem("token");

  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });

    validation(e);
  };

  const validation = (e) => {
    let { name, value } = e.target;
    setvalidate((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "new_pass":
          if (!value) {
            stateObj[name] = "Please enter New Password.";
          } else if (data.confirmPassword && value !== data.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password must be same.";
          } else {
            stateObj["confirmPassword"] = data.confirmPassword
              ? ""
              : validate.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (data.new_pass && value !== data.new_pass) {
            stateObj[name] = "Password and Confirm Password must be same.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setIsloding(true);

    const params = new FormData();
    params.append("old_pass", data.old_pass);
    params.append("new_pass", data.new_pass);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}change_password`,
        method: "PUT",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          e.target.reset();
          navigate("/Login");
        })
        .catch((error) => {
          const err = error.response.data.message;
          setIsloding(false);
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const togglebtn = () => {
    setOpen((prevopen) => !prevopen);
  };
  const togglebtns = () => {
    setOpens((prevopens) => !prevopens);
  };
  const togglebtne = () => {
    setOpene((prevopene) => !prevopene);
  };

  return (
    <div>
      <Screensback>
        <motion.div
          // initial={{ opacity: 0, x: -100 }}
          // whileInView={{ opacity: 1, x: 1 }}
          // transition={{ duration: 0.3 }}
          className="Wback"
        >
          <div className="dukhdenewaladiv" onClick={() => navigate("/Forgot")}>
            <KeyboardArrowLeftIcon />
          </div>
          <div className="Mcontnt">
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 1 }}
              transition={{ duration: 1 }}
              className="cnt"
            >
              <img src={wedvedlogo} alt="" />
              <div className="texttt">
                <h1>Reset Password</h1>
                <p>
                  Your new password must be different from previous used
                  password.
                </p>
              </div>
              <form onSubmit={handlesubmit} className="resetinputs">
                <div className="Rfirstinput">
                  <img src={lock1} alt="" />
                  <input
                    type={open ? "Text" : "password"}
                    id="oldpassword"
                    placeholder="Old Password"
                    name="old_pass"
                    onChange={handlechange}
                    required
                  />

                  {open ? (
                    <AiOutlineEye onClick={togglebtn} />
                  ) : (
                    <AiOutlineEyeInvisible onClick={togglebtn} />
                  )}
                </div>

                <div className="Rfirstinput">
                  <img src={lock1} alt="" />
                  <input
                    type={opens ? "Text" : "password"}
                    id="newpassword"
                    placeholder="New Password"
                    name="new_pass"
                    onChange={handlechange}
                    required
                    onBlur={validation}
                  />

                  {opens ? (
                    <AiOutlineEye onClick={togglebtns} />
                  ) : (
                    <AiOutlineEyeInvisible onClick={togglebtns} />
                  )}
                </div>
                {validate.new_pass && (
                  <span className="err">{validate.new_pass}</span>
                )}
                <div className="Rfirstinput">
                  <img src={lock1} alt="" />
                  <input
                    type={opene ? "Text" : "password"}
                    id="confirmpassword"
                    placeholder="Confirm New Password"
                    name="confirmPassword"
                    onChange={handlechange}
                    required
                    onBlur={validation}
                  />

                  {opene ? (
                    <AiOutlineEye onClick={togglebtne} />
                  ) : (
                    <AiOutlineEyeInvisible onClick={togglebtne} />
                  )}
                </div>
                {validate.confirmPassword && (
                  <span className="err">{validate.confirmPassword}</span>
                )}
                <span className="p">{Error}</span>
                <div className="Rbtn">
                  <SecondButton type="submit">Reset Password</SecondButton>
                </div>
              </form>
            </motion.div>
          </div>
        </motion.div>
      </Screensback>
      {!isLoding && <div></div>}
      {isLoding && (
        <Backdrop
          open={true}
          sx={{ color: "#0DC3D7", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </div>
  );
};

export default ResetPassword;
