import "./Openchat.css";
import Jobdetails from "./jobdetails";
import { useState, useEffect } from "react";
import axios from "axios";
import locationicon from "../../../Assets/locationIcon.png";
import Staricon from "../../../Assets/Staricon.png";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import AppConfig from "../../../Api/AppConfig";

const Openchat = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState();
  const [Error, seterror] = useState("");

  const base = `${AppConfig.BASE_URL_PROFILE}`;
  const id = JSON.parse(localStorage.getItem("job_id"));
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    const params = new FormData();
    params.append("job_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_job_details`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = response.data;

          setPost(resdata);
          // const biderdata=response.data.total_bids
          // setBider(biderdata)
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const openchat = (e, value) => {
    e.stopPropagation();
    const room = value.room_id;
    const profile = value.vender_profile_pic;
    localStorage.setItem("room_id", room);
    localStorage.setItem("profile", profile);
    navigate("/Chat", {
      state: {
        name: value.vender_first_name + " " + value.vender_last_name,
        pic: base + value.vender_profile_pic,
      },
    });
  };
  const handleprofile = (value) => {
    localStorage.setItem("vender_id", value.vender_id);
    navigate("/VendorProfile/profession");
  };

  return (
    <>
      <Jobdetails post={post} getAllData={getAllData} />
      {post?.open_chat.length > 0 ? (
        post?.open_chat.map((value, index) => (
          <div
            className="openchat_bids"
            key={index}
            onClick={() => handleprofile(value)}
          >
            <div className="openchatbid_div">
              <img src={base + value.vender_profile_pic} alt="" />
              <div className="openchat_bidsright">
                <h2>
                  {value.vender_first_name + " " + value.vender_last_name}
                </h2>
                <div>
                  <span>
                    {" "}
                    <Box
                      sx={{
                        "& > legend": { mt: 2 },
                      }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Rating
                        name="read-only"
                        value={parseInt(value.rate)}
                        size="small"
                        style={{ zIndex: "0" }}
                        readOnly
                      />
                      ({value.reviews} Reviews)
                    </Box>
                  </span>
                </div>

                <div className="openchat_miles">
                  <img src={locationicon} alt="" />
                  <span>{value.distance} miles away</span>
                </div>
                <div className="openchat_bidinfo">
                  <div className="openchat_biddetails">
                    <p>bid</p>
                    <h4>${value.bid}</h4>
                  </div>
                  <div className="openchat_biddetails">
                    <p>bid On</p>
                    <h4>{value.bid_on}</h4>
                  </div>
                </div>
                <div className="openchat_button">
                  <button onClick={(e) => openchat(e, value)}>Open Chat</button>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="nodata">No Chat available</div>
      )}
      <span className="p">{Error}</span>
    </>
  );
};
export default Openchat;
