import "./Vendorprofession.css";
import { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import AppConfig from "../../../Api/AppConfig";

const Vendorprofessions = () => {
  const navigate = useNavigate();
  const [post, setPost] = useState();
  const [totalpage, setTotalpage] = useState();
  const [Error, seterror] = useState("");

  const base = `${AppConfig.BASE_URL_PROFILE2}`;

  const id = localStorage.getItem("vendor_id");
  const userToken = localStorage.getItem("token");
  const pro = post?.professions;

  useEffect(() => {
    getAllData();
  }, []);

  const click = (data) => {
    let currentpage = data.selected + 1;

    const params = new FormData();
    params.append("page", currentpage);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_view_vender_profile`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          setPost(redata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const getAllData = () => {
    const params = new FormData();
    params.append("vender_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_view_vender_profile`,

        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = response.data;
          setPost(resdata);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      {pro?.length > 0 ? (
        <div>
          {pro?.map((value, index) => (
            <div key={index} className="Vendorprofession_Card">
              <div className="profession_headingcard">
                <h2>{value.profession}</h2>
              </div>
              <div className="vendorprofession_btns">
                {value.skills.map((skill, index) => (
                  <button key={index}>{skill}</button>
                ))}
              </div>
              <div className="vendorprofession_job">
                <p>
                  <b>{value.t_year}</b> <br /> Year
                </p>
                <p>
                  <b>{value.job_done}</b> <br /> Job done
                </p>
              </div>
              <div>
                <p>{value.about_proff}</p>
              </div>
              <div>
                {value.work_pics.map((src, index) => (
                  <img
                    key={index}
                    src={base + src}
                    alt=""
                    style={{
                      width: "120px",
                      height: "80px",
                      borderRadius: "5px",
                    }}
                    className="imgbtn"
                  />
                ))}
              </div>
            </div>
          ))}
          <ReactPaginate
            pageCount={totalpage}
            onPageChange={click}
            previousLabel="Previous"
            nextLabel="Next"
            containerClassName="nextslider"
            pageClassName="sliderbuttons"
            pageLinkClassName="linkbuttons"
            previousClassName="sliderbuttons"
            previousLinkClassName="linkbuttons"
            nextClassName="sliderbuttons"
            nextLinkClassName="linkbuttons"
            activeClassName="activepage"
            activeLinkClassName="activepage"
            breakClassName="sliderbuttons"
            breakLinkClassName="linkbuttons"
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            disabledLinkClassName="disable"
          />
        </div>
      ) : (
        <div className="nodata">NO data available</div>
      )}
      <span className="p">{Error}</span>
    </>
  );
};
export default Vendorprofessions;
