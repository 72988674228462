import { useState, useEffect } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";

function MapContainer(props) {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [coupledata, setCoupledata] = useState();

  useEffect(() => {
    setCoupledata(props.vendordata);
  }, []);

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onMapClick = (props) => {
    if (showingInfoWindow) {
      setSelectedPlace(null);
      setActiveMarker(null);
      setShowingInfoWindow(false);
    }
  };

  const onInfoWindowClose = () => {
    setSelectedPlace(null);
    setActiveMarker(null);
    setShowingInfoWindow(false);
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          zIndex: "-1",
          width: "100%",
          height: "100%",
        }}
      >
        <Map google={props.google} onClick={onMapClick} zoom={14}>
          {coupledata?.map((d, index) => (
            <Marker
              key={index}
              onClick={onMarkerClick}
              name={d}
              position={{ lat: d.lat, lng: d.long }}
            />
          ))}

          <InfoWindow
            marker={activeMarker}
            visible={showingInfoWindow}
            onClose={onInfoWindowClose}
          >
            <div className="mapbox">
              <div className="map_pic">
                <img
                  src={
                    process.env.REACT_APP_BASE_URL_PROFILE +
                    selectedPlace?.name.profile_pic
                  }
                  alt=""
                />
                <div className="map_name">
                  <h2>{selectedPlace?.name.first_name}</h2>
                  <div>
                    <Box
                      sx={{
                        "& > legend": { mt: 2 },
                      }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Rating
                        name="read-only"
                        value={parseInt(selectedPlace?.name.rate)}
                        size="small"
                        style={{ zIndex: "0" }}
                        readOnly
                      />
                      ({selectedPlace?.name.reviews} Reviews)
                    </Box>
                  </div>
                </div>
              </div>
              {selectedPlace?.name.professions.map((value) => (
                <div className="csinger">
                  <h3>{value.profession}</h3>
                  <div className="csingerbtn">
                    {value.skills.map((skill, i) => (
                      <button key={i}>{skill}</button>
                    ))}
                  </div>
                </div>
              ))}
              {/* <div className="vendorcardbtn" >
                      <button onClick={()=>console.log('hello')}>View Profile</button>

                    </div> */}
            </div>
          </InfoWindow>
        </Map>
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY,
})(MapContainer);
