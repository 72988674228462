import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./CoupleProfile.css";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppConfig from "../../../Api/AppConfig";

const CoupleProfile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState();
  const [posts, setPosts] = useState();
  const [totalpage, setTotalpage] = useState();
  const [isLoding, setIsloding] = useState(true);
  const [Error, seterror] = useState("");

  const userToken = localStorage.getItem("token");
  const base = `${AppConfig.BASE_URL_PROFILE}`;
  const id = localStorage.getItem("couple_id");

  useEffect(() => {
    if (id) {
      getAllData();
    }
  }, []);

  const getAllData = () => {
    const params = new FormData();
    params.append("couple_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_view_couple_profile`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const redata = response.data;
          setProfile(redata);
          const pdata = response.data.post_data;
          setPosts(pdata);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const click = (data) => {
    let currentpage = data.selected + 1;

    const params = new FormData();
    params.append("page", currentpage);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_home_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data.post_data;
          setPosts(redata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const viewDetails = (e) => {
    const params = new FormData();
    params.append("post_id", e.id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_view_couple_post`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = JSON.stringify(response.data.id);
          localStorage.setItem("postview_id", resdata);
          setIsloding(false);
          navigate("/couple_post_details/bid");
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);

          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <div>
      <div className="coupleprofilectnt">
        <div className="couplenav">
          <ArrowBackIcon
            onClick={() => navigate("/couple_post_details/bid")}
            style={{ cursor: "pointer" }}
          />
          <h3>Couple Profile</h3>
        </div>
        <div className="coupleprofile_bgimage">
          <div className="coupleprofile_main">
            <span className="p">{Error}</span>

            <div className="couple_profile">
              {profile ? (
                <img src={base + profile?.profile_pic} className="couple_img" />
              ) : (
                <div className="couple_img" />
              )}
              <h2>{profile?.first_name + " " + profile?.last_name}</h2>
            </div>
            <div className="coupleprofile_details">
              <div>
                <p>Bride Name</p>
                <h4>{profile?.bride_name}</h4>
              </div>
              <div>
                <p>Year of Engagement</p>
                <h4>{profile?.year_of_eng}</h4>
              </div>
            </div>
            <div className="coupleprofile_memories">
              <h4>Loving Memories</h4>
              <p>{profile?.loving_memories}</p>
            </div>

            <div className="coupleprofile_posts">
              <h3>{profile?.total_post}</h3>
              <span>Total Posted</span>
            </div>
            <div className="coupleprofile_Confirmed">
              <h3>{profile?.total_confirmed}</h3>
              <span>Total Confirmed</span>
            </div>
            <div className="coupleprofile_Withdrawn">
              <h3>{profile?.total_withdrawn}</h3>
              <span>Total Withdrawn</span>
            </div>
            <div className="coupleprofile_TotalCancelled">
              <h3>{profile?.total_cancelled}</h3>
              <span>Total Cancelled</span>
            </div>

            <div className="postedrequirement">
              <h2>Posted Requirement</h2>
            </div>

            <div className="coupleprofileCards">
              {posts?.map((value, index) => (
                <div key={index} className="coupleprofileCard1">
                  <div className="coupleprofile_confirmdiv">
                    <div className="coupleprofile_jobtext">
                      <h3>{value.lok_for}</h3>
                      {/* <p>{value.status}</p> */}
                      <p
                        className={
                          value.status === "Open"
                            ? "open_status"
                            : value.status === "Confirmed"
                            ? "confirm_status"
                            : value.status === "Cancelled"
                            ? "cancel_status"
                            : value.status === "Completed"
                            ? "complete_status"
                            : value.status === "Expired"
                            ? "expire_status"
                            : "withdrawn_status"
                        }
                      >
                        {value.status}
                      </p>
                    </div>
                    <div className="coupleprofile_jobbtns">
                      {value.skills.map((skill, i) => (
                        <button key={i}>{skill}</button>
                      ))}
                    </div>
                    <div className="coupleprofile_bugetdiv">
                      <div className="coupleprofile_div1">
                        <span>Budget</span>
                        <h4>${value.budget}</h4>
                      </div>
                      <div className="coupleprofile_div1">
                        <span>Bid</span>
                        <h4>{value.bid}</h4>
                      </div>

                      <div className="coupleprofile_div1">
                        <span>Posted On</span>
                        <h4>{value.date_created}</h4>
                      </div>
                    </div>
                    <div className="coupleprofile_details2">
                      <p>{value.requirements}</p>
                    </div>
                    <div className="coupleprofile_btngrp">
                      {value.status === "Open" ? (
                        <button
                          className="coupleprofile_btns"
                          onClick={() => viewDetails(value, index)}
                        >
                          View Details
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <ReactPaginate
              pageCount={totalpage}
              onPageChange={click}
              previousLabel="Previous"
              nextLabel="Next"
              containerClassName="nextslider"
              pageClassName="sliderbuttons"
              pageLinkClassName="linkbuttons"
              previousClassName="sliderbuttons"
              previousLinkClassName="linkbuttons"
              nextClassName="sliderbuttons"
              nextLinkClassName="linkbuttons"
              activeClassName="activepage"
              activeLinkClassName="activepage"
              breakClassName="sliderbuttons"
              breakLinkClassName="linkbuttons"
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              disabledLinkClassName="disable"
            />
          </div>
        </div>
      </div>
      {isLoding && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </div>
  );
};

export default CoupleProfile;
