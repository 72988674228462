import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";


const ProtectedRoute_Vendor = (props) => {

    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isvendor, setIsvendor] = useState(false);


    const checkUserToken = () => {

  if (!localStorage.getItem('token')) {
            setIsLoggedIn(false);
            return navigate('/');
        }
 else{            
    setIsLoggedIn(true);

            const predata = JSON.parse(localStorage.getItem("data"));
            const userType=predata.data.user_type

        if (userType === 1) {
            setIsvendor(false);
            return navigate(window.history.go(-1));
        }else{
            setIsvendor(true)
        }
  
        }
    }
    useEffect(() => {
            checkUserToken();
        }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                isLoggedIn && isvendor ? props.children :null 
            }
        </React.Fragment>
    );
}
export default ProtectedRoute_Vendor;