import React from "react";
import "./LogoutModal.css";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../../Firebase";
import AppConfig from "../../../Api/AppConfig";

const LogoutModal = () => {
  const navigate = useNavigate();

  const url = `${AppConfig.BASE_URL_PROFILE}`;
  const userdata = JSON.parse(localStorage.getItem("data"));
  const myimg = userdata.data.profile_pic;
  const name = userdata.data.first_name + "  " + userdata.data.last_name;

  // const handlelogOut = () => {
  //   localStorage.clear()

  //
  // };
  const handlelogOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.clear();
        if (!localStorage.getItem("token")) {
          navigate("/");
        }
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <div>
      <motion.div
        initial={{ scale: 0.5 }}
        whileInView={{ scale: 1 }}
        transition={{ duration: 0.2 }}
        className="dropdown-content"
      >
        <div className="drop"></div>
        <img src={url + myimg} alt="" className="dropdown-content_img" />
        <h4 className="dropdown-content_heading">{name}</h4>
        <Link smooth="true" to="/Cards">
          <span className="icon">
            <PaymentOutlinedIcon />
          </span>
          Payments
        </Link>
        <Link smooth="true" to="/EditProfile">
          <span className="icon">
            <BorderColorOutlinedIcon />
          </span>
          Edit Profile
        </Link>
        <Link smooth="true" to="/Setting_Notification">
          <span className="icon">
            <SettingsOutlinedIcon />
          </span>
          Setting
        </Link>
        <Link smooth="true" to="/Login" onClick={handlelogOut}>
          <span className="icon">
            <LogoutOutlinedIcon />
          </span>
          Logout
        </Link>
      </motion.div>
    </div>
  );
};

export default LogoutModal;
