import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeySharpIcon from "@mui/icons-material/KeySharp";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { useState, useEffect } from "react";
import "./Setting.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { signOut } from "firebase/auth";
import { auth } from "../../../Firebase";

const Settingsidebar = () => {
  const navigate = useNavigate();
  const [user, setuser] = useState(false);

  const predata = JSON.parse(localStorage.getItem("data"));
  const user_type = predata.data.user_type;

  useEffect(() => {
    if (!localStorage.getItem("data")) {
      navigate("/Login");
    } else if (user_type == 1) {
      setuser(true);
    } else if (user_type == 2) {
      setuser(false);
    }
  });

  const backbtn = () => {
    if (user) {
      navigate("/Homelist_Gridview");
    } else {
      navigate("/VendorHomelist_Gridview");
    }
  };
  const handlelogOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.clear();
        if (!localStorage.getItem("token")) {
          navigate("/");
        }
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <>
      <div className="setting_header">
        <div className="setting_title">
          <ArrowBackIcon
            id="backbtn"
            onClick={backbtn}
            style={{ cursor: "pointer" }}
          />
          <SettingsOutlinedIcon id="arw" />
          <h3>Settings</h3>
        </div>
        <ul className="setting_list">
          <li className="activeHome">
            <NavLink
              className="activenf "
              smooth="true"
              to={"/Setting_Notification"}
            >
              <NotificationsOutlinedIcon id="arw" />
              <div className="chek">
                <h4>Notification Setting</h4>
                <KeyboardArrowRightIcon id="arw" />
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink className="activecp" to={"/Setting_ChangePassword"}>
              <KeySharpIcon id="arw" />
              <div className="chek">
                <h4>Change Password </h4>
                <KeyboardArrowRightIcon id="arw" />
              </div>
            </NavLink>
          </li>
          <li className="activeHome">
            <NavLink className="activeab" to={"/AboutUs"}>
              <ListAltOutlinedIcon id="arw" />
              <div className="chek">
                <h4>About Us</h4>
                <KeyboardArrowRightIcon id="arw" />
              </div>
            </NavLink>
          </li>
          <li className="activehome">
            <NavLink className="activepp" to="/PrivacyPolicy">
              <ArticleOutlinedIcon id="arw" />
              <div className="chek">
                <h4>Privacy Policy</h4>
                <KeyboardArrowRightIcon id="arw" />
              </div>
            </NavLink>
          </li>
          <li className="activeHome">
            <NavLink className="activetc" to={"/Terms&Condition"}>
              <DescriptionOutlinedIcon id="arw" />
              <div className="chek">
                <h4>Term & condition</h4>
                <KeyboardArrowRightIcon id="arw" />
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/Login" onClick={handlelogOut}>
              <LogoutOutlinedIcon id="arw" />
              <div className="chek">
                <h4>LogOut</h4>
              </div>
            </NavLink>
            <div className="backarw" onClick={backbtn} id="arw">
              <div className="backarw_btn">
                <ArrowBackIcon style={{ cursor: "pointer" }} />
                Back
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Settingsidebar;
