import React from "react";
import "./Services.css";
import RectangleBoy from "../../Assets/RectangleBoy.png";
import Ellipse1 from "../../Assets/Ellipse1.png";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Underline from "../UI/Underline";
import { motion } from "framer-motion";
// import Navbar from "../UI/Navbar";

const Services = () => {
  return (
    <div>
      {/* <Navbar/> */}
      <div className="services" id="Services">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 1 }}
          className="leftside"
        >
          <img src={RectangleBoy} alt="" />
          <img src={Ellipse1} alt="" />
        </motion.div>

        {/* right side */}
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: -1 }}
          transition={{ delay: 0.2, duration: 1 }}
          className="rightside"
        >
          <div id="whyus">
            <h5>Why Choose Us</h5>
            <h1 style={{ color: "#091011" }}>
              Adding the Memories <br /> of Every Moment
            </h1>

            <div className="under" style={{ marginTop: "1rem" }}>
              <Underline />
            </div>
          </div>

          <div className="Exp">
            <div className="profession">
              <div>
                <VerifiedOutlinedIcon style={{ fontSize: "2.1rem" }} />
              </div>
              <div className="text">
                <h3>Professional</h3>
                <p>
                  Choose WedVed for a wedding experience like no other – <br />
                  where seamless planning meets unparalleled creativity.
                </p>
              </div>
            </div>
            <div className="profession">
              <div>
                <PersonAddAltIcon style={{ fontSize: "2.1rem" }} />
              </div>
              <div className="text">
                <h3>Experience</h3>
                <p>
                  Backed by years of collective experience, the WedVed team{" "}
                  <br />
                  is dedicated to crafting unforgettable moments, bringing a
                  <br />
                  wealth of expertise and passion to every wedding
                </p>
              </div>
            </div>
            <div className="profession">
              <div>
                <HeadphonesOutlinedIcon style={{ fontSize: "2.1rem" }} />
              </div>
              <div className="text">
                <h3>Support 24/7</h3>
                <p>
                  Rest easy knowing that our 24/7 support team at WedVed is{" "}
                  <br />
                  here for you at every step of the way.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Services;
