import React, { useState } from "react";
import "./CompleteProfile.css";
import Leftarrow from "../../UI/Leftarrow";
import wedvedlogo from "../../../Assets/Wedved_Logo.png";
import usericon from "../../../Assets/user.png";
import location from "../../../Assets/location.png";
import SecondButton from "../../UI/SecondButton";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import SearchBox from "./Searchbox";
import AppConfig from "../../../Api/AppConfig";

const CompleteProfile = () => {
  const navigate = useNavigate();
  const [isLoding, setIsloding] = useState(false);
  // const [status, setstatus] = useState(null);
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [Error, seterror] = useState("");

  const [value, setValue] = useState(dayjs("yyyy-mm-dd"));

  const [data, setData] = useState({
    name: "",
    d_of_eng: "", //value
    eng_loc: "",
    about_self: "",
    lat: "",
    long: "",
  });

  const userToken = localStorage.getItem("token");
  const moment = require("moment");

  const successCallback = (position) => {
    const l = parseInt(position.coords.latitude);
    setLat(l);
    const o = parseInt(position.coords.longitude);
    setLong(o);
  };
  const errorCallback = (error) => {};

  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setIsloding(true);

    const inputDate = data.d_of_eng;
    const formattedDate = moment(inputDate).format("DD/MM/YYYY");

    const params = new FormData();
    params.append("name", data.name);
    params.append("d_of_eng", formattedDate);
    params.append("eng_loc", data.eng_loc);
    params.append("about_self", data.about_self);
    params.append("lat", lat);
    params.append("long", long);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_cm_profile`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          e.target.reset();
          localStorage.removeItem("user_type");
          navigate("/AddCard");
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);

          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {
      setIsloding(false);
    }
  };
  const skip = () => {
    localStorage.removeItem("user_type");
    // navigate("/AddCard");
    navigate("/Homelist_Gridview");
  };

  const latlong = (places, latLng) => {
    setData((prevData) => {
      return {
        ...prevData,
        eng_loc: places.formatted_address,
      };
    });

    setLat(latLng.lat);
    setLong(latLng.lng);
  };

  return (
    <div>
      <div className="screenback">
        <div className="backprofile">
          {/* <Screensback> */}
          <motion.div
            // initial={{ opacity: 0, x: -100 }}
            // whileInView={{ opacity: 1, x: 1 }}
            // transition={{ duration: 0.3 }}
            className="Cprofilebackw"
          >
            <motion.form
              onSubmit={handlesubmit}
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 1 }}
              transition={{ duration: 1 }}
              className="Cprofilectn"
            >
              <div className="arrowbtn">
                <div
                  className="mainarrow"
                  onClick={() => navigate("/Homelist_Gridview")}
                >
                  <Leftarrow />
                </div>
              </div>
              <div className="cprotext">
                <img className="Clogo" src={wedvedlogo} alt="" />
                <h2>Complete Profile</h2>
                <p>Add your loving moments</p>
              </div>
              <div className="maininputsDiv">
                <div className="firstproinput">
                  <img src={usericon} alt="" />
                  <input
                    required
                    onChange={handlechange}
                    name="name"
                    type="text"
                    placeholder="Enter Fullname"
                    value={data.name}
                  />
                </div>
                <div className="firstproinput">
                  {/* <img src={calendar} alt="" /> */}
                  <input
                    type="date"
                    required
                    value={data.d_of_eng}
                    name="d_of_eng"
                    onChange={handlechange}
                    style={{ fontSize: "15px" }}
                  />
                </div>
                <div className="firstproinput">
                  <img src={location} alt="" />
                  <SearchBox
                    job={data.eng_loc}
                    InputEventJob={handlechange}
                    names="eng_loc"
                    onPlaceSelected={latlong}
                    ph="Engagement location"
                    className="datalocation"
                  />
                </div>
              </div>

              <div className="textarea">
                <input
                  required
                  value={data.about_self}
                  onChange={handlechange}
                  name="about_self"
                  type="textarea"
                  placeholder="Enter your loving moments"
                />
              </div>
              <span className="p">{Error}</span>

              <div className="Cprofilebtns">
                <div className="skipbtn">
                  <button type="submit" className="cfirstbtn" onClick={skip}>
                    Skip & Add Later
                  </button>
                </div>
                <div className="addbtn">
                  <SecondButton type="submit">Add Card</SecondButton>
                </div>
              </div>
            </motion.form>
          </motion.div>
          {!isLoding && <div></div>}
          {isLoding && (
            <Backdrop
              open={true}
              sx={{
                color: "#0DC3D7",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              <CircularProgress color="inherit" open={true} />
            </Backdrop>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
