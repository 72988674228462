import { initializeApp } from "firebase/app";
import { getMessaging } from 'firebase/messaging';
import {getAuth,GoogleAuthProvider}  from 'firebase/auth'
// import { onMessage } from "firebase/messaging";
// import logo from '../../../Assets/Wedved_Logo.png'

const firebaseConfig = {
  apiKey: "AIzaSyDjw5R4tCwhAWwouzIEmCWgep53-99nN1U",
  authDomain: "wed-ved.firebaseapp.com",
  projectId: "wed-ved",
  storageBucket: "wed-ved.appspot.com",
  messagingSenderId: "195069297357",
  appId: "1:195069297357:web:eb6050e4d6e354f1dd1f84",
  measurementId: "G-FTG3YH117E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const google=new GoogleAuthProvider()
export const auth=getAuth()





