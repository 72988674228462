import { Link } from "react-scroll";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { useEffect, useState } from "react";
import Settingsidebar from "./Setting";
import { Outlet } from "react-router-dom";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppConfig from "../../../Api/AppConfig";

const Nf = () => {
  const navigate = useNavigate();

  const [show, setshow] = useState(true);
  const [isLoding, setIsloding] = useState(true);
  const [Error, seterror] = useState("");

  const [data, setdata] = useState({
    pushnf: "",
    email: "",
  });

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    if (window.innerWidth < 500) {
      setshow(false);
    } else {
      setshow(true);
    }
  }, []);

  useEffect(() => {
    d();
  }, []);

  const d = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}get_user_data`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const redata = response.data;
          if (redata.is_push_notification === true) {
            setdata((prevData) => {
              return {
                ...prevData,
                pushnf: 1,
              };
            });
          } else {
            setdata((prevData) => {
              return {
                ...prevData,
                pushnf: 0,
              };
            });
          }
          if (redata.is_email_notification === true) {
            setdata((prevData) => {
              return {
                ...prevData,
                email: 1,
              };
            });
          } else {
            setdata((prevData) => {
              return {
                ...prevData,
                email: 0,
              };
            });
          }
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const on1 = () => {
    setIsloding(true);
    const params = new FormData();
    params.append("push_notify", 0);
    params.append("email_notify", data.email);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}setting_api`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          d();
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const on2 = () => {
    setIsloding(true);
    const params = new FormData();
    params.append("push_notify", 1);
    params.append("email_notify", data.email);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}setting_api`,

        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          d();
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const of1 = () => {
    setIsloding(true);

    const params = new FormData();
    params.append("push_notify", data.pushnf);
    params.append("email_notify", 0);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}setting_api`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          d();
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const of2 = () => {
    setIsloding(true);

    const params = new FormData();
    params.append("push_notify", data.pushnf);
    params.append("email_notify", 1);
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}setting_api`,

        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          d();
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      {show && <Settingsidebar />}
      {!show && (
        <>
          <div className="setting_titlenav">
            <ArrowBackIcon
              onClick={() => navigate("/Setting")}
              style={{ cursor: "pointer" }}
            />
            <h3>Notification Setting</h3>
          </div>
          <div className="notification_data">
            <ul className="notification_list">
              <li>
                <Link className="" to="/">
                  Push Notification
                </Link>
                {data.pushnf === 1 ? (
                  <ToggleOnIcon id="on_nf_toggle" onClick={on1} />
                ) : (
                  <ToggleOffIcon id="off_nf_toggle" onClick={on2} />
                )}
              </li>
              <li>
                <Link className="" to="/">
                  Email Notification
                </Link>
                {data.email === 1 ? (
                  <ToggleOnIcon id="on_nf_toggle" onClick={of1} />
                ) : (
                  <ToggleOffIcon id="off_nf_toggle" onClick={of2} />
                )}
              </li>
            </ul>
            <span className="p">{Error}</span>
            <Outlet />
            {isLoding && (
              <Backdrop
                open={true}
                sx={{
                  color: "#0DC3D7",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <CircularProgress color="inherit" open={true} />
              </Backdrop>
            )}
          </div>
        </>
      )}

      {show && (
        <div className="notification_data">
          <ul className="notification_list">
            <li>
              <Link className="" to="/">
                Push Notification
              </Link>
              {data.pushnf === 1 ? (
                <ToggleOnIcon id="on_nf_toggle" onClick={on1} />
              ) : (
                <ToggleOffIcon id="off_nf_toggle" onClick={on2} />
              )}
            </li>
            <li>
              <Link className="" to="/">
                Email Notification
              </Link>
              {data.email === 1 ? (
                <ToggleOnIcon id="on_nf_toggle" onClick={of1} />
              ) : (
                <ToggleOffIcon id="off_nf_toggle" onClick={of2} />
              )}
            </li>
          </ul>
          <Outlet />
          {isLoding && (
            <Backdrop
              open={true}
              sx={{
                color: "#0DC3D7",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              <CircularProgress color="inherit" open={true} />
            </Backdrop>
          )}
        </div>
      )}
    </>
  );
};
export default Nf;
