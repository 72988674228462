import React, { useEffect, useState } from "react";
import "./Accountoption.css";
import Group from "../../Assets/Groupimg.png";
import wedvedlogo from "../../Assets/Wedved_Logo.png";
import simg3 from "../../Assets/simg3.png";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { GoogleLoginButton } from "react-social-login-buttons";
import { signInWithPopup } from "firebase/auth";
import { auth, google } from "../../Firebase";
import { messaging } from "../../Firebase";
import { getToken } from "firebase/messaging";
import AppConfig from "../../Api/AppConfig";
import Leftarrow from "../UI/Leftarrow";

const Accountoption = () => {
  const navigate = useNavigate();

  const [user, setuser] = useState(false);
  const [devicetoken, setdevicetoken] = useState();
  const [Error, seterror] = useState("");

  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const REDIRECT_URI = window.location.href;

  const user_type = localStorage.getItem("user_type");

  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        const permissionResult = await Notification.requestPermission();
        // console.log('Notification permission:', permissionResult);

        if (permissionResult === "granted") {
          const currentToken = await getToken(messaging, {
            vapidKey:
              "BEE4soE8v6Uav9SkEwC3s4nav05S8MaN_qOqy75l2jsGT7uG4FFM8kaEpmlJ08tOjf5Tumd0frBASfST8gz46bQ",
          });
          // console.log('Device token:', currentToken);
          setdevicetoken(currentToken);
          // Use the token as needed
        }
      } catch (error) {
        console.error("Error obtaining device token:", error);
      }
    };

    requestNotificationPermission();
  }, []);

  useEffect(() => {
    if (user_type == 1) {
      setuser(true);
    } else {
      setuser(false);
    }
  }, [user]);

  const sociallogin = (data) => {
    const params = new FormData();
    params.append("f_name", data[0].displayName);
    params.append("email", data[0].email);
    params.append("user_type", user_type);
    params.append("social_id", data[0].uid);
    params.append("social_type", "google");
    params.append("profile_pic", data[0].photoURL);
    params.append("device_token", devicetoken);
    params.append("device_type", "web");

    try {
      let config = {
        url: "https://admin.wedved.com/api/v1/social_api",
        method: "POST",
        data: params,
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          // setIsloding(false);
          const resdata = JSON.stringify(response);
          localStorage.setItem("data", resdata);
          if (user_type == 1) {
            navigate("/CompleteProfile");
          } else {
            navigate("/VendorCompleteProfile");
          }
          const userdata = JSON.parse(localStorage.getItem("data"));
          const token = userdata.token;
          localStorage.setItem("token", token);
          // const user_type=userdata.data.user_type
          // const cp_complete=userdata.data.complete_profile
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);

          // setIsloding(false);
        });
    } catch (error) {}
  };

  const onLoginStart = async (h) => {
    const result = await signInWithPopup(auth, h);
    sociallogin(result.user.providerData);
  };

  const componentClicked = (response) => {
    // console.log('comp', response)
  };

  return (
    <div>
      <div className="screenback">
        <div className="backoverlay">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 1 }}
            transition={{ duration: 0.3 }}
            className="mainitems"
          >
            <div
              className="backarrow_option"
              onClick={() => navigate("/selectprofile")}
            >
              <Leftarrow />
            </div>

            <motion.div
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: -1 }}
              transition={{ duration: 1 }}
              className="mainitem"
            >
              <img src={wedvedlogo} alt="" className="main_logo" />
              <img src={Group} alt="" className="main_grp" />
              <div className="mainitemtext">
                {!user && (
                  <>
                    <h2>Find Couples Near Your Venue</h2>
                    <p>
                      Register and get <b> 5000+</b> couples to serve your
                      services.
                    </p>
                  </>
                )}
                {user && (
                  <>
                    <h2>Look for wedding vendors near you</h2>
                    <p>
                      Post your requirment and get <b> 5000+</b> vendors near
                      you.
                    </p>
                  </>
                )}
                <div className="sociallinkss">
                  <GoogleLoginButton
                    style={{ width: "180px" }}
                    onClick={() => onLoginStart(google)}
                  />

                  <Link to="/CreateAccount">
                    <img src={simg3} alt="" />
                  </Link>
                </div>
                <span className="p">{Error}</span>

                <p>
                  You already have an account?{" "}
                  <span onClick={() => navigate("/login")}> Sign In</span>
                </p>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Accountoption;
