import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import renderHTML from "react-render-html";
import Settingsidebar from "./Setting";
import { Outlet } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import AppConfig from "../../../Api/AppConfig";

const Term = () => {
  const navigate = useNavigate();
  const [term, setTerm] = useState("");
  const [Error, seterror] = useState("");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}terms_condition`,
        method: "GET",
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const tc = response.data.text;
          const newtc = renderHTML(tc);

          setTerm(newtc);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
        });
    } catch (error) {}
  };
  return (
    <>
      <div className="setting_titlenav">
        <ArrowBackIcon
          onClick={() => window.history.go(-1)}
          style={{ cursor: "pointer" }}
        />
        <h3>Terms & Conditions</h3>
      </div>

      <div className="term_data">
        {term}
        <span className="p">{Error}</span>

        <Outlet />
      </div>
    </>
  );
};

export default Term;
