import React, { useEffect, useState } from "react";
import locationicon from "../../../Assets/locationIcon.png";
import "./VendorCards.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import searchicon from "../../../Assets/search-normal.png";
import { motion } from "framer-motion";
import AppConfig from "../../../Api/AppConfig";

const VendorCards = ({ click, tampData, totalpage, Error, handlesearch }) => {
  const navigate = useNavigate();
  const base = AppConfig.BASE_URL_PROFILE;

  const viewDetails = (e) => {
    localStorage.setItem("vendor_id", e.id);
    navigate(`/VendorProfile/profession`);
  };

  return (
    <div>
      <div className="searchinput">
        <div className="searchicon">
          <img src={searchicon} alt="" />
          <input
            type="search"
            placeholder="Search"
            id="search"
            onKeyDown={handlesearch}
          />
        </div>
      </div>
      <span className="p">{Error}</span>

      {tampData?.length > 0 ? (
        <div>
          <div className="vendorsprofile">
            {tampData?.map((value, index) => {
              return (
                <motion.div
                  key={index}
                  className="vendorbox"
                  layout
                  transition={{ duration: 1 }}
                >
                  <div className="boxcntnt">
                    <div
                      style={{
                        height: "230px",
                        overflow: "auto",
                        display: "flex",
                        gap: "1rem",
                        flexDirection: "column",
                      }}
                    >
                      <div className="vname">
                        <img src={base + value.profile_pic} alt="" />
                        <div className="location">
                          <h3>{value.first_name}</h3>
                          <div>
                            <Box
                              sx={{
                                "& > legend": { mt: 2 },
                              }}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Rating
                                name="read-only"
                                value={parseInt(value.rate)}
                                size="small"
                                style={{ position: "initial" }}
                                readOnly
                              />
                              ({value.reviews} Reviews)
                            </Box>
                          </div>
                          <div className="miles">
                            <img src={locationicon} alt="" />
                            <span>{value.distance} miles away</span>
                          </div>
                        </div>
                      </div>
                      {value.professions?.map((curElem, index) => (
                        <div className="singer" key={index}>
                          <h3>{curElem.profession}</h3>
                          <div className="singerbtn">
                            {curElem.skills?.map((btn, i) => (
                              <button key={i}>{btn}</button>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="vendorcardbtn">
                      <button onClick={() => viewDetails(value, index)}>
                        View Profile
                      </button>
                    </div>
                  </div>
                </motion.div>
              );
            })}
            <ReactPaginate
              pageCount={totalpage}
              onPageChange={click}
              previousLabel="Previous"
              nextLabel="Next"
              containerClassName="nextslider"
              pageClassName="sliderbuttons"
              pageLinkClassName="linkbuttons"
              previousClassName="sliderbuttons"
              previousLinkClassName="linkbuttons"
              nextClassName="sliderbuttons"
              nextLinkClassName="linkbuttons"
              activeClassName="activepage"
              activeLinkClassName="activepage"
              breakClassName="sliderbuttons"
              breakLinkClassName="linkbuttons"
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              disabledLinkClassName="disable"
            />
          </div>
        </div>
      ) : (
        <div className="nodata">No data available </div>
      )}
    </div>
  );
};

export default VendorCards;
