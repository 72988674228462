import React, { useState, useEffect } from "react";
import "./VendorProfile.css";
import {
  NavLink,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import * as React from 'react';
import axios from "axios";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppConfig from "../../../Api/AppConfig";

const VendorProfile = () => {
  const navigate = useNavigate();

  const [value, setvalue] = useState();
  const [profile, setProfile] = useState();
  const [isLoding, setIsloding] = useState(true);
  const [Error, seterror] = useState("");

  const id = localStorage.getItem("vendor_id");
  const userToken = localStorage.getItem("token");
  const base = `${AppConfig.BASE_URL_PROFILE}`;

  useEffect(() => {
    if (id) {
      getAllData();
    }
  }, []);

  const getAllData = () => {
    const params = new FormData();
    params.append("vender_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}couple_view_vender_profile`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const resdata = response.data;
          setIsloding(false);

          setProfile(resdata);
          setvalue(response.data.rate);
        })
        .catch((error) => {
          const err = error.response.data;
          seterror(err);
          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  return (
    <div>
      <div className="venderprofilectnt">
        <div className="vendornav">
          <ArrowBackIcon
            onClick={() => window.history.go(-1)}
            style={{ cursor: "pointer" }}
          />
          <h3>Vendor Profile</h3>
        </div>
        <div className="vendorprofile_bgimage">
          <div className="vendorprofile_main">
            <div className="vendor_profile">
              {profile ? (
                <img src={base + profile?.profile_pic} className="vendor_img" />
              ) : (
                <div className="vendor_img"></div>
              )}
              <h2>{profile?.first_name + " " + profile?.last_name}</h2>
              <h2>{profile?.company_name}</h2>
              <div className="vendorjob_rating">
                <Box
                  sx={{
                    "& > legend": { mt: 2 },
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Rating name="read-only" value={parseInt(value)} readOnly />(
                  {profile?.reviews} Reviews)
                </Box>
              </div>
              <div className="vendor_job">
                <span style={{ color: "#007cc0" }}>
                  {profile?.successful_job_done}
                </span>{" "}
                succesful jobs Done
              </div>
            </div>
            <ul className="vendordetails_list">
              <li>
                <NavLink className="bid" smooth="true" to="profession">
                  Profession
                </NavLink>
              </li>
              <li>
                <NavLink className="chat" smooth="true" to="/">
                  Event photos
                </NavLink>
              </li>
            </ul>
            <span className="p">{Error}</span>

            <Outlet />
          </div>
        </div>
      </div>
      {isLoding && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </div>
  );
};

export default VendorProfile;
