import axios from "axios";
import "./Notificationmodal.css";
// import CircleIcon from "@mui/icons-material/Circle";
import { useState } from "react";
import { useEffect } from "react";
// import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import AppConfig from "../../Api/AppConfig";

const NotificationVendorModal = () => {
  const navigate = useNavigate();
  const [nf, setnf] = useState([]);
  const [totalpage, setTotalpage] = useState();
  const [currPage, setCurrPage] = useState(0);
  const [temp, settemp] = useState(nf);
  const [hasNext, sethasNext] = useState();

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_notification_api`,
        method: "POST",
        headers: {
          Authentication: userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          setnf(redata);
          const next = response.pagination.has_next;
          sethasNext(next);
          const data = response.pagination.page;
          setCurrPage(data);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
        })
        .catch((error) => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  }, []);

  const onScroll = () => {
    let CurrPage = currPage + 1;
    const params = new FormData();
    params.append("page", CurrPage);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_notification_api`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const redata = response.data;
          settemp(redata);
          const alldata = nf.concat(redata);
          setnf(alldata);
          const next = response.pagination.has_next;
          sethasNext(next);
          const data = response.pagination.page;
          setCurrPage(data);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
        })
        .catch((error) => {
          const err = error.response.data.message;
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };
  const chat = (e) => {
    if (e.room_id) {
      const room = e.room_id;
      localStorage.setItem("room_id", room);
      navigate("/Chat", {
        state: {
          name: e.title,
          pic: e.user_img,
        },
      });
    } else {
      localStorage.setItem("job_id", nf.job_id);
      navigate(`/${nf.screen}`);
    }
  };

  return (
    <>
      <div className="notificationmodal_content">
        <div className="notification_drop"></div>
        <h1 className="nf_heading">Notifications</h1>
        <InfiniteScroll
          dataLength={nf.length}
          next={onScroll}
          style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
          hasMore={hasNext}
          // inverse={true}
          height={500}
          loader={<h4>Loading...</h4>}
          scrollableTarget="notification_detail"
        >
          {nf?.length > 0 ? (
            <>
              {nf?.map((nf, index) => (
                <div
                  className="notification_detail"
                  key={index}
                  onClick={() => chat(nf)}
                >
                  <p className="nf_title">
                    {" "}
                    <img
                      alt="user-image"
                      src={nf.user_img}
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    />
                    {nf.title}
                  </p>
                  <p className="nf_msg">{nf.message}</p>
                  <p className="nf_time">{nf.timestamps}</p>
                </div>
              ))}
              {temp?.map((nf, index) => (
                <div
                  className="notification_detail"
                  key={index}
                  onClick={() => chat(nf)}
                >
                  <p className="nf_title">
                    {" "}
                    <img
                      alt="user-image"
                      src={nf.user_img}
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    />
                    {nf.title}
                  </p>
                  <p className="nf_msg">{nf.message}</p>
                  <p className="nf_time">{nf.timestamps}</p>
                </div>
              ))}
            </>
          ) : (
            <div className="nodata" style={{ color: "white" }}>
              No Notifications yet
            </div>
          )}
        </InfiniteScroll>

        {/* <ReactPaginate
            pageCount={totalpage}
            onPageChange={click}
            previousLabel='<<'
            nextLabel='>>'
            containerClassName='nextslider'
            pageClassName="nf_sliderbuttons"
            pageLinkClassName="nf_linkbuttons"
            previousClassName="nf_sliderbuttons"
            previousLinkClassName="nf_linkbuttons"
            nextClassName="nf_sliderbuttons"
            nextLinkClassName="nf_linkbuttons"
            activeClassName="nf_activepage"
            activeLinkClassName="nf_activepage"
            breakClassName="nf_sliderbuttons"
            breakLinkClassName="nf_linkbuttons"
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            disabledLinkClassName='nf_disable'

          /> */}
      </div>
    </>
  );
};
export default NotificationVendorModal;
