import axios from "axios";
import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import "./Recentlybid.css";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import AppConfig from "../../../Api/AppConfig";
import { Backdrop, CircularProgress } from "@mui/material";

const RecentlyBid = () => {
  const navigate = useNavigate();
  const [rbid, setrbid] = useState();
  const [totalpage, setTotalpage] = useState();
  const [isloading, setisloading] = useState(true);
  const [Error, seterror] = useState("");

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_recent_bid_list`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setisloading(false);
          const redata = response.data.post_data;
          setrbid(redata);
          const tpage = response.pagination.total_pages;
          setTotalpage(tpage);
          seterror("");
        })
        .catch((error) => {
          setisloading(false);

          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const click = (data) => {
    setisloading(true);
    let currentpage = data.selected + 1;

    const params = new FormData();
    params.append("page", currentpage);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_recent_bid_list`,
        method: "POST",
        data: params,
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setisloading(false);
          const redata = response.data.post_data;
          setrbid(redata);
          seterror("");
        })
        .catch((error) => {
          setisloading(false);

          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      <div className="Rbidnav">
        <ArrowBackIcon
          onClick={() => navigate("/VendorHomelist_Gridview")}
          style={{ cursor: "pointer" }}
        />
        <h3>Recent Bid</h3>
      </div>
      <div className="Rbidmain">
        <span className="p">{Error}</span>

        <div className="vjobCards">
          {rbid?.length > 0 ? (
            <>
              {rbid?.map((value, index) => {
                return (
                  <div key={index} className="vmyCard1">
                    <div className="vendorconfirmdiv">
                      <div className="vendorjobtext">
                        <h3>{value.lok_for}</h3>
                        {/* <p>{value.status}</p> */}
                        <p
                          className={
                            value.status === "Open"
                              ? "open_status"
                              : value.status === "Confirmed"
                              ? "confirm_status"
                              : value.status === "Cancelled"
                              ? "cancel_status"
                              : value.status === "Completed"
                              ? "complete_status"
                              : "withdrawn_status"
                          }
                        >
                          {value.status}
                        </p>
                      </div>
                      <div className="vmyjobbtns">
                        {value.skills.map((skill, i) => (
                          <button key={i}>{skill}</button>
                        ))}
                      </div>
                      <div className="vendorbugetdiv">
                        <div className="vendordiv1">
                          <span>Budget</span>
                          <h4>${value.budget}</h4>
                        </div>
                        <div className="vendordiv1">
                          <span>Bid</span>
                          <h4>{value.bid}</h4>
                        </div>
                        <div className="vendordiv1">
                          <span>Post On</span>
                          <h4>{value.date_created}</h4>
                        </div>
                        {/* <div className="vendordiv1">
                        <span>Bided</span>
                        <h4>${value.won}</h4>
                      </div>
                      <div className="vendordiv1">
                        <span>Last Bid On</span>
                        <h4>{value.job_won_on}</h4>
                      </div> */}
                      </div>
                      <div className="vendordetails">
                        <p>{value.requirements.slice(15)}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
              <ReactPaginate
                pageCount={totalpage}
                onPageChange={click}
                previousLabel="Previous"
                nextLabel="Next"
                containerClassName="nextslider"
                pageClassName="sliderbuttons"
                pageLinkClassName="linkbuttons"
                previousClassName="sliderbuttons"
                previousLinkClassName="linkbuttons"
                nextClassName="sliderbuttons"
                nextLinkClassName="linkbuttons"
                activeClassName="activepage"
                activeLinkClassName="activepage"
                breakClassName="sliderbuttons"
                breakLinkClassName="linkbuttons"
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                disabledLinkClassName="disable"
              />
            </>
          ) : (
            <div className="nodata" style={{ color: "white" }}>
              Submit a bid to see recent bids
            </div>
          )}
        </div>
      </div>{" "}
      {isloading && (
        <Backdrop
          open={true}
          sx={{
            color: "#0DC3D7",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" open={true} />
        </Backdrop>
      )}
    </>
  );
};
export default RecentlyBid;
