import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import renderHTML from "react-render-html";
import Settingsidebar from "./Setting";
import { Outlet } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import AppConfig from "../../../Api/AppConfig";

const Privacypolicy = () => {
  const navigate = useNavigate();

  const [privacypolicy, setPrivacypolicy] = useState();
  const [Error, seterror] = useState("");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}privacy_policy`,
        method: "GET",
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          const pp = response.data.text;
          const newpp = renderHTML(pp);

          setPrivacypolicy(newpp);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
        });
    } catch (error) {}
  };
  return (
    <>
      <>
        <div className="setting_titlenav">
          <ArrowBackIcon
            onClick={() => window.history.go(-1)}
            style={{ cursor: "pointer" }}
          />
          <h3>Privacy Policy</h3>
        </div>
        <div className="term_data">
          {privacypolicy}
          <span className="p">{Error}</span>

          <Outlet />
        </div>
      </>
    </>
  );
};

export default Privacypolicy;
