import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const ProtectedRoute_Couple = (props) => {

    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [iscouple, setIscouple] = useState(false);

//   const userToken=predata.token

    const checkUserToken = () => {

  if (!localStorage.getItem('token')) {
            setIsLoggedIn(false);
            return navigate('/');
        }else{
              const predata = JSON.parse(localStorage.getItem("data"));

        const userType=predata.data.user_type

        setIsLoggedIn(true);
        if (userType === 2) {
            setIscouple(false);
            
            return navigate(window.history.go(-1));
        }else{
            setIscouple(true)
        }
  
        }
    }
    useEffect(() => {
            checkUserToken();
            if(!localStorage.getItem("data")){
                navigate('/Login')
              }            
        }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                isLoggedIn && iscouple ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute_Couple;