import { motion } from "framer-motion";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import "./Profession.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Add from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AppConfig from "../../../Api/AppConfig";

const Profession = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showimg, setshowimg] = useState(false);
  const [deletefile, setdeletefile] = useState(false);
  const [id, setid] = useState(null);
  const [isLoding, setIsloding] = useState(true);
  const [Error, seterror] = useState("");

  const base = `${AppConfig.BASE_URL_PROFILE2}`;
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_profession_list`,
        method: "GET",
        headers: {
          Authentication: userToken,
        },
      };
      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const redata = response.data;
          setData(redata);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  // const work_pics=data.
  // const file = work_pics[i];
  // const extension = file.split('.').pop().toLowerCase();

  const Update = (e) => {
    setIsloding(true);

    const params = new FormData();
    params.append("prof_id", e.id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}vender_profession_view`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          const resdata = JSON.stringify(response.data.id);
          localStorage.setItem("update_id", resdata);
          const rdata = JSON.stringify(response);
          localStorage.setItem("update_data", rdata);
          navigate("/EditProfession");
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);

          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  const cdeletejob = (e) => {
    setdeletefile(true);
    setid(e.id);
  };
  const deletejob = () => {
    setIsloding(true);
    const s = data.filter((i) => i.id !== id);
    setData(s);
    const params = new FormData();
    params.append("profession_id", id);

    try {
      let config = {
        url: `${AppConfig.BASE_URL_v1}delete_profession`,
        method: "POST",
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authentication: userToken,
        },
      };

      axios(config)
        .then((res) => res.data)
        .then((response) => {
          setIsloding(false);
          setdeletefile(false);
        })
        .catch((error) => {
          const err = error.response.data.message;
          seterror(err);

          setIsloding(false);
          if (error.response.data.code === 401) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    } catch (error) {}
  };

  return (
    <>
      <motion.div
        // initial={{ opacity: 0, x: -100 }}
        // whileInView={{ opacity: 1, x: 1 }}
        // transition={{ duration: 0.3 }}
        className="professionprofilebackw"
      >
        <div className="Professionnav">
          <ArrowBackIcon
            onClick={() => navigate("/VendorHomelist_Gridview")}
            style={{ cursor: "pointer" }}
          />
          <h3>Profession</h3>
          <div>
            <AddCircleOutlineIcon
              id="add"
              onClick={() => navigate("/AddProfession")}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <span className="p">{Error}</span>

        <div className="cardvendordetail">
          {data.length > 0 ? (
            data.map((curElem, index) => (
              <>
                <div className="detailsCard" key={index}>
                  <div className="headingcard">
                    <div className="headingcard_left">
                      <h3>{curElem.profession}</h3>
                      <p>
                        <span>{curElem.t_year}+</span>Years
                      </p>
                    </div>
                    <div className="headingcard_right">
                      <BorderColorOutlinedIcon
                        id="r"
                        onClick={() => Update(curElem, index)}
                      />
                      <DeleteOutlineOutlinedIcon
                        id="r"
                        onClick={() => cdeletejob(curElem, index)}
                      />
                    </div>
                  </div>
                  <div className="professions_jobbtns">
                    {curElem.skills.map((btn, i) => (
                      <button key={i}>{btn}</button>
                    ))}
                  </div>
                  <div className="professions_images">
                    {curElem.work_pics.map((url, i) => {
                      const extension = url.split(".").pop().toLowerCase();

                      if (
                        extension === "3g2" ||
                        extension === "3gp" ||
                        extension === "flv" ||
                        extension === "m4p" ||
                        extension === "m4v" ||
                        extension === "mkv" ||
                        extension === "mp4" ||
                        extension === "mov" ||
                        extension === "mxf" ||
                        extension === "svi" ||
                        extension === "webm" ||
                        extension === "wmv"
                      ) {
                        return (
                          <video
                            src={base + url}
                            key={i}
                            className="videobtn"
                            controls
                          />
                        );
                      } else {
                        return (
                          <img
                            src={base + url}
                            alt={"image-" + i}
                            key={i}
                            className="imgbtn"
                          />
                        );
                      }
                      return null; // Ignore unsupported file types
                    })}
                  </div>
                </div>
              </>
            ))
          ) : (
            <>
              <div className="profession_data">No data</div>
            </>
          )}
          {deletefile && (
            <>
              <div className="background"></div>
              <div className="sure_popup">
                <p>Are you sure want to delete?</p>
                <button onClick={() => setdeletefile(false)}>cancel</button>
                <button className="sure_popup_delete" onClick={deletejob}>
                  delete
                </button>
              </div>
            </>
          )}
          {!isLoding && <div></div>}
          {isLoding && (
            <Backdrop
              open={true}
              sx={{
                color: "#0DC3D7",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              <CircularProgress color="inherit" open={true} />
            </Backdrop>
          )}
        </div>
      </motion.div>
    </>
  );
};
export default Profession;
